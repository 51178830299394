import { helper } from '@ember/component/helper';

export function capitalizeTranslation(param) {
    if (Array.isArray(param) && param.length > 0) {
        const textToTranslate = param[0];
        return textToTranslate.charAt(0).toUpperCase() + textToTranslate.slice(1);
    }
    return param;
}

export default helper(capitalizeTranslation);
