import Service, { inject as service } from '@ember/service';


export default class VastuuGroup extends Service{
    @service('collector-service') collector;
    @service store;
    @service error;
    @service intl;
    @service session;



    //make request to vastuu group api, with the customer id as a parameter
    async request(url, data, inviteCreator = null) {
        const vgCustomerId = await this.getCustomerId(inviteCreator);
        if (!vgCustomerId) {
            return this.error.notify(this.intl.t('general.vastuu_group.customer_id_not_found'));
        }

        return this.collector.ccapi({
            url: this.formatUrl(url),
            data: { ...data, 'customer-identifier': vgCustomerId },
        })

    }

    // get the vastuu group customer id
    // if the user is not authenticated, and the invite creator is not null, get the customer id from ttapi with the invite creator as a parameter
    // if the user is authenticated, get the customer id from the store
    // peek if the customer id is already in the store, if not, fetch the customer id from the store
    async getCustomerId(inviteCreator) {

        if (!this.session.isAuthenticated && inviteCreator) {
            return this.getVgCustomerId(inviteCreator);
        }

        let vgCustomerId = await this.store.peekAll('vastuugroupsetting');
        if (!vgCustomerId.length) {
            vgCustomerId = await this.store.findAll('vastuugroupsetting');
        }

        return vgCustomerId.length ? vgCustomerId.firstObject.customerIdentifier : null;
    }

    async getVgCustomerId(creator) {
        return this.collector.ttapi({
            url: 'getVgCustomerId',
            method: 'get',
            dataType: 'text',
            data: {
                creator,
            }
        });
    }

    formatUrl(url) {
        // remove slash from the beginning of the url if it exists
        return url.startsWith('/') ? url.substring(1) : url;
    }
}


