// noinspection TypeScriptValidateTypes

import { IRecord } from 'tt4/ember-movenium/interfaces/record';
import { getOwner } from '@ember/application';
import EmberObject, { get, set } from '@ember/object';
import { intersect } from '@ember/object/computed';
import { IField, IOptions } from 'tt4/ember-movenium/interfaces/forms';

export function save_record(record: IRecord, owner: any) {
    if (!owner) throw new Error('Call this with save_record(record, this)');
    const formsService = getOwner(owner).lookup('service:forms');
    const form = owner.form ? owner.form : owner.args.form;
    return new Promise<void>(async (resolve, reject) => {
        const fields = formsService.fields(form);
        const children: any[] = [];

        for (const field in fields) {
            if (field) {
                const currentField = get(fields, field) as IField;

                if (get(currentField, 'type') === 'databasearray' && field !== 'involved') {
                    const obj = EmberObject.extend({
                        fieldsInCommon: intersect('parentFields', 'childFields'),
                    }).create({
                        parentFields: Object.keys(fields),
                        childFields: Object.keys(
                            formsService.fields((currentField.options as IOptions).form),
                        ),
                    });

                    if (get(record, field) as IField) {
                        get(record, field).forEach((child: any) => {
                            if (child.isDeleted) return;
                            get(obj, 'fieldsInCommon').forEach((commonField) => {
                                if (get(record, commonField))
                                    set(child, commonField, get(record, commonField));
                            });
                            children.push(child);
                        });
                    }
                }
            }
        }

        saveRecordsInIsDeletedState(fields, owner);

        if (children.length > 0) {
            await record.validate();

            try {
                if (get(record, 'isValid')) {
                    // We have to save the child rows before the parent row, so run checkRowValidation
                    // to validate the parent row against e.g. overlapping work times. If the check fails,
                    // execution will stop before child rows are added. Without this check, the child rows will
                    // get added before it's certain the parent row can be saved, which can lead to child rows with no parent.
                    const collectorService = getOwner(owner).lookup('service:collector-service');
                    await collectorService.checkRowValidation(record._internalModel.modelName, record, !record.get('isNew'));

                    for (const child of children) {
                        await child.validate();
                        if (!get(child, 'isValid')) {
                            const errors = await child.errors;
                            if (errors && errors.firstObject)
                                throw new Error(
                                    errors.firstObject.message +
                                        ' (' +
                                        errors.firstObject.attribute +
                                        ')',
                                );
                            else await child.save(); // just try to save so it goes to catch if no record errors
                        } else continue;
                    }
                    const promises = children.map((child) => {
                        return child.save();
                    });
                    await Promise.all(promises);
                    await record.save();
                    resolve();
                } else {
                    resolve();
                }
            } catch (e) {
                reject(e);
            }
        } else {
            try {
                await record.validate();
                if (get(record, 'isValid')) await record.save();
                resolve();
            } catch (e) {
                reject(e);
            }
        }
    });
}

/**
 * this method saves all records that are isDeleted and not saved ... the idea is that when ie. material is deleted from worktime
 * it is not destroyed but instead just deleted and removed from worktime.material array .. so when worktime is saved the removed record stays in
 * isDeleted state but is never really destroyed so this method looks for hanging deleted records and saves them.
 * @param fields
 * @param owner
 */
const saveRecordsInIsDeletedState = (fields: any, owner: any): void => {
    const store = getOwner(owner).lookup('service:store');
    for (const field in fields) {
        if (field) {
            const currentField = get(fields, field) as IField;

            if (get(currentField, 'type') === 'databasearray' && field !== 'involved') {
                const options = currentField.options as IOptions;
                if (!options) return;
                const form = options.form;

                const deletedRecords = store
                    .peekAll(form)
                    .filter((item: IRecord) => item.isDeleted);
                deletedRecords.forEach((record: IRecord) => record.save());
            }
        }
    }
};

export default { save_record };
