import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Textarea\n    @rows={{4}}\n    @cols={{8}}\n    @class={{@class}}\n    @value={{@value}}\n    @disabled={{@disabled}}\n    @touchMove={{this.touchMoveAction}}\n    @touchEnd={{this.touchEndAction}}\n    {{on 'change' (fn @onChange @value)}}\n    {{on 'keyup' (fn @onChange @value)}}\n/>", {"contents":"<Textarea\n    @rows={{4}}\n    @cols={{8}}\n    @class={{@class}}\n    @value={{@value}}\n    @disabled={{@disabled}}\n    @touchMove={{this.touchMoveAction}}\n    @touchEnd={{this.touchEndAction}}\n    {{on 'change' (fn @onChange @value)}}\n    {{on 'keyup' (fn @onChange @value)}}\n/>","moduleName":"tt4/components/addon/fields/textarea.hbs","parseOptions":{"srcName":"tt4/components/addon/fields/textarea.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
/* istanbul ignore next */
export default class FieldsTextareaComponent extends Component {
    private _touchMoving: boolean = false;

    set touchMoving(moving: boolean) {
        this._touchMoving = moving;
    }

    get touchMoving(): boolean {
        return this._touchMoving;
    }

    @action
    protected touchMoveAction() {
        debounce(this, () => (this.touchMoving = true), 50);
    }

    @action
    protected touchEndAction() {
        if (this.touchMoving) {
            this.touchMoving = false;
            (document.activeElement as HTMLElement).blur();
        }
    }
}
