import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ClockCardComponent extends Component {
    @action
    // we have to have this "middleware" action to handle the formOnchange and also the onFieldChange
    onChangeHandler(field, formOnchange, value) {
        // Manually call formOnchange with field name and value
        formOnchange(field, value);
        // Manually call onFieldChange with field name and value
        this.args.onFieldChange(field, value);
    }
}
