import podNames from 'ember-component-css/pod-names';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getAssetFolder } from 'tt4/utils/getScript';
import getScript from 'tt4/utils/getScript';

export default class AiAgentChatbot extends Component {
    @service gui;
    @service flags;

    constructor() {
        super(...arguments);
        const assets = getAssetFolder('assets');
        getScript(`${assets}ai-agent-script.js`);
    }

    get styleNamespace() {
        return podNames['ai-agent-chatbot'];
    }

    get isOtta() {
        try {
            return this.gui.getBrand() !== 'default';
        } catch (err) {
            return false;
        }
    }

    get getAgentName() {
        if (this.isOtta) {
            return 'Botta';
        } else return 'Motti';
    }

    get getGreetingMessage() {
        if (this.isOtta) {
            return 'Hei! Olen Botta, chattibotti. Neuvon sinua kaikissa Ottaan liittyvissä kysymyksissä. Osaamistani kehitetään jatkuvasti. Miten voin auttaa?';
        } else
            return 'Hei! Olen Motti, chattibotti. Neuvon sinua kaikissa Moveniumiin liittyvissä kysymyksissä. Osaamistani kehitetään jatkuvasti. Miten voin auttaa?';
    }
}
