/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/register/user/info/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterUserInfoController;

import Ember from 'ember';

import validator from '../../../../mixins/base-validation';

import config from '../../../../config/environment';

import $ from 'jquery';

RegisterUserInfoController = Ember.Controller.extend(validator, {
    session: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    vastuuGroup: Ember.inject.service('vastuu-group'),
    registerUser: Em.inject.controller('register.user'),
    showRaksa: Ember.computed('registerUser.type', function () {
        return this.get('registerUser.type') === 'valtti';
    }),
    validationObject: 'model',
    validations: Ember.computed('showRaksa', function () {
        var validate;
        validate = {
            lastname: {
                mandatory: true,
            },
            firstname: {
                mandatory: true,
            },
        };
        if (this.get('showRaksa')) {
            validate.birthdate = {
                mandatory: true,
                birthdate: true,
            };
            validate.taxnumber = {
                mandatory: true,
                length: 12,
                number: true,
            };
            validate.homecountry = {
                mandatory: true,
            };
            validate.employer_name = {
                mandatory: true,
            };
            validate.employer_id = {
                mandatory: true,
            };
        }
        return validate;
    }),
    validationstate: Ember.Object.create(),
    errors: Ember.Object.create(),
    setValidationErrors: function () {
        var field, results, validations;
        validations = this.get('validations');
        validations = this.get('validations');
        results = [];
        for (field in validations) {
            results.push(this.setValidationError(field));
        }
        return results;
    },
    setValidationError: function (field) {
        var error, k, rule;
        if (!this.get('validationstate.' + field)) {
            this.set('validationstate.' + field, Ember.Object.create());
        }
        error = this.get('errors.' + field);
        if (!error) {
            this.set('validationstate.' + field + '.ok', true);
            this.set('validationstate.' + field + '.error', false);
        }
        for (k in error) {
            rule = error[k];
            this.set('validationstate.' + field + '.ok', false);
            this.set('validationstate.' + field + '.error', rule);
            return;
        }
    },
    fetchUserWithTaxnumber: function (taxnumber = false, ret = false) {
        var c;
        if (!taxnumber) {
            taxnumber = this.get('model.taxnumber');
        }
        this.set('loading', true);
        return this.vastuuGroup.request('tvpersonapi2',
                {
                    country: 'FI',
                    'id-country': 'FI',
                    'id-type': 'tax_number',
                    'id-value': taxnumber,
                },
                this.registerUser.creator,
            ).then((data) => {
                this.set('loading', false);
                if (data.length === 1 && !ret) {
                    this.setPersonInfo(data[0]);
                } else {
                    this.set('selectEmployer', data);
                }
                return data;
            });
    },
    fetchUserWithBarcode: function () {
        var c;
        this.set('loading', true);
        c = this.get('Collector');
        return c
            .ccapi({
                url: 'tvcard',
                data: {
                    card: this.get('model.barcode'),
                },
            })
            .then((data) => {
                if (data.length === 1) {
                    return this.fetchUserWithTaxnumber(data[0]['taxnumber']).then((userinfo) => {
                        return userinfo.forEach((user) => {
                            if (data[0]['vatin'] === user['vatin']) {
                                return this.setPersonInfo(user);
                            }
                        });
                    });
                }
            });
    },
    setPersonInfo: function (data) {
        this.set('duplicateTaxnumber', false);
        if (data.taxnumber) {
            this.set('userNotFoundFromTV', false);
            this.set('model.taxnumber', data.taxnumber);
            this.set('model.firstname', data.firstname);
            this.set('model.lastname', data.lastname);
            this.set('model.phone', data.phone);
            this.set('model.email', data.email);
            this.set('model.birthdate', data.birthdate);
            this.set('model.homecountry', data.homecountry);
            this.set('model.employer_id', data.vatin);
            this.set('model.employer_name', data.employer);
            this.set('model.status', data.status);
            this.set('model.emp_address', data.emp_address);
            this.set('model.emp_city', data.emp_city);
            this.set('model.emp_country', data.emp_country);
            this.set('model.emp_homecountry', data.emp_homecountry);
            this.set('model.emp_email', data.emp_email);
            this.set('model.emp_firstname', data.emp_firstname);
            this.set('model.emp_lastname', data.emp_lastname);
            this.set('model.emp_phone', data.emp_phone);
            this.set('model.emp_zip', data.emp_zip);
            this.set('model.contract_type', data.contract_type);
            this.set('model.workpass', data.workpass);
        } else {
            this.set('userNotFoundFromTV', true);
        }
        return this.validateDuplicateTaxnumber().then((ret) => {
            if (!ret) {
                return this.set('duplicateTaxnumber', true);
            }
        });
    },
    validateDuplicateTaxnumber: function () {
        var taxnumber;
        this.set('duplicateTaxnumber', false);
        taxnumber = this.get('model.taxnumber');
        return new Ember.RSVP.Promise((resolve, reject) => {
            if (!taxnumber) {
                resolve(true);
            }
            return this.get('Collector')
                .ttapi({
                    url: 'checkTaxnumber',
                    method: 'post',
                    dataType: 'text',
                    data: {
                        taxnumber: taxnumber,
                        creator: this.get('registerUser.creator'),
                    },
                })
                .then(
                    function (ret) {
                        if (ret === 'user_found') {
                            return resolve(false);
                        } else {
                            return resolve(true);
                        }
                    },
                    function (e) {
                        return reject(e);
                    },
                );
        });
    },
    validate_all: function () {
        return this.validate().then(() => {
            this.setValidationErrors();
            return this.validateDuplicateTaxnumber().then(
                (ret) => {
                    var error, errors;
                    if (ret) {
                        errors = this.get('errors');
                        for (error in errors) {
                            if (errors.hasOwnProperty(error)) {
                                this.set('loading', false);
                                return false;
                            } else {
                                this.get('registerUser').set('userinfo', this.get('model'));
                                return true;
                            }
                        }
                    } else {
                        this.set('duplicateTaxnumber', true);
                        return false;
                    }
                },
                function (e) {
                    return console.log(e);
                },
            );
        });
    },
    actions: {
        // this is called when ever some field is modified
        change: function (field, value) {
            var promise;
            promise = this.validate([field]);
            return promise.then(() => {
                this.setValidationError(field);
                if (field === 'taxnumber' && value.length === 12) {
                    this.fetchUserWithTaxnumber();
                }
                if (field === 'barcode' && value.length === 12) {
                    return this.fetchUserWithBarcode();
                }
            });
        },
        clearErrors: function (field) {
            if (this.get('validationstate.' + field)) {
                return this.set('validationstate.' + field + '.error', false);
            }
        },
        autofill: function () {
            this.set('model.taxnumber', '100015809173');
            return Em.run.later(
                this,
                function () {
                    return $('.form-signin input:text').first().keyup();
                },
                20,
            );
        },
        selectEmployer: function (user) {
            this.set('selectEmployer', void 0);
            return this.setPersonInfo(user);
        },
    },
});

export default RegisterUserInfoController;
