/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/taxman/taxreport/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TaxmanTaxreportIndexController;

import Ember from 'ember';

import config from '../../../../config/environment';

TaxmanTaxreportIndexController = Ember.Controller.extend({
    session: Em.inject.service(),
    exportFile: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    selectedMonth: 0,
    maincontractor: null,
    projectFilter: null,
    generatingPdf: false,
    key: 'vstyonte',
    contacts: Em.computed('maincontractor', function () {
        if (this.get('maincontractor')) {
            return this.store.query('exportContactPerson', {
                employer: this.get('maincontractor.id'),
            });
        } else {
            return this.store.findAll('exportContactPerson');
        }
    }),
    contactNotSet: Em.computed('contacts.[]', function () {
        var contacts;
        contacts = this.get('contacts').filter((contact) => {
            return contact.get('isNew') === false;
        });
        return contacts.length <= 0;
    }),
    //get 36 previous months
    months: Ember.computed(function () {
        var count, montharr, obj;
        count = 0;
        montharr = [];
        while (count < 36) {
            obj = {
                id: count,
                name: moment().subtract(count++, 'months').format('MMMM YYYY'),
            };
            montharr.push(obj);
        }
        return montharr;
    }),
    settings: Em.Object.create({
        form: 'export_contact_person',
    }),
    values: Ember.computed(
        'selectedMonth',
        'maincontractor',
        'projectFilter',
        'ownerFilter',
        function () {
            var arr, moment_month, month;
            month = this.get('selectedMonth');
            moment_month = moment().subtract(month, 'months');
            arr = [
                {
                    name: 'date',
                    value:
                        moment_month.startOf('month').format('YYYY-MM-DD') +
                        '_' +
                        moment_month.endOf('month').format('YYYY-MM-DD'),
                },
            ];
            if (this.get('projectFilter')) {
                arr.push({
                    name: 'project',
                    value: this.get('projectFilter.id'),
                });
            }
            if (this.get('maincontractor') && !this.get('projectFilter')) {
                arr.push({
                    name: 'maincontractor',
                    value: this.get('maincontractor.id'),
                });
            }
            if (this.get('ownerFilter')) {
                arr.push({
                    name: 'owner',
                    value: this.get('ownerFilter.id'),
                });
            }
            return arr;
        },
    ),
    employerField: Ember.computed(function () {
        if (this.get('Collector').testNeedsOne('worktime')) {
            return 'worktime.employer';
        } else {
            return 'presence.employer';
        }
    }),
    projectField: Ember.computed(function () {
        if (this.get('Collector').testNeedsOne('worktime')) {
            return 'worktime.project';
        } else {
            return 'presence.project';
        }
    }),
    actions: {
        refreshPreview: function () {
            // Kikka miljoona is NO HAX
            return this.notifyPropertyChange('selectedMonth');
        },
        maincontractorChanged: function (value) {
            return this.set('maincontractor', value);
        },
        projectChanged: function (value) {
            return this.set('projectFilter', value);
        },
        ownerChanged: function (value) {
            return this.set('ownerFilter', value);
        },
        toggleModal: function (settings, record) {
            if (record) {
                this.notifyPropertyChange('contacts');
            }
            this.toggleProperty('showModal');
            return false;
        },
        exportPdf: function () {
            var data, filter, filters, i, len, promise, url;
            this.set('generatingPdf', true);
            filters = this.get('values');
            data = {};
            for (i = 0, len = filters.length; i < len; i++) {
                filter = filters[i];
                data[filter['name']] = filter['value'];
            }
            if (config.environment === 'development') {
                data.capi_url = 'http://capi/';
                data.ttapi_url = 'http://ttapi/';
            } else {
                data.capi_url = config.APP.COLLECTOR_API_URL + '/';
                data.ttapi_url = this.get('ttapi').host + '/';
            }
            data.dataType = 'text';
            data.type = 'base64';
            data.key = this.get('key');
            url = 'pdf/taxman';
            promise = this.get('Collector').ttapi({
                dataType: 'text',
                url: url,
                data: data,
                type: 'POST',
            });
            return promise.then((data) => {
                this.set('generatingPdf', false);
                return this.get('exportFile').exportFile(data, 'export', 'pdf', true);
            });
        },
    },
});

export default TaxmanTaxreportIndexController;
