import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default Route.extend({
    Collector: service('collector-service'),
    ttapi: service(),

    queryParams: {
        'ori_site': {
            refreshModel: true,
        },
        'pass_end:pass_end': {
            refreshModel: true,
        },
    },

    model(params) {
        // if pass_end qParam is given, pass it to the form-report component and set the pass_ends_range filter to the given value
        // so that we can transition (from anomalies widget) to the report with the pass_ends_range filter set
        set(this.controllerFor('orientations/passes'), 'qParams', params);
        set(
            this.controllerFor('orientations/passes'),
            'pass_ends_range',
            params['pass_end:pass_end'],
        );

        if (this.Collector.testNeeds(['products.rfid'])) {
            return RSVP.hash({
                timecleanProjects: get(this, 'ttapi')._get('/api/gateList/projects'),
            });
        }
    },
});
