import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';

export default class UsersInactiveController extends Controller {
    @service ttapi;

    days = 7;

    date = moment().subtract(this.days, 'days').format('YYYY-MM-DD');

    constructor() {
        super(...arguments);
        this.endpoint = this.ttapi.host + '/inactive_users_v2';
    }

    @action
    setDaysAndSendChange (field, sendAction, value) {
        set(this, field, moment().diff(value, 'days'))
        sendAction(this.days, field);
    }

}
