/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/saved/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersSavedController;

import Ember from 'ember';

import savedController from '../../../controllers/saved';

import config from '../../../config/environment';

UsersSavedController = savedController.extend({
    intl: Ember.inject.service(),
    //    needs: 'orientations/add'
    orientationAddController: Ember.inject.controller('orientations/add'),
    hideAddSame: true,
    showAddOrientation: Ember.computed(function () {
        return this.Collector.formExists('orientation');
    }),
    email: null,
    email_send_status: null,
    email_sent: false,
    email_error: false,
    phone_error: false,
    sms_send_status: null,
    sms_sent: false,
    reset: function () {
        this.set('sms_sent', false);
        return this.set('email_sent', false);
    },
    actions: {
        addOrientation: function () {
            var controller, user;
            user = this.get('model.id');
            controller = this.get('orientationAddController');
            controller.set('users', Em.A([parseInt(user, 10)]));
            return this.transitionToRoute('orientations.add');
        },
        send_sms: function () {
            var model, phone, promise, self;
            model = this.get('model');
            phone = model.get('phone');
            if (!/^[+]?[0-9]{10,20}$/i.test(phone)) {
                this.set('phone_error', this.get('intl').t('field.error.phone'));
                return;
            }
            if (!/^[+]/i.test(phone)) {
                this.set('phone_error', this.get('intl').t('field.error.country_code'));
                return;
            }
            this.set('phone_error', false);
            if (model.get('isDirty')) {
                model.save();
            }
            this.set('sms_sent', true);
            this.set('sms_send_status', this.get('intl').t('general.sending'));
            self = this;
            promise = this.Collector.ttapi({
                url: 'sendSms',
                data: {
                    phone: model.get('phone'),
                    messageid: 'new_user_added',
                    values: {
                        userid: model.get('id'),
                        product: config.brand === 'otta' ? 'Otta' : 'Visma Movenium',
                    },
                    language: moment.locale(),
                },
                method: 'post',
            });
            return promise.then(
                function (ret) {
                    return self.set(
                        'sms_send_status',
                        self.get('intl').t('users.credentials_sent'),
                    );
                },
                function (ret) {
                    return self.set('sms_send_status', 'ERROR: ' + ret);
                },
            );
        },
        sendEmail: function () {
            var content, email, model, promise;
            model = this.get('model');
            email = model.get('email');
            if (
                !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
                    email,
                )
            ) {
                this.set('email_error', this.get('intl').t('field.error.email'));
                return;
            }
            this.set('email_error', false);
            if (model.get('isDirty')) {
                model.save();
            }
            this.set('email_sent', true);
            this.set('email_send_status', this.get('intl').t('general.sending'));
            content =
                this.get('intl').t('user.action.email.email_content', {
                    url: window.location.origin,
                }) +
                '<br><br>' +
                this.get('intl').t('user.action.email.email_username') +
                ': ' +
                model.get('username') +
                '<br>' +
                this.get('intl').t('user.action.email.email_password') +
                ': ' +
                model.get('password') +
                '<br>';
            promise = this.Collector.ttapi({
                url: 'sendEmail',
                data: {
                    email: email,
                    title:
                        this.get('session.currentUser.company') +
                        ' - ' +
                        this.get('intl').t('user.action.email.email_title'),
                    content: content.replace(/<br ?\/?>/g, '\n'),
                },
                method: 'post',
            });
            return promise.then(() => {
                return this.set('email_send_status', this.get('intl').t('users.credentials_sent'));
            });
        },
    },
});

export default UsersSavedController;
