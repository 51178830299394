import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import fetch from 'fetch';
import { tracked } from '@glimmer/tracking';

export default class CustomExportComponent extends Component {
    @service('collector-service') collector;
    @service router;
    @service store;
    @service intl;
    @service error;
    @service() notifications;
    @service ttapi;
    @service('export-file') exportFile;
    @service dialogs;

    defaultsForCustomExport = {
        encoding: 'utf8',
        separator: 'comma',
        decimal: 'colon',
        linebreak: 'lf',
        headers: true,
        integrationtype: 'salary',
        absences: true,
        templateId: null,
    };

    texts = {
        utf8: 'Unicode UTF-8',
        latin1: 'Latin 1',
        lf: 'LF',
        lfcr: 'LFCR',
        showIntegration: 'show integration',
    };

    get styleNamespace() {
        return podNames['custom-export-comp'];
    }

    get columnList() {
        return this.createColumnList();
    }

    @tracked isLoadingPreview = false;
    @tracked isSaving = false;
    @tracked isLoadingFile = false;
    @tracked exportList = [];
    @tracked validate = false;
    @tracked absenceTypes = [];
    @tracked record;
    @tracked templateList = [];

    constructor() {
        super(...arguments);
        this.loadExportList();
        this.createTemplateList();
        set(this, 'record', this.store.createRecord('custom-export', this.defaultsForCustomExport));
        set(this, 'filterPreview', {
            date:
                moment().startOf('week').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('week').format('YYYY-MM-DD'),
        });
        set(this, 'dateFormatList', ['YYYY-MM-DD', 'DD.MM.YYYY', 'YYYY/MM/DD']);
        this.absenceTypes = this.getAbsenceTypes();
    }

    @action
    addColumn() {
        if (!this.record.columns) this.record.columns = [{}];
        else this.record.columns.pushObject({});
    }

    @action
    async save() {
        this.isSaving = true;
        this.validate = false;

        if (this.checkValidations()) {
            this.validate = true;
            this.isSaving = false;
            return;
        }

        const firstSave = this.record.isNew;
        try {
            await this.record.save();
            if (firstSave) this.loadExportList();
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
        } catch (e) {
            this.notifications.error(this.error.toString(e), { autoClear: true });
        }
        this.isSaving = false;
    }

    @action
    delete(column) {
        this.record.columns.removeObject(column);
    }

    @action
    move(direction, column) {
        const originalIndex = this.record.columns.indexOf(column);

        if (direction === 'up') {
            if (originalIndex === 0) return;
            this.record.columns.removeObject(column);
            this.record.columns.insertAt(originalIndex - 1, column);
        }

        if (direction === 'down') {
            if (originalIndex === this.record.columns.length - 1) return;
            this.record.columns.removeObject(column);
            this.record.columns.insertAt(originalIndex + 1, column);
        }
    }

    @action
    goto(record) {
        set(this, 'record', record);
    }

    @action
    createNew() {
        set(this, 'record', this.store.createRecord('custom-export', this.defaultsForCustomExport));
    }

    @action
    async remove(record) {
        if (await this.dialogs.confirm(this.intl.t('general.remove_row'))) {
            record.destroyRecord();
            if (record === this.record)
                set(
                    this,
                    'record',
                    this.store.createRecord('custom-export', this.defaultsForCustomExport),
                );
        }
    }

    @action
    updateColumnKey(column, event) {
        const obj = this.columnList.findBy('key', event.target.value);

        if (obj.type === 'date') set(column, 'dateformat', this.dateFormatList[0]); // auto select default dateformat

        set(column, 'type', obj.type);
        set(column, 'key', obj.key);
    }

    @action
    filterChange(value, field) {
        if (!field) return;
        set(this.filterPreview, field, value);

        if (this.previewHasBeenLoaded) this.updatePreview();
    }

    @action
    async updatePreview() {
        this.isLoadingPreview = true;
        const data = await this.fetchExport();
        set(this, 'previewStr', data);
        set(this, 'previewHasBeenLoaded', true);
        this.isLoadingPreview = false;
    }

    @action
    async loadFile() {
        this.isLoadingFile = true;
        const data = await this.fetchExport();
        this.exportFile.exportFile(data, 'preview', 'csv');
        this.isLoadingFile = false;
    }

    @action
    toggleType(type) {
        this.record.integrationtype = type;
        if (type === 'absences') this.record.absences = true;
    }

    @action
    setAbsenceTypes(type) {
        if (!this.record.absencetypes) this.record.absencetypes = [];
        if (this.record.absencetypes.findBy('id', type.id)) {
            this.record.absencetypes.removeObject(type);
        } else {
            this.record.absencetypes.pushObject(type);
        }
    }

    checkValidations() {
        let somethingIsMissing = false;
        if (!this.record.name) somethingIsMissing = true;
        if (this.record.columns) {
            for (let column of this.record.columns) {
                if (!column.header) somethingIsMissing = true;
                if (column.type === 'database' && !column.subfield) somethingIsMissing = true;
            }
        }

        return somethingIsMissing ? true : false;
    }

    async loadExportList() {
        set(this, 'exportList', await this.store.query('custom-export', {}));
    }

    // Salary templates
    createTemplateList() {
        const personec = {
            name: 'Personec',
            columns: [
                {
                    header: 'Henkilönumero',
                    type: 'database',
                    key: 'user',
                    subfield: 'number',
                },
                {
                    header: 'Palkkalaji',
                    key: 'salarycode',
                },
                {
                    header: 'Määrä',
                    key: 'amount',
                },
                {
                    header: 'Päivämäärä',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'date',
                },
                {
                    header: 'Seurantataso',
                    type: 'database',
                    key: 'project',
                    subfield: 'number',
                },
            ],
            encoding: 'utf8',
            separator: 'semicolon',
            decimal: 'comma',
            linebreak: 'lf',
            quotes: false,
            headers: false,
            integrationtype: 'salary',
            absences: true,
            onerowabsences: false,
        };

        const mepco = {
            name: 'Mepco',
            columns: [
                {
                    header: 'PVM',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'date',
                },
                {
                    header: 'Filleri 1',
                },
                {
                    header: 'TYNRO',
                    type: 'database',
                    key: 'user',
                    subfield: 'number',
                },
                {
                    header: 'PLNRO',
                    key: 'salarycode',
                },
                {
                    header: 'KPL/MÄÄRÄ',
                    key: 'amount',
                },
                {
                    header: 'Filleri2',
                },
                {
                    header: 'KUSTPA',
                    type: 'database',
                    key: 'project',
                    subfield: 'number',
                },
            ],
            encoding: 'utf8',
            separator: 'semicolon',
            decimal: 'comma',
            linebreak: 'lf',
            quotes: false,
            headers: false,
            integrationtype: 'salary',
            absences: true,
            onerowabsences: false,
        };

        const procountor = {
            name: 'Procountor',
            columns: [
                {
                    header: 'Rivityyppi',
                    type: 'static',
                    key: 'static',
                    static: 'E',
                },
                {
                    header: 'Henkilönumero',
                    type: 'database',
                    key: 'user',
                    subfield: 'number',
                },
                {
                    header: 'Palkkalajin koodi',
                    key: 'salarycode',
                },
                {
                    header: 'Palkkalajin nimi',
                },
                {
                    header: 'Rivin lukumäärä',
                    key: 'amount',
                },
                {
                    header: 'Rivin yksikköhinta',
                },
                {
                    header: 'Dimension nimi 1',
                },
                {
                    header: 'Nimikkeen nimi 1',
                },
                {
                    header: 'Dimension nimi 2',
                },
                {
                    header: 'Nimikkeen nimi 2',
                },
                {
                    header: 'Dimension nimi 3',
                },
                {
                    header: 'Nimikkeen nimi 3',
                },
                {
                    header: 'Dimension nimi 4',
                },
                {
                    header: 'Nimikkeen nimi 4',
                },
                {
                    header: 'Dimension nimi 5',
                },
                {
                    header: 'Nimikkeen nimi 5',
                },
                {
                    header: 'Dimension nimi 6',
                },
                {
                    header: 'Nimikkeen nimi 6',
                },
                {
                    header: 'Ansaintajakson alku pvm',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'date',
                },
                {
                    header: 'Ansaintajakson loppu pvm',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'date',
                },
                {
                    header: 'Rivikommentti',
                    type: 'textbox',
                    key: 'description',
                },
            ],
            encoding: 'utf8',
            separator: 'semicolon',
            decimal: 'colon',
            linebreak: 'lf',
            quotes: false,
            headers: false,
            integrationtype: 'salary',
            absences: true,
            onerowabsences: false,
        };

        const talenom_tyoajat = {
            name: 'Talenom - Työajat',
            columns: [
                {
                    header: 'PVM',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'date',
                },
                {
                    header: 'TYNRO',
                    type: 'database',
                    key: 'user',
                    subfield: 'number',
                },
                {
                    header: 'PLAJI',
                    key: 'salarycode',
                },
                {
                    header: 'MÄÄRÄ',
                    key: 'amount',
                },
                {
                    header: 'TYHJÄ 1',
                },
                {
                    header: 'TYHJÄ 2',
                },
                {
                    header: 'TYHJÄ 3',
                },
                {
                    header: 'PROJ',
                    key: 'project',
                    type: 'database',
                    subfield: 'number',
                },
            ],
            encoding: 'utf8',
            separator: 'semicolon',
            decimal: 'colon',
            linebreak: 'lf',
            quotes: false,
            headers: false,
            integrationtype: 'salary',
            absences: false,
            onerowabsences: false,
        };

        const talenom_poissaolot = {
            name: 'Talenom - Poissaolot',
            columns: [
                {
                    header: 'TYNRO',
                    type: 'database',
                    key: 'user',
                    subfield: 'number',
                },
                {
                    header: 'APVM',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'startdate',
                },
                {
                    header: 'LPVM',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'enddate',
                },
                {
                    header: 'KOODI',
                    type: 'database',
                    key: 'abcensetype',
                    subfield: 'key',
                },
                {
                    header: 'TUNNIT',
                    type: 'hours',
                    key: 'absence_hours',
                },
            ],
            encoding: 'utf8',
            separator: 'semicolon',
            decimal: 'colon',
            linebreak: 'lf',
            quotes: false,
            headers: false,
            integrationtype: 'absences',
            absences: true,
            onerowabsences: true,
        };

        const talenom_vuosilomat = {
            name: 'Talenom - Vuosilomat',
            columns: [
                {
                    header: 'TYNRO',
                    type: 'database',
                    key: 'user',
                    subfield: 'number',
                },
                {
                    header: 'APVM',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'startdate',
                },
                {
                    header: 'LPVM',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'enddate',
                },
                {
                    header: 'KOODI',
                    type: 'database',
                    key: 'abcensetype',
                    subfield: 'absence_code',
                },
            ],
            encoding: 'utf8',
            separator: 'semicolon',
            decimal: 'colon',
            linebreak: 'lf',
            quotes: false,
            headers: false,
            integrationtype: 'absences',
            absences: true,
            onerowabsences: true,
        };

        const fennoa_tyoajat = {
            name: 'Fennoa - Työajat',
            columns: [
                {
                    header: 'Henkilönumero',
                    type: 'database',
                    key: 'user',
                    subfield: 'number',
                },
                {
                    header: 'Palkkalajin koodi',
                    key: 'salarycode',
                },
                {
                    header: 'Päivämäärä',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'date',
                },
                {
                    header: 'Määrä',
                    key: 'amount',
                },
                {
                    header: 'Hinta',
                },
                {
                    header: 'Kerroin',
                },
                {
                    header: 'Selite',
                },
                {
                    header: 'Poissaolo alkaa',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'startdate',
                },
                {
                    header: 'Poissaolo päättyy',
                    dateformat: 'DD.MM.YYYY',
                    type: 'date',
                    key: 'enddate',
                },
                {
                    header: 'Poissaolo päivät',
                    key: 'dayamount',
                },
                {
                    header: 'Dimensio1',
                },
                {
                    header: 'Dimensio2',
                },
                {
                    header: 'Dimensio3',
                },
                {
                    header: 'Dimensio4',
                },
            ],
            encoding: 'utf8',
            separator: 'semicolon',
            decimal: 'colon',
            linebreak: 'lf',
            quotes: false,
            headers: false,
            integrationtype: 'salary',
            absences: true,
            onerowabsences: true,
            templateId: 'Fennoa',
        };

        this.templateList = [
            personec,
            mepco,
            procountor,
            talenom_tyoajat,
            talenom_poissaolot,
            talenom_vuosilomat,
            fennoa_tyoajat,
        ];
    }

    @action
    createTemplateFields(template) {
        set(this, 'record', this.store.createRecord('custom-export', template));
    }

    createColumnList() {
        const whiteListTypes = ['date', 'database', 'textbox'];
        const absenceWhiteListTypes = ['date', 'database', 'textbox', 'hours', 'number'];
        const blackListFields = [
            'export_salary',
            'salarytype',
            'location',
            'end_location',
            'superior',
        ];
        const columnList = [
            { key: 'static', name: this.intl.t('custom-export.static'), type: 'static' },
            { key: 'salarycode', name: this.intl.t('custom-export.salary-code') },
            { key: 'amount', name: this.intl.t('custom-export.amount') },
        ];

        if (this.record.integrationtype === 'salary') {
            let worktimeFieldArray = this.collector
                .fieldArray('worktime')
                .filter(
                    (item) =>
                        whiteListTypes.includes(item.type) && !blackListFields.includes(item.name),
                );
            for (let field of worktimeFieldArray)
                columnList.push({ key: field.name, name: field.translated_name, type: field.type });
        }

        if (this.record.absences) {
            let absencesFieldArray = this.collector
                .fieldArray('abcense')
                .filter((item) => absenceWhiteListTypes.includes(item.type));
            for (let field of absencesFieldArray) {
                if (!columnList.findBy('key', field.name))
                    columnList.push({
                        key: field.name,
                        name: field.translated_name,
                        type: field.type,
                    });
            }
            if (!columnList.findBy('key', 'date')) {
                let dateField = this.collector.field('worktime', 'date');
                columnList.push({
                    key: dateField.name,
                    name: dateField.translated_name,
                    type: dateField.type,
                });
            }
        }

        return columnList.sort((a, b) => ('' + a.name).localeCompare(b.name));
    }

    async fetchExport() {
        const headers = this.ttapi.headers;
        headers['Content-Type'] = 'application/json';
        const response = await fetch(
            this.ttapi.host + '/api/custom-export?salaryversion=' + this.getVersion(),
            {
                headers: headers,
                method: 'POST',
                body: JSON.stringify(
                    Object.assign(
                        this.filterPreview,
                        {
                            limit: 25,
                            preview: true,
                            debug: this.debugShowIntegration ? 'integration' : null,
                        },
                        { export: this.record.toJSON() },
                    ),
                ),
            },
        );
        return await response.text();
    }

    async getAbsenceTypes() {
        const types = await this.store.findAll('abcensetype');
        return types.map((type) => {
            return { id: type.id, text: type.name };
        });
    }

    getVersion() {
        if (this.collector.testNeeds(['products.salarytype_v2'])) return 2;
        else return 1;
    }
}
