import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, get } from '@ember/object';

export default Component.extend({
    flags: service(),
    tagName: 'td',
    classNames: 'title-cell',
    // we can use classnamebindings to add a css-class to the element based on some computed property
    classNameBindings: ['isTopMenu:top-menu-left-margin-fix'],
    isTopMenu: computed(function () {
        return get(this, 'flags').test('top-header-menu');
    }),
});
