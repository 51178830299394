import podNames from 'ember-component-css/pod-names';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import moment from 'moment';

export default class CopyPreviousDayIntermediateComponent extends Component {
    @tracked record;
    @tracked form = this.args.form;

    @service router;
    @service store;
    @service notifications;
    @service('collector-service') collector;
    @service intl;
    @service session;
    @service ttapi;

    @tracked selectedWorktimes = [];
    @tracked selectedDate = null;
    @tracked selectedDateJS = null;
    @tracked isLoading = false;

    @tracked dayLabel = "";

    constructor() {
        super(...arguments);

        if (this.selectedDate === null) {
            this.selectedDate = this.getPreviousWorkingDay();
            this.daySelected(this.selectedDate);
        }
        this.dayLabel = moment(this.selectedDate).format('DD.MM.YYYY');

        const date = moment(this.dayLabel, 'DD.MM.YYYY').toDate();
        const options = { weekday: 'long', locale: this.intl.locale };
        const weekdayName = date.toLocaleDateString(this.intl.locale, options);
        this.dayLabel =
            weekdayName.charAt(0).toUpperCase() + weekdayName.slice(1) + ' ' + this.dayLabel;
    }

    getPreviousWorkingDay() {
        let previousWorkingDay = moment().subtract(1, 'day');
        while (previousWorkingDay.day() === 0 || previousWorkingDay.day() === 6) {
            previousWorkingDay.subtract(1, 'day');
        }
        return previousWorkingDay.toDate();
    }

    selectedDayChanged () {
        if(this.selectedDate === null) this.dayLabel = moment().format('DD.MM.YYYY');
        else this.dayLabel = moment(this.selectedDate).format('DD.MM.YYYY');

        const date = moment(this.dayLabel, 'DD.MM.YYYY').toDate();
        const options = { weekday: 'long', locale: this.intl.locale };
        const weekdayName = date.toLocaleDateString(this.intl.locale, options);
        this.dayLabel =
            weekdayName.charAt(0).toUpperCase() + weekdayName.slice(1) + ' ' + this.dayLabel;
    }

    @computed('selectedWorktimes')
    get showSaving() {
        if((Array.isArray(this.selectedWorktimes) && this.selectedWorktimes.length > 0)) {
            return true;
        } else return false;
    }

    async getWorktimes() {
        const params = {
            date: this.selectedDate + '_' + this.selectedDate,
            user: this?.session?.currentUser?.id,
            sideload: true,
            limit: 500,
        };
        let query = await this.store.query('worktime', params);
        this.selectedWorktimesTemp = [];
        query.forEach((worktime) => {
            this.selectedWorktimesTemp.push(worktime);
        });
        this.selectedWorktimes = this.selectedWorktimesTemp;
    }

    get styleNamespace() {
        return podNames['workday-summary/copy-previous-day-component/copy-previous-day-intermediate-component'];
    }

    @action closeClicked() {
        this.args.close();
    }

    @action async daySelected(date) {
        this.selectedDate = moment(date).format('YYYY-MM-DD');
        this.selectedDateJS = date;
        this.selectedDayChanged();
        this.isLoading = true;
        await this.getWorktimes();
        this.isLoading = false;
    }

    @action select() {
        this.args.datePicked(this.selectedDate, this.selectedWorktimes);
    }
}
