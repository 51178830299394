/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/record-comp-export.coffee.old
// Generated by CoffeeScript 2.6.1
var RecordCompExportComponent;

import Ember from 'ember';

import recordComp from '../pods/collector/record-comp/component';

import config from '../config/environment';

import { isTimeoutError } from 'ember-ajax/errors';

RecordCompExportComponent = recordComp.extend({
    layoutName: 'collector/record-comp',
    Collector: Em.inject.service('collector-service'),
    intl: Em.inject.service(),
    store: Em.inject.service(),
    dialogs: Em.inject.service(),
    ttapi: Em.inject.service(),
    session: Em.inject.service(),
    error: Em.inject.service(),
    ajax: Em.inject.service(),
    cc: Em.inject.service(),
    flags: Em.inject.service(),
    save_record: function () {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var record, user;
            record = this.get('record');
            //get user before saving, because that gets somehow current user
            user = record.get('user');
            // we must save the record (the export row) once here so we can get it's id before calling cc
            return record.save().then((back) => {
                return this.get('store')
                    .findRecord('wage_period', record.get('export_period_name.id'), {
                        reload: true,
                    })
                    .then(() => {
                        var field, fields, include_old_rows, options, params;
                        if (record.get('include_old_rows')) {
                            params = [
                                'date=' +
                                    moment(record.get('export_period_name.period_start_date'))
                                        .subtract(3, 'months')
                                        .format('YYYY-MM-DD') +
                                    '_' +
                                    record.get('export_period_name.period_end_date'),
                            ];
                        } else {
                            params = [
                                'date=' +
                                    record.get('export_period_name.period_start_date') +
                                    '_' +
                                    record.get('export_period_name.period_end_date'),
                            ];
                        }
                        params.push('exportid=' + back.id);
                        if (user) {
                            params.push('user=' + user.get('id'));
                        }
                        if ((include_old_rows = record.get('include_old_rows'))) {
                            params.push('include_old_rows=' + include_old_rows);
                        }
                        if (record.get('worktimegroup')) {
                            params.push('worktimegroup=' + record.get('worktimegroup.id'));
                        }
                        params.push('export_wage_period_id=' + record.get('export_period_name.id'));
                        fields = this.get('Collector').fields(this.get('form'));
                        for (field in fields) {
                            options = fields[field];
                            if (
                                !(
                                    field === 'worktimegroup' ||
                                    field === 'date' ||
                                    field === 'export_description' ||
                                    field === 'export_name' ||
                                    field === 'export_period_file' ||
                                    field === 'export_period_name' ||
                                    field === 'include_old_rows' ||
                                    field === 'user'
                                )
                            ) {
                                params.push(
                                    field +
                                        '=' +
                                        (options.type === 'database'
                                            ? record.get(field + '.id')
                                            : record.get(field)),
                                );
                            }
                        }
                        Ember.assert(
                            !this.get('selectedIntegration'),
                            'integration is not selected.. this should never happen',
                        );
                        return this.callCC(
                            this.get('selectedIntegration'),
                            params,
                            record,
                            resolve,
                            reject,
                        );
                    });
            });
        });
    },
    callCC: function (key, params, record, resolve, reject) {
        var access_token, data, promise, queryParams, queryParamsString, timeout;
        data = {};
        params.forEach((item) => {
            return (data[item.split('=')[0]] = item.split('=')[1]);
        });
        if (config.environment === 'development') {
            data.capi_url = 'http://capi/';
            data.ttapi_url = 'http://ttapi/';
            data.cc_url = 'http://ccbackend/trigger/';
        } else {
            data.capi_url = config.APP.COLLECTOR_API_URL + '/';
            data.ttapi_url = this.get('ttapi').host + '/';
            data.cc_url = this.get('cc').host + '/';
        }
        access_token = this.get('session.session.authenticated.access_token');
        data.token = access_token;
        data.exportName = record.get('constructor.modelName');
        timeout = 50000;
        //promise = @get('ttapi')._post "/ccrequest/" + key, JSON.stringify(data)
        queryParams = [];
        if (this.getSalaryVersion() !== 1) {
            queryParams.push('salaryversion=' + this.getSalaryVersion());
        }
        // removing the flag
        // Remove these lines and ttapi ccController row that supports this "private" queryParam. And change ttapi so public=true is never used.
        if (this.get('flags').test('private-export-files')) {
            queryParams.push('private=true');
        }
        if (this.get('flags').test('export-slow-ccrequest')) {
            queryParams.push('slowRequest=true'); // this parameter makes ttapi to call cc with webhooks that saves the response file when cc is done
            timeout = 1000; // we timeout request every time so user is informed that file is returned later
        }

        // if key already has ? in it (for example ?_v=1), do not add ? again, use & instead
        if (key.indexOf('?') === -1) {
            queryParamsString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
        } else {
            queryParamsString = queryParams.length > 0 ? '&' + queryParams.join('&') : '';
        }
        promise = this.get('ajax').request(
            this.get('ttapi').host + '/ccrequest/' + key + queryParamsString,
            {
                method: 'post',
                timeout: timeout,
                headers: {
                    Authorization: 'Bearer ' + access_token,
                },
                data: JSON.stringify(data),
            },
        );
        return promise
            .then((back, textStatus, jqXHR) => {
                var downloadFile, message;
                back = back.ret;
                downloadFile = true;
                if (back === '') {
                    this.get('dialogs').alert(this.get('intl').t('export.no_data'));
                    reject();
                    return;
                }
                if (back.error != null) {
                    message = this.get('intl').t('general.ccerror', {
                        error: back.error,
                    });
                    this.get('dialogs').alert(
                        new Ember.String.htmlSafe(message.replace('\n', '<br>')),
                    );
                    reject();
                    return;
                }
                if (Ember.typeOf(back) === 'array') {
                    message = this.get('intl').t('general.ccresponsemessage', {
                        response: back.mapBy('response').join('\n'),
                    });
                    this.get('dialogs').alert(
                        new Ember.String.htmlSafe(message.replace(/\n/g, '<br>')),
                    );
                    back = back.mapBy('response').join('\n');
                    downloadFile = false;
                }
                return this.get('store')
                    .findRecord(record.get('constructor.modelName'), record.get('id'), {
                        reload: true,
                    })
                    .then((back) => {
                        var a, file_url, files;
                        files = back.get('export_period_file');
                        file_url = config.APP.COLLECTOR_API_URL + '/' + files[0].url;
                        if (downloadFile) {
                            a = document.createElement('a');
                            a.href = file_url;
                            a.target = '_blank';
                            a.download = files[0].name;
                            document.body.appendChild(a);
                            a.click();
                        }
                        return resolve();
                    });
            })
            .catch((reason) => {
                if (isTimeoutError(reason)) {
                    this.get('dialogs').alert(this.get('intl').t('export.timeout_error'));
                    return resolve();
                } else {
                    this.get('error').notify(reason);
                    return resolve();
                }
            });
    },
    getFilename: function (record, jqXHR) {
        var default_name, given, given_arr;
        default_name = record.get('export_name').replace(/[^A-Za-z0-9]/g, '') || 'export';
        default_name += '.csv';
        given = jqXHR.getResponseHeader('Content-Disposition');
        if (given) {
            given_arr = given.split('=');
            if (given_arr[0] === 'filename' && given_arr.length === 2) {
                return given_arr[1];
            }
        }
        return default_name;
    },
    getSalaryVersion: function () {
        if (this.get('Collector').testNeeds(['products.salarytype_v2'])) {
            return 2;
        } else {
            return 1;
        }
    },
});

export default RecordCompExportComponent;
