/* eslint-disable ember/named-functions-in-promises */
/* eslint-disable no-undef */
export function initializeVersionInfo(component) {
    component.version = '';

    if (typeof cordova !== 'undefined' && cordova !== null) {
      cordova.getAppVersion.getVersionNumber().then((version) => {
        component.version = `${component.intl.t('application.version')}: ${component.gui.version()} APP: ${version}`;
      });
    } else {
      component.version = `${component.intl.t('application.version')}: ${component.gui.version()}`;
    }
  }
