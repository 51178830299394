import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';
import environment from 'tt4/config/environment';

export default class UserDeletionComponent extends Component {
  @service store;
  @service collectorService;
  @service collector;
  @service capi;
  @service ttapi;
  @service intl;
  @service ajax;

  @tracked givenPassword = '';
  @tracked givenFullname = '';
  @tracked isDeleting = false;
  @tracked errorMessage = null;
  @tracked successMessage = null;
  @tracked isLoading = false;
  @tracked redirectTimeout = 10;
  whiteListForms = ['worktime', 'abcense', 'presence', 'trnotice'];
  shouldRedirect = false;
  @computed('whiteListForms')
  get forms() {
    const forms = this.whiteListForms.filter((form) => {
      return this.collectorService.testNeeds([form]);
    });
    const ret = forms.map((form) => {
        return { name: form, date: moment().format('YYYY-MM-DD')};
    });
    return ret;
  }
  @computed('args.user.{firstname,lastname}')
  get boldFullname() {
      return htmlSafe(
          `[<b>${this.args.user.firstname} ${this.args.user.lastname}</b>]`
      );
  }
  @action
    close() {
        this.args.close();
    }
    @action
    async deleteUser() {
      this.isDeleting = true;
      this.errorMessage = null;
      this.successMessage = null;
      this.isLoading = true;
      try {
        let user = this.args.user;
        let userId = user.id;
        let password = this.givenPassword;
        let nametest = this.givenFullname;
        let terminate = this.forms;
        await this.ttapi.request(`terminate-user/${userId}`, {
          method: 'post',
          data: {
            password,
            nametest,
            terminate
          }
        });
        this.successMessage = 'User and all related data successfully deleted.';
        this.shouldRedirect = true;
      } catch (error) {
        this.shouldRedirect = false;
        this.errorMessage = `Error deleting user: ${error.payload.message}`;
      } finally {
        this.isLoading = false;
        this.isDeleting = false;
        if (this.shouldRedirect) {
          this.startCountDownAndRedirect();
        }
      }
    }
    @action
    startCountDownAndRedirect() {
        // Start the countdown
        // Disable redirect when executing tests
        if (environment.environment === 'test') {
            return;
        }
        const countdown = setInterval(() => {
        if (this.redirectTimeout > 0) {
            this.redirectTimeout--;
        } else {
            clearInterval(countdown);
            // Redirect user back to users route.
            window.location.href = '/';
        }
        }, 1000);
    }


}
