import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),
    session: service(),
    evented: service(),
    ttapi: service(),
    intl: service(),
    localstorage: service(),
    router: service(),
    collector: service('collector-service'),
    notifications: service(),
    analytics: service(),
    user: null,
    project: null,
    worktimegroup: null,
    showOkAlso: true,

    tableLayout: computed(function () {
        if (this.localstorage.getItem('anomalitywidget-layout')) {
            return this.localstorage.getItem('anomalitywidget-layout');
        } else {
            return 'report';
        }
    }),

    missingWorktimesPerUser: computed('anomalities', async function () {
        const anomalities = this.anomalities.findBy('type', 'missing_worktimes');
        let data = anomalities.data;
        const userIds = this.unique(data.mapBy('user'));
        const users = anomalities.users;
        return userIds.map((userid) => {
            const user = users.findBy('id', userid);
            const missing_dates = data
                .filter((item) => item.user === userid)
                .map((item) => item.date + ' 00:00:00Z');
            return {
                userid: userid,
                name: `${user.firstname} ${user.lastname}`,
                missing_dates: missing_dates,
                email: user.email,
                phone: user.phone,
            };
        });
    }),

    saldo: computed('anomalities', function () {
        return get(this, 'anomalities').findBy('type', 'saldo').data;
    }),

    taxman_errors: computed('anomalities', function () {
        return get(this, 'anomalities').findBy('type', 'taxman_errors').data;
    }),

    init() {
        this._super();
        const promise = this.loadAnomalities();
        set(this, 'promise', promise);

        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
        this.evented.on('dashboardProjectChanged', this, 'dashboardProjectChanged');
    },

    didDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
        this.evented.off('dashboardProjectChanged', this, 'dashboardProjectChanged');
    },

    actions: {
        /* general actions */
        setEditableSetting(setting) {
            set(this, 'showSettings', false);
            set(this, 'currentSetting', setting);
        },
        closeCurrentSetting() {
            set(this, 'showSettings', true);
            set(this, 'currentSetting', false);
        },
        changeLayout(layout) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Use ${layout} layout`,
                label: 'Anomalities widget',
            });
            this.localstorage.setItem('anomalitywidget-layout', layout);
            //set(this,'tableLayout',layout)
            this.notifyPropertyChange('tableLayout');
        },
        gotoAnomality(anomality) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Binoculars clicked for ${anomality.type}`,
                label: 'Anomalities widget',
            });
            if (anomality.link) {
                this.router.transitionTo(anomality.link.route, {
                    queryParams: anomality.link.query_params,
                });
            } else {
                set(this, 'showAnomality', anomality.type);
                set(this, 'anomalityValue', anomality.value);
            }
        },

        /* settings-actions */
        async saveSettings() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Save settings',
                label: 'Anomalities widget',
            });
            set(this, 'showSettings', false);
            set(this, 'promise', this.saveSettingsAndReloadAnomalities());
        },

        closeSettings() {
            set(this, 'showSettings', false);
            set(this, 'promise', this.loadAnomalities());
        },

        /* missing-worktimes actions */
        async toggleCheckAll(check) {
            let data = await this.missingWorktimesPerUser;
            data.map((row) => set(row, 'checked', check));
        },
        async toggleCheckRow(row) {
            set(row, 'checked', row.checked ? false : true);

            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Show ${row.type} set ${row.checked}`,
                label: 'Anomalities widget',
            });

            let missingWorktimes = await this.missingWorktimesPerUser;
            if (!missingWorktimes) return;
            set(this, 'allRowsChecked', missingWorktimes.isEvery('checked', true));
        },

        async sendNotificationToCheckedUsers() {
            let users = [...(await this.missingWorktimesPerUser)];
            let sendTo = users.filter((user) => user.checked);
            let sendBy = this.getSendBySetting('missing_worktimes', 'push');
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Send notification to selected users by ${sendBy}`,
                label: 'Anomalities widget',
            });
            sendTo.forEach((user) => {
                let content = this.getNoticeContent(user);
                this.sendNotice(sendBy, user, content);
            });
        },

        showSettings() {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: 'Open settings',
                label: 'Anomalities widget',
            });
            set(this, 'showSettings', true);
        },
    },

    onStoreEvent(params) {
        if (params.type === 'worktime' || params.type === 'abcense')
            set(this, 'promise', this.loadAnomalities());
    },

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        set(this, 'promise', this.loadAnomalities());
    },

    getNoticeContent(user) {
        let message = this.intl.t('widget.anomalities.missing-worktimes.noticeContent') + '\n';
        user.missing_dates.map((date) => (message += moment(date).format('DD.MM.') + ' '));
        return message;
    },

    sendNotice(sendBy, user, content) {
        let title = this.intl.t('widget.anomalities.missing-worktimes.noticeTitle');
        switch (sendBy) {
            case 'email':
                if (user.email)
                    return this.sender(
                        'sendEmail',
                        { email: user.email, title: title, content: content },
                        user.name,
                    );
                return this.notifications.error(
                    this.intl.t('widget.anomalities.missing-worktimes.noEmailAddress', {
                        user: user.name,
                    }),
                    {
                        autoClear: true,
                    },
                );

            case 'sms':
                if (user.phone)
                    return this.sender(
                        'sendSms',
                        {
                            phone: user.phone,
                            messageid: 'anomalities_notice',
                            language: moment.locale(),
                            values: {
                                days: user.missing_dates
                                    .map((date) => moment(date).format('DD.MM.'))
                                    .join(','),
                            },
                        },
                        user.name,
                    );
                return this.notifications.error(
                    this.intl.t('widget.anomalities.missing-worktimes.noPhone', {
                        user: user.name,
                    }),
                    {
                        autoClear: true,
                    },
                );

            case 'push':
                return this.sender(
                    'push',
                    { userids: JSON.stringify([user.userid]), title: title, message: content },
                    user.name,
                );
        }
    },
    async sender(url, data, user) {
        try {
            await this.ttapi.request(url, {
                dataType: 'text',
                data: data,
                method: 'post',
            });
        } catch (e) {
            return this.notifications.error(e, { autoClear: true });
        }
        this.notifications.success(
            this.intl.t('widget.anomalities.missing-worktimes.noticeSent', { user: user }),
            {
                autoClear: true,
            },
        );
    },

    async loadAnomalities() {
        let c = this.collector;
        const settings = await this.store.findAll('widget-anomalies-setting');

        if (settings.findBy('name', 'show-ok'))
            set(this, 'showOkAlso', settings.findBy('name', 'show-ok').value);

        let arr = c.needsOfArray([
            {
                type: 'missing_worktimes',
                needs: ['worktime'],
                hasSettings: true,
                checked: this.isChecked('missing_worktimes', settings),
            },
            {
                type: 'saldo',
                needs: ['products.saldo'],
                checked: this.isChecked('saldo', settings),
            },
            {
                type: 'ending_passes',
                needs: ['products.orientations||products.orientations_new', 'orientation@access'],
                hasSettings: true,
                checked: this.isChecked('ending_passes', settings),
                link: {
                    route: 'orientations.passes',
                    query_params: {
                        order: 'pass_end-',
                        'pass_end:pass_end': this.getDaterange('ending_passes'),
                        'ori_site': this.project,
                    },
                },
            },
            {
                type: 'unapproved_worktimes',
                needs: ['worktime', 'worktime@access'],
                hasSettings: true,
                checked: this.isChecked('unapproved_worktimes', settings),
                link: {
                    route: 'timetracker.worktime-list',
                    query_params: {
                        date: this.getDaterange('unapproved_worktimes'),
                        status: 'open',
                        project: this.project,
                    },
                },
            },
            {
                type: 'taxman_errors',
                needs: [
                    'export_taxman',
                    '!userlevel=1?',
                    'export_contact_person@access',
                    'urakkaraportointi@access',
                ],
                checked: this.isChecked('taxman_errors', settings),
            },
            {
                type: 'abcense',
                needs: ['abcense'],
                hasSettings: true,
                checked: this.isChecked('abcense', settings),
                link: {
                    route: 'abcenses',
                    query_params: { startdate: this.getDaterange('abcense'), status: 'open' },
                },
            },
        ]);

        const queryParams = ['show=' + arr.filterBy('checked', true).mapBy('type').join(',')];
        if (arr.findBy('type', 'unapproved_worktimes'))
            queryParams.push(
                'unapproved_worktimes_date=' + this.getDaterange('unapproved_worktimes'),
            );
        if (arr.findBy('type', 'ending_passes'))
            queryParams.push('ending_passes_date=' + this.getDaterange('ending_passes'));
        if (arr.findBy('type', 'missing_worktimes')) {
            queryParams.push('missing_worktimes_date=' + this.getDaterange('missing_worktimes'));
            const setting = this.store
                .peekAll('widget-anomalies-setting')
                .findBy('name', 'missing_worktimes');
            if (setting && setting.value.hideUserIds)
                queryParams.push('missing_worktimes_hide=' + setting.value.hideUserIds.join(','));
        }
        if (arr.findBy('type', 'abcense'))
            queryParams.push('abcense=' + this.getDaterange('abcense'));

        if (this.project) queryParams.push('project=' + this.project);

        set(this, 'anomalitiesSettings', arr);
        // get values from backend
        const response = await this.ttapi.request('api/anomalies?' + queryParams.join('&'));
        for (let type in response) Object.assign(arr.findBy('type', type), response[type]);

        set(this, 'anomalities', arr);
    },

    getDaterange(type) {
        const setting = this.store.peekAll('widget-anomalies-setting').findBy('name', type);
        const defaults = {
            unapproved_worktimes: 7,
            ending_passes: 7,
            missing_worktimes: 'current_week',
            abcense: 1,
        };
        const dateRange = setting ? setting.value.selectedDateRange : defaults[type];

        if (type === 'ending_passes')
            return (
                moment().format('YYYY-MM-DD') +
                '_' +
                moment().add(dateRange, 'days').format('YYYY-MM-DD')
            );
        if (type === 'abcense')
            return (
                moment().subtract(3, 'month').format('YYYY-MM-DD') +
                '_' +
                moment().add(dateRange, 'months').format('YYYY-MM-DD')
            );

        if (dateRange === 'current_week')
            return (
                moment().startOf('isoWeek').format('YYYY-MM-DD') +
                '_' +
                moment().format('YYYY-MM-DD')
            );
        if (dateRange === 'last_week')
            return (
                moment().startOf('isoWeek').subtract(1, 'week').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('isoWeek').subtract(1, 'week').format('YYYY-MM-DD')
            );
        if (dateRange === 'last_2_weeks')
            return (
                moment().startOf('isoWeek').subtract(2, 'week').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('isoWeek').subtract(1, 'week').format('YYYY-MM-DD')
            );
        if (dateRange === 'current_month')
            return (
                moment().startOf('month').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD')
            );
        if (dateRange === 'last_month')
            return (
                moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') +
                '_' +
                moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
            );

        // default style is to calculate days backwards
        return (
            moment().subtract(dateRange, 'days').format('YYYY-MM-DD') +
            '_' +
            moment().format('YYYY-MM-DD')
        );
    },

    getSendBySetting(type, defaultValue = null) {
        const setting = this.store.peekAll('widget-anomalies-setting').findBy('name', type);
        // if setting is never saved .. default to push
        return setting ? setting.value.sendBy : defaultValue;
    },

    isChecked(name, settings) {
        if (!settings.findBy('name', 'show-anomalies')) return true; // default is always on
        return settings.findBy('name', 'show-anomalies').value.includes(name);
    },

    async saveSettingsAndReloadAnomalities() {
        let record = this.store
            .peekAll('widget-anomalies-setting')
            .findBy('name', 'show-anomalies');
        if (!record)
            record = this.store.createRecord('widget-anomalies-setting', {
                name: 'show-anomalies',
            });
        set(record, 'value', this.anomalitiesSettings.filterBy('checked', true).mapBy('type'));
        await record.save();

        let recordShowOk = this.store.peekAll('widget-anomalies-setting').findBy('name', 'show-ok');
        if (!recordShowOk)
            recordShowOk = this.store.createRecord('widget-anomalies-setting', { name: 'show-ok' });
        set(recordShowOk, 'value', this.showOkAlso);
        await recordShowOk.save();

        await this.loadAnomalities();
    },

    unique(array) {
        // https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
        return [...new Set(array)];
    },

    dashboardProjectChanged(params) {
        set(this, 'promise', this.loadAnomalities());
        set(this, 'projectName', params.project?.name);
    },
});
