/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/message-board-forced.coffee.old
// Generated by CoffeeScript 2.6.1

/*
 * this component gets forced and unread messages and shows them in modal
 */
var MessageBoardForcedComponent;

import Ember from 'ember';

import config from '../config/environment';

import $ from 'jquery';

MessageBoardForcedComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    forcedArr: Em.A([]),
    didInsertElement: function () {
        var self;
        self = this;
        //do not fetch forced messaged if impersonated user
        if (this.get('session.session.content.authenticated.scope')) {
            return;
        }
        return $.ajax({
            url: self.get('ttapi').host + '/messageboard/forced',
            type: 'GET',
            headers: {
                Authorization: 'Bearer ' + self.get('session.session.authenticated.access_token'),
            },
            success: function (data) {
                var error, json;
                //convert to json object and set as ember object
                if (data != null) {
                    try {
                        if ('string' === typeof data) {
                            json = $.parseJSON(data);
                        }
                    } catch (error1) {
                        error = error1;
                        throw new Ember.Error('unvalid JSON from messageboard forced: ' + data);
                    }
                    if (json) {
                        return Ember.run(function () {
                            var msg, msgs;
                            msgs = Ember.Object.create(json);
                            if (msgs.messageboards == null) {
                                return;
                            }
                            msg = msgs.messageboards;
                            return msg.forEach(function (tmp) {
                                if (tmp.forced) {
                                    return self.forcedArr.pushObject(tmp);
                                }
                            });
                        });
                    }
                }
            },
        });
    },
});

export default MessageBoardForcedComponent;
