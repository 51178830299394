/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/diary/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var WorksitediaryIndexController;

import Ember from 'ember';

import GeneralReportController from '../../../controllers/general-report';

import { set } from '@ember/object';

import pdfmixin from '../../../mixins/worksitediary_pdf';

import { fetchWeather } from 'tt4/ember-movenium/utils/weather';

import moment from 'moment';

WorksitediaryIndexController = GeneralReportController.extend(pdfmixin, {
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    cordova: Ember.inject.service('own-cordova-service'),
    store: Ember.inject.service(),
    weather: Ember.inject.service(),
    form: 'diary',
    showForm: false,
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: {
                dynamic: true,
                route: 'diary.report.date.project',
                fields: ['date', 'project'],
            },
        }),
    }),
    showColumns: ['project', 'date', 'works', 'deviations', 'weather', 'status'],
    status: 'all',
    queryParams: ['project', 'date', 'page', 'user', 'status'],
    date: moment().subtract(1, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    filters: Em.A([
        Em.Object.create({
            field: 'date',
        }),
        Em.Object.create({
            field: 'project',
        }),
        Em.Object.create({
            field: 'user',
        }),
        Em.Object.create({
            type: 'searchbox',
            bindto: 'q',
        }),
    ]),
    project: null,
    user: null,
    isWeatherLoading: false,
    weatherStringToObject: function (string) {
        var e, parsed;
        if (!string) {
            return false;
        }
        try {
            parsed = JSON.parse(string);
        } catch (error) {
            e = error;
            return false;
        }
        if (parsed.temperature) {
            return parsed;
        }
        return false;
    },
    actions: {
        fetchNewWeather: function () {
            this.set('isWeatherLoading', true);
            return this.store
                .query('project', {
                    id: this.get('showForm.project.id'),
                    sideload: true,
                })
                .then((proj) => {
                    return this.weather
                        .fetchWeatherByProject(proj.firstObject, this.get('showForm.date'))
                        .then((res) => {
                            this.set('showForm.weather', JSON.stringify(res));
                            return this.set('isWeatherLoading', false);
                        })
                        .catch((e) => {
                            if (e.message === 'projectLocation is null') {
                                this.notifications.error(
                                    this.intl.t('general.weather.no_projectLocation'),
                                    {
                                        autoClear: true,
                                    },
                                );
                                return this.set('isWeatherLoading', false);
                            }
                        });
                });
        },
        onFieldChange: function (field, value) {
            return this.set('showForm.' + field, value);
        },

        closeEditModal: function () {
            return this.set('showForm', false);
        },
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        transitionToEditRoute: function (row) {
            const date = row.date;
            const project = row.project.id;
            return this.transitionToRoute('diary.report.date.project', date, project);
        },
    },
});

export default WorksitediaryIndexController;
