/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/calendar-report-add-options/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CalendarReportAddOptionsComponent;

import Ember from 'ember';

import clickelsewhere from 'tt4/mixins/clickelsewhere';

CalendarReportAddOptionsComponent = Ember.Component.extend(clickelsewhere, {
    Collector: Ember.inject.service('collector-service'),
    analytics: Ember.inject.service(),
    showOptions: false,
    onClickElsewhere: function () {
        return this.set('showOptions', false);
    },
    addableOptions: Ember.computed('options.[]', function () {
        var c;
        c = this.get('Collector');
        return this.get('options').filter((option) => {
            if (option === 'worktime') {
                return c.canAddWorktime(this.get('date')) && !c.hasErrors(option);
            }
            return !c.hasErrors(option);
        });
    }),
    actions: {
        toggleOptions: function () {
            if (this.get('addableOptions.length') === 1) {
                return this.send('addRecord', this.get('addableOptions.firstObject'));
            } else {
                return this.toggleProperty('showOptions');
            }
        },
        addRecord: function (type) {
            if (type === 'worktime') {
                this.get('analytics').customData = { category: 'calendar-report' };
            }
            if (this.get('add')) {
                this.add(type);
            }
            return this.set('showOptions', false);
        },
    },
});

export default CalendarReportAddOptionsComponent;
