import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    intl: service(),
    store: service(),
    session: service(),
    ttapi: service(),
    gui: service(),
    evented: service(),
    analytics: service(),

    minDate: computed('center', function () {
        if (!this.Collector.testNeedsOne('worktime')) return;
        return new Date(this.center.getFullYear(), this.center.getMonth(), 1);
    }),

    maxDate: computed('center', function () {
        if (!this.Collector.testNeedsOne('worktime')) return;
        return new Date(this.center.getFullYear(), this.center.getMonth() + 1, 0);
    }),

    startOfMonth: computed('center', function () {
        return moment(this.center).startOf('month').format('YYYY-MM-DD');
    }),

    endOfMonth: computed('center', function () {
        return moment(this.center).endOf('month').format('YYYY-MM-DD');
    }),

    modalDate: computed('selected', function () {
        let date = moment(this.selected).format('YYYY-MM-DD');
        return date + '_' + date;
    }),

    navMonth: computed('center', 'intl.primaryLocale', function () {
        return moment(this.center).format('MMMM YYYY');
    }),

    modalHeader: computed('selected', 'intl.primaryLocale', function () {
        return moment(this.selected).format('dd DD[.]MM[.]YYYY');
    }),

    formattedDate: computed('selected', function () {
        return moment(this.selected).format('YYYY-MM-DD');
    }),

    init() {
        this._super();

        let date = new Date();
        this.center = new Date(date.getFullYear(), date.getMonth(), 1);
        // this.specialDays = []
        let forms = [];
        if (this.Collector.testNeedsOne('worktime')) forms.push('worktime');
        if (this.Collector.testNeedsOne('abcense')) forms.push('abcense');
        this.forms = forms;

        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
    },

    didInsertElement() {
        this.setAllData();
    },

    didDestroyElement() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
    },

    actions: {
        changeCenter(date) {
            set(this, 'center', date);
            this.setAllData();
        },
        changeMonth(amount) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Change month`,
                label: 'worktime-calendar widget',
            });
            let center = get(this, 'center');
            let newCenter = new Date(center.setMonth(center.getMonth() + amount));
            set(this, 'center', newCenter);
            this.setAllData();
        },
        selectDate(date) {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Date clicked`,
                label: 'worktime-calendar widget',
            });
            if (this.dayToBeCopied) {
                this.dayToBeCopied(date);
                return;
            }
            set(this, 'selected', date);
        },
    },

    onStoreEvent(params) {
        if (params.type === 'worktime') set(this, 'worktimes', this.getWorktimes());
        if (params.type === 'abcense') set(this, 'absences', this.getAbsences());
    },

    onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        let center = get(this, 'center');
        let date = new Date();
        // if month shown in calendar is current month, update it to reflect possible day change
        if (center.getMonth() === date.getMonth()) {
            set(this, 'center', new Date(date.getFullYear(), date.getMonth(), 1));
        }
        this.setAllData();
    },

    setAllData() {
        set(this, 'worktimes', this.getWorktimes());
        set(this, 'absences', this.getAbsences());
        set(this, 'specialDays', this.getSpecialDays());
        set(this, 'weekNumbers', this.getweekNumbersForMonth());
    },

    async getWorktimes() {
        if (!this.Collector.testNeedsOne('worktime')) return;
        const params = {
            date: this.startOfMonth + '_' + this.endOfMonth,
            user: this.session.currentUser.id,
            sideload: true,
            limit: 500,
        };
        let query = await this.store.query('worktime', params);
        let days = {};
        let sum = 0;
        query.forEach((worktime) => {
            let date = get(worktime, 'date');

            if (days[date]) days[date] += get(worktime, 'work_hours');
            else days[date] = get(worktime, 'work_hours');

            sum += get(worktime, 'work_hours');
        });
        return { days, sum };
    },

    async getAbsences() {
        if (!this.Collector.testNeedsOne('abcense')) return;
        const params = {
            startdate: this.startOfMonth,
            enddate: this.endOfMonth,
            order: 'starttime,endtime',
            user: this.session.currentUser.id,
            sideload: ['abcensetype.name'],
        };
        let absences = await this.ttapi._get('absence/split', params);

        let days = {};
        let sum = 0;

        absences.forEach((absence) => {
            let date = get(absence, 'date');
            let hours = get(absence, 'hours');

            // Handle absences with exporttype==days which have their hours always set to 1.
            // Calculate hours based on total absence hours divided by amount of days.
            if (absence.abcensetype?.exporttype === 'days') {
                hours = get(absence, 'absence_hours') / get(absence, 'dayamount');
            }

            if (days[date]) {
                days[date] += hours;
            } else {
                days[date] = hours;
            }

            sum += hours;
        });
        return { days, sum };
    },

    async getSpecialDays() {
        const worktimegroup = await this.session.currentUser.worktimegroup;
        let params = { date: this.startOfMonth + '_' + this.endOfMonth };
        if (worktimegroup) params.worktimegroup = worktimegroup.id;
        let workdays = await this.store.query('workday-day', params);
        let days = {};
        workdays.forEach((day) => {
            if (day.minutes === 0) days[get(day, 'date')] = get(day, 'date');
        });
        return days;
    },

    // https://stackoverflow.com/questions/2483719/get-weeks-in-month-through-javascript
    getweekNumbersForMonth() {
        var firstOfMonth = moment(this.center).startOf('month');
        var lastOfMonth = moment(this.center).endOf('month');
        var day = firstOfMonth.day() || 6;
        day = day === 1 ? 0 : day;
        if (day) {
            day--;
        }
        var diff = 7 - day;
        var lastDate = lastOfMonth.date();
        if (lastOfMonth.day() === 1) {
            diff--;
        }
        var weeksInMonth = Math.ceil((lastDate - diff) / 7);
        let weeks = [];
        for (var i = 0; i < weeksInMonth + 1; i++) {
            weeks.pushObject(
                moment(this.center)
                    .startOf('month')
                    .add(i * 7, 'day')
                    .isoWeek(),
            );
        }
        return weeks;
    },
});
