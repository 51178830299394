import podNames from 'ember-component-css/pod-names';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import moment from 'moment';
import { save_record } from 'tt4/ember-movenium/utils/save-the-children';

export default class CopyPreviousDayFinalComponent extends Component {
    @tracked record;
    @tracked headerTitle = "";
    @tracked editableWorktimes = [];

    @tracked validationMessageWorktimes = '';
    @tracked validationMessageAbsences = '';

    @service router;
    @service store;
    @service notifications;
    @service('collector-service') collector;
    @service intl;
    @service session;
    @service ttapi;
    @service evented;

    @tracked isLoading = false;

    constructor() {
        super(...arguments);
        let headerTitleFormatted = moment(this.args.day, 'YYYY-MM-DD').format('DD.MM.YYYY');
        const date = moment(headerTitleFormatted, 'DD.MM.YYYY').toDate();
        const options = { weekday: 'long', locale: this.intl.locale };
        const weekdayName = date.toLocaleDateString(this.intl.locale, options);
        this.headerTitle =
            weekdayName.charAt(0).toUpperCase() + weekdayName.slice(1) + ' ' + headerTitleFormatted;
        
        this.args.worktimes.forEach((worktime) => {
            const copiedWorktime = this.collector.copyRecord(worktime);
            copiedWorktime.date = this.args.day;
            this.editableWorktimes.push(copiedWorktime);
        });
    }

    get styleNamespace() {
        return podNames['workday-summary/copy-previous-day-component/copy-previous-day-final-component'];
    }

    @action 
    closeClicked() {
        this.args.close();
    }

    @action
    async fieldChanged(field, value, record) {
        set(await record, field, value);
    }

    @action async save() {
        this.isLoading = true;
        const failed = [];
        this.form = "worktime";

        for (let i = 0; i < this.editableWorktimes.length; i++) {
            await this.editableWorktimes[i].validate();
            if (!this.editableWorktimes[i].isValid) {
                this.isLoading = false;
                return;
            }
        }

        for (let i = 0; i < this.editableWorktimes.length; i++) {
            try {
                await save_record(this.editableWorktimes[i], this);
            } catch (e) {
                failed.push(this.editableWorktimes[i]);
            }
        }

        if (failed.length > 0) {
            this.notifications.error(this.intl.t('previousdaycopy.unable_to_copy_worktimes'), { autoClear: true });
            this.isLoading = false;
            this.args.close();
            return;
        }

        this.evented.storeEvent('insert', 'worktime', null);
        
        this.args.close();
        this.args.notifySaved();
        this.isLoading = false;
    }
}
