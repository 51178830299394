/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/components/date-range-picker/component.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldsDateRangePickerComponent;

import Ember from 'ember';

import { computed } from '@ember/object';

import $ from 'jquery';

import { isEmpty } from '@ember/utils';

Ember.TextField.reopen({
    attributeBindings: ['data-anchor'],
});

CollectorFieldsDateRangePickerComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    store: Ember.inject.service(),
    gui: Ember.inject.service(),
    serverFormat: 'YYYY-MM-DD',
    screenLimit: 564,
    maximumDateIntervalInYears: 3,
    showDropdown: true,
    didInsertElement: function () {
        let datesArr, end, lang, options, self, serverFormat, start;

        if (window.innerWidth <= this.get('screenLimit')) {
            this.set('readonly', true);
        }

        self = this;
        serverFormat = this.get('serverFormat');

        if (this.get('value')) {
            datesArr = this.get('value').split('_');
        } else {
            datesArr = [];
            datesArr.push(moment(this.get('start'), serverFormat));
            datesArr.push(moment(this.get('end'), serverFormat));
        }

        if (moment.locale() === 'se') {
            lang = 'sv';
        } else {
            lang = moment.locale();
        }

        options = {
            language: lang,
            autoclose: true,
            todayHighlight: true,
            calendarWeeks: true,
            orientation: 'bottom auto',
            clearBtn: !!this.get('clearBtn'),
            weekStart: 1,
        };

        //startDate: startDate.format("L")
        $('.input-daterange', this.element).datepicker(options);

        const startDateElement = $('.input-daterange .startdate', this.element);
        const endDateElement = $('.input-daterange .enddate', this.element);

        startDateElement.datepicker(
            'setDate',
            moment(datesArr[0]).toDate(),
        );

        endDateElement.datepicker(
            'setDate',
            moment(datesArr[1]).toDate(),
        );

        start = datesArr[0];
        end = moment(start).add(this.maximumDateIntervalInYears, 'years');

        endDateElement.datepicker('setEndDate', end.format('L'));

        startDateElement.on('changeDate', (ev) => {
            start = ev['date'];
            end = moment(start).add(this.maximumDateIntervalInYears, 'years');
            endDateElement.datepicker('setEndDate', end.format('L'));

            if (
                self.get('value') &&
                moment(start).diff(self.get('value').split('_')[1], 'years', true) <
                    -this.maximumDateIntervalInYears
            ) {
                endDateElement.datepicker(
                    'setDate',
                    moment(start).add(this.maximumDateIntervalInYears, 'years').toDate(),
                );
            }

            // if mobile, do not show end-calendar automatically
            if (window.innerWidth >= self.get('screenLimit')) {
                return endDateElement.datepicker('show');
                // but set value, because users wonder why nothing happens when only selecting startdate SUP-2234
            }

            if (endDateElement.datepicker('getDate')) {
                self.set(
                    'value',
                    moment(start).format(serverFormat) +
                        '_' +
                        moment(
                            endDateElement.datepicker('getDate'),
                        ).format(serverFormat),
                );
                return this.sendAction('dateChange', this.get('value'), this.get('fieldName'));
            }
        });

        endDateElement.on('hide', (ev) => {
            const changeDates = () => {
                let endDate, startDate;
                startDate = moment(start);
                endDate = moment(ev['date']);

                if (!startDate.isValid() || !endDate.isValid()) {
                    return;
                }

                this.set(
                    'value',
                    startDate.format(serverFormat) + '_' + endDate.format(serverFormat),
                );

                return this.sendAction('dateChange', this.get('value'), this.get('fieldName'));
            };

            // Ember.run.next loop was originally added in https://jira.visma.com/browse/SUP-2326
            // but it does not work when screen size is smaller than 564 and datepicker changes in to the mobile mode
            // if next loop is used with mobile mode this.set crashes because element is already destroyed
            return this.gui.isMobile(564) ? changeDates() : Ember.run.next(this, changeDates);
        });

        if (this.get('ranges')) {
            return this.get('ranges').then((ranges) => {
                return this.set('periods', ranges);
            });
        }

        const excludedField = !['employmentstarted', 'birthdate'].includes(this.get('fieldName'));

        this.set('showDropdown', excludedField)

        if (excludedField) {
            return this.setWagePeriods();
        }
    },
    setWagePeriods: function () {
        var params;
        if (this.get('Collector').formExists('wage_period')) {
            params = {
                limit: 20,
                order: 'period_end_date-,period_start_date-',
                period_start_date:
                    moment().subtract(1, 'years').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
            };
            return this.get('store')
                .query('wage-period', params)
                .then((periods) => {
                    var ranges;
                    if (periods.get('length') === 0) {
                        return;
                    }
                    ranges = {};
                    periods.forEach(function (period) {
                        var name;
                        if (period.get('period_name')) {
                            name = period.get('period_name');
                        } else {
                            name =
                                moment(period.get('period_start_date')).format('L') +
                                ' - ' +
                                moment(period.get('period_end_date')).format('L');
                        }
                        return (ranges[name] = [
                            moment(period.get('period_start_date')),
                            moment(period.get('period_end_date')),
                        ]);
                    });
                    if (!this.get('isDestroyed')) {
                        return this.set('periods', ranges);
                    }
                });
        }
    },
    formattedPeriods: computed('periods', function () {
        if (this.get('periods')) {
            return Object.entries(this.get('periods'));
        }
    }),
    currentPeriod: computed('periods', function () {
        var currentPeriods, dateToday;
        if (this.get('periods')) {
            dateToday = moment().format('YYYY-MM-DD');
            currentPeriods = Object.entries(this.get('periods')).filter((period) => {
                var periodEndDate, periodStartDate;
                periodStartDate = period[1][0].format('YYYY-MM-DD');
                periodEndDate = period[1][1].format('YYYY-MM-DD');
                return moment(dateToday).isBetween(periodStartDate, periodEndDate, null, '[]');
            });
            if (isEmpty(currentPeriods)) {
                return false;
            } else {
                return currentPeriods[0][1];
            }
        }
    }),
    lastPeriod: computed('periods', function () {
        var periodDates;
        if (this.get('periods')) {
            periodDates = Object.entries(this.get('periods')).map((period) => {
                return period[1];
            });
            if (isEmpty(periodDates)) {
                return false;
            }
            // If current period is found, return second newest
            if (periodDates.includes(this.get('currentPeriod'))) {
                return periodDates[1];
            } else {
                return periodDates[0];
            }
        }
    }),
    actions: {
        setPeriod: function (period) {
            var today = moment();
            var dayOfWeek = today.day();
            // if today is sunday, last monday is 7 days ago
            var lastMonday = today.subtract(dayOfWeek === 0 ? 7 : dayOfWeek - 1, 'days');
            var lastWeeksMonday = lastMonday.clone().subtract(1, 'weeks');

            if (period === 'currentWeek') {
                period = [lastMonday.clone(), lastMonday.clone().add(6, 'days')];
            }
            if (period === 'lastWeek') {
                period = [lastWeeksMonday.clone(), lastWeeksMonday.clone().add(6, 'days')];
            }
            $('.input-daterange .startdate', this.element).datepicker(
                'setDate',
                moment(period[0]).toDate(),
            );
            $('.input-daterange .enddate', this.element).datepicker(
                'setDate',
                moment(period[1]).toDate(),
            );
            $('.input-daterange .enddate', this.element).datepicker('show'); //for mobile to update the picker must be shown first
            return $('.input-daterange .enddate', this.element).datepicker('hide');
        },
    },
});

export default CollectorFieldsDateRangePickerComponent;
