import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    flags: service(),
    classNames: ['widget-library'],
    classNameBindings: ['nonFixedMode:non-fixed-mode'],
    // if flag 'top-header-menu' is true then we add non-fixed-mode class to the component
    nonFixedMode: computed(function () {
        return this.flags.test('top-header-menu');
    }),
});
