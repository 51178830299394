import { helper } from '@ember/component/helper';

export function isLastElement(params) {
    const items = params[0];
    const index = params[1];

    return index === items.length - 1;
}

export default helper(isLastElement);
