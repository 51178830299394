/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/components/collector-field.coffee.old
// Generated by CoffeeScript 2.6.1
var CollectorFieldComponent,
    indexOf = [].indexOf;

import Ember from 'ember';

import localization from '../classes/localization';

import collector from '../classes/collector';

import validatorClass from 'tt4/classes/validations';

import { htmlSafe } from '@ember/string';

const { getOwner, get } = Ember;

CollectorFieldComponent = Ember.Component.extend({
    intl: Ember.inject.service(),
    localStorageWrapper: Em.inject.service(),
    session: Em.inject.service(),
    gui: Em.inject.service(),
    Collector: Em.inject.service('collector-service'),
    userId: Ember.computed('session.currentUser.id', function () {
        return this.get('session.currentUser.id');
    }),
    title: true,
    external_errors: Em.A([]),
    init: function () {
        var cust_value,
            defaultValue,
            default_value,
            ext,
            feature,
            field,
            fieldExt,
            fields,
            j,
            key,
            l,
            len,
            len1,
            merged_values,
            modelName,
            name,
            options,
            ref,
            ref1,
            ref2,
            ref3,
            v,
            val,
            value;
        this._super();
        field = this.get('field');
        if (!field) {
            return;
        }
        // super HAX ...
        // database fields cannot update model if alias
        // other fields can but they need to be alias so parent controllers can change their values
        if ((ref = get(field, 'type')) === 'database' || ref === 'timerlink') {
            Ember.defineProperty(
                this,
                'value',
                Ember.computed.oneWay('model.' + this.get('field.ext')),
            );
        } else {
            Ember.defineProperty(
                this,
                'value',
                Ember.computed.alias('model.' + this.get('field.ext')),
            );
        }
        ext = this.get('field.ext');
        if (ext && ext.indexOf('_plus', ext.length - 5) !== -1) {
            name = ext.substring(0, ext.length - 5);
            Ember.defineProperty(this, 'plus', Ember.computed.alias('model.' + name + '_plus'));
            Ember.defineProperty(this, 'minus', Ember.computed.alias('model.' + name + '_minus'));
            this.set('plusminus', true);
        }
        if (ext === 'location') {
            this.set('field.type', 'gpslocation');
        }
        if (ext === 'end_location') {
            this.set('field.type', 'gpslocation');
        }
        if (ext === 'location_map') {
            this.set('field.type', 'gpslocationmap');
        }
        if (
            ext === 'weather' &&
            this.get('value') &&
            Ember.typeOf(this.get('value')) === 'string'
        ) {
            this.set('value', JSON.parse(this.get('value')));
        }
        if (this.get('field.type') === 'signature') {
            val = this.get('value');
            if (val != null && val.length > 0) {
                val = val[0];
            }
            if (val != null && val.url) {
                if (val.url.substring(0, 4) !== 'http') {
                    this.set('value.url', collector.api_url() + '/' + val.url);
                }
            }
        }
        if (this.get('field.type') === 'radio') {
            modelName = this.model.constructor.modelName;
            fieldExt = this.field.ext;
            options = this.get('field.options');
            if (typeof options[0] !== 'object') {
                options = options.map(function (val) {
                    return {
                        value: val,
                        text: modelName + '.' + fieldExt + '.' + val,
                    };
                });
                this.set('field.options', options);
            }
        }
        // merge field features with customization features
        if (this.get('field.customization.features') && field.features) {
            ref1 = field.features;
            for (feature in ref1) {
                value = ref1[feature];
                //merge if values aren't same
                if (
                    this.get('field.customization.features')[feature] !== value &&
                    this.get('field.customization.features')[feature] !== void 0
                ) {
                    merged_values = [];
                    cust_value = this.get('field.customization.features')[feature];
                    //put from customization
                    if (typeof cust_value === 'object') {
                        cust_value.forEach((val) => {
                            return merged_values.push(val);
                        });
                    } else {
                        merged_values.push(cust_value);
                    }
                    //put features from api to array
                    if (typeof value === 'object') {
                        value.forEach((val) => {
                            return merged_values.push(val);
                        });
                    } else {
                        merged_values.push(value);
                    }
                    field.features[feature] = merged_values;
                }
            }
        }
        // Set default values for the field that comes from forms settings
        if (
            field &&
            field.features.default_value &&
            this.get('model.isNew') &&
            this.get('value') == null
        ) {
            default_value = (function () {
                switch (field.features.default_value) {
                    case 'today':
                        return moment().format('YYYY-MM-DD');
                    default:
                        return field.features.default_value;
                }
            })();
            if (field.type === 'dropdown_values') {
                default_value = default_value.toString();
            }
            default_value = default_value.value != null ? default_value.value : default_value;
            Ember.run.next(this, () => {
                this.set('value', default_value);
                this.set('model.' + this.get('field.ext'), default_value);
                return this.send('validate');
            });
        }
        //default values for fields
        if (
            this.get('model.isNew') &&
            this.get('field.customization.defaultValue') &&
            this.get('value') == null
        ) {
            defaultValue = this.get('field.customization.defaultValue');
            this.set('value', defaultValue);
            this.set('model.' + this.get('field.ext'), defaultValue);
        }
        if (field && typeof field.features.mandatory === 'object') {
            ref2 = field.features.mandatory;
            for (j = 0, len = ref2.length; j < len; j++) {
                fields = ref2[j];
                for (key in fields) {
                    v = fields[key];
                    Ember.defineProperty(this, 'conditional', Ember.computed.alias('model.' + key));
                }
            }
        }
        if (field && typeof field.features.hide === 'object') {
            ref3 = field.features.hide;
            for (l = 0, len1 = ref3.length; l < len1; l++) {
                fields = ref3[l];
                for (key in fields) {
                    v = fields[key];
                    Ember.defineProperty(
                        this,
                        'hide_conditional',
                        Ember.computed.alias('model.' + key),
                    );
                }
            }
        }
        if (
            this.get('field.features.mandatory') &&
            typeof this.get('field.features.mandatory') !== 'object'
        ) {
            this.set('_mandatory', true);
            if (
                !(
                    this.get('field.type') === 'dropdown_country' ||
                    (this.get('field.options') &&
                        this.get('field.options.firstObject') !== '' &&
                        this.get('field.type') !== 'database' &&
                        this.get('field.type') !== 'dropdown_values')
                )
            ) {
                if (
                    (this.get('value') instanceof Ember.ObjectProxy ||
                        this.get('value') instanceof Ember.ArrayProxy) &&
                    Ember.isEmpty(this.get('value.content')) &&
                    !this.get('hide')
                ) {
                    if (this.get('errors')) {
                        this.errors.push(this.field.ext);
                    } else {
                        this.errors = [this.field.ext];
                    }
                } else if (!Ember.isPresent(this.get('value')) && !this.get('hide')) {
                    if (this.get('errors')) {
                        this.errors.push(this.field.ext);
                    } else {
                        this.errors = [this.field.ext];
                    }
                }
            }
        }
        if (this.get('field.type') === 'timeclean_imei') {
            this.set('buttonText', 'project.add.imei');
        }
        if (this.get('field.customization.hide_title' || !this.get('settings.title'))) {
            this.set('title', false);
        }
        if (this.get('field.features.stashed') && !this.get('show_stashed')) {
            this.set('hide', true);
        }
        if (this.get('field.validateOnInit') && this.get('value')) {
            this.send('validate');
        }
        if (typeof this.get === 'function' ? this.get('external_errors') : void 0) {
            return this.get('external_errors').clear();
        }
    },
    setDefaultValue: Em.on(
        'init',
        Em.observer('session.currentUser.id', function () {
            var defValue, self;
            if (
                localStorage &&
                !this.get('value') &&
                !this.get('settings.edit') &&
                !this.get('settings.report')
            ) {
                self = this;
                switch (this.get('field.type')) {
                    case 'database':
                        defValue = this.get('localStorageWrapper').getUserSetting(
                            this.get('field.options.form'),
                        );
                        defValue = parseInt(defValue, 10);
                        if (
                            defValue &&
                            this.get('model.isNew') &&
                            !this.get('hide') &&
                            !this.get('field.features.do_not_preselect')
                        ) {
                            //@set 'value', defValue
                            return this.Collector.get('store')
                                .findRecord(this.get('field.options.form'), defValue, {
                                    reload: true,
                                })
                                .then(
                                    (row) => {
                                        return Ember.run.next(this, () => {
                                            if (row.get('row_info.status') !== 'removed') {
                                                this.set('value', row);
                                                return this.send('validate');
                                            }
                                        });
                                    },
                                    (reason) => {
                                        return this.get('localStorageWrapper').setUserSetting(
                                            this.get('field.options.form'),
                                            null,
                                        );
                                    },
                                );
                        }
                }
            }
        }),
    ),
    storeDefaultValue: function () {
        var ref, tmp_value, value;
        if (localStorage && this.get('value')) {
            switch (this.get('field.type')) {
                case 'database':
                    value = this.get('value');
                    if (!((ref = Ember.typeOf(value)) === 'number' || ref === 'string')) {
                        tmp_value = value.get('id');
                    } else {
                        tmp_value = value;
                    }
                    return this.get('localStorageWrapper').setUserSetting(
                        this.get('field.options.form'),
                        parseInt(tmp_value),
                    );
            }
        }
    },
    name: Ember.computed('intl.locale', function () {
        var name;
        if (this.get('field.type') === 'checkboxgroup') {
            name = this.get('field.name')
                ? this.get('field.name')
                : this.get('intl').t(
                      this.get('model.constructor.modelName') + 's.' + this.get('field.ext'),
                  );
            return name;
        }
        if (this.get('model.constructor.modelName').includes('measurement-custom')) {
            name = this.get('field.name')
                ? this.get('field.name')
                : this.get('intl').t('tr' + '.' + this.get('field.ext'));
            return name;
        }
        if (this.get('field.customization.translation')) {
            //translation from customization
            return htmlSafe(this.get('intl').t(this.get('field.customization.translation')));
        }
        if (this.get('field.translation')) {
            return htmlSafe(this.get('intl').t(this.get('field.translation')));
        }
        return htmlSafe(
            this.get('field.name') ||
                this.get('intl').t(
                    this.get('model.constructor.modelName') + '.' + this.get('field.ext'),
                ),
        );
    }),
    unit: Ember.computed(function () {
        switch (this.get('field.type')) {
            case 'minutes':
                return 'min';
            case 'price':
                if (this.get('session.currentUser.company_info.country') === 'se') {
                    return 'SEK';
                } else {
                    return '€';
                }
                break;
            case 'big_price':
                return '€';
            default:
                return this.get('field.options');
        }
    }),
    valuePreview: Ember.computed('value', 'intl.locale', function () {
        var dropdowns, ref, value;
        value = this.Collector.format(
            this.get('value'),
            this.get('field'),
            null,
            this.get('model'),
        );
        if (this.get('formatter')) {
            dropdowns = ['dropdown_values'];
            if (
                value === this.get('value') &&
                this.get('field.type') !== 'embedded' &&
                this.get('field.type') !== 'object'
            ) {
                value = this.get('formatter')(this.get('field.ext'), this.get('value'), this);
            } else if (((ref = this.get('field.type')), indexOf.call(dropdowns, ref) >= 0)) {
                value = this.get('formatter')(
                    this.get('field.ext'),
                    this.get('value'),
                    this,
                    value,
                );
            }
        }
        return value;
    }),
    popoverContents: '...',
    prompt: Ember.computed('intl.locale', function () {
        return this.get('intl').t('general.select_choose');
    }),
    update: Ember.observer('value', function () {
        if (this.get('field.type') === 'databasearray') {
            return;
        }
        return Ember.run.next(() => {
            var str;
            // if record is already deleted do not try to set anything to it
            if (this.get('model.currentState.stateName') === 'root.deleted.saved') {
                return;
            }
            if (this.isDestroyed || this.isDestroying) {
                return;
            }
            str = 'model.' + this.get('field.ext');
            return this.set(str, this.get('value'));
        });
    }),
    static_content: Ember.computed('intl.locale', function () {
        var j, l, len, len1, month_count, new_option, obj, option, options, real_options;
        real_options = [];
        if (this.get('field.type') === 'dropdown_country') {
            real_options = this.get('countries');
        } else if (this.get('field.type') === 'dropdown_userlevel') {
            options = this.get('field.options');
            if (Ember.typeOf(options) === 'string' && options.indexOf(',') !== -1) {
                options = options.split(',');
            }
            for (j = 0, len = options.length; j < len; j++) {
                option = options[j];
                if (option) {
                    if (typeof option !== 'object') {
                        new_option = {};
                        new_option.id = option;
                        option = new_option;
                    }
                    //hide admin from own roles
                    if (
                        parseInt(option.id) === 5 &&
                        parseInt(this.get('session.currentUser.userlevel')) > 9
                    ) {
                        continue;
                    }
                    if (parseInt(option.id) > 9) {
                        option.value = option.id.substring(2);
                        option.id = parseInt(option.id).toString(); // to remove all but numbers (in case of own userroles)
                    } else {
                        option.value = this.get('intl').t(
                            this.get('model.constructor.modelName') +
                                '.' +
                                this.get('field.ext') +
                                '.' +
                                option.id,
                        );
                    }
                    real_options.push(option);
                }
            }
        } else if (this.get('field.type') === 'month_dropdown') {
            month_count = 0;
            while (month_count < 36) {
                obj = {
                    id: moment().subtract(month_count, 'months').format('YYYY-MM'),
                    value: moment().subtract(month_count, 'months').format('MMMM YYYY'),
                };
                real_options.push(obj);
                month_count++;
            }
        } else {
            options = this.get('field.options');
            if (options) {
                for (l = 0, len1 = options.length; l < len1; l++) {
                    option = options[l];
                    if (option) {
                        if (typeof option !== 'object') {
                            new_option = {};
                            new_option.id = option;
                            option = new_option;
                        }
                        if (!('name' in this.get('field')) || option.value == null) {
                            if (
                                this.get('intl').exists(
                                    this.get('model.constructor.modelName') +
                                        '.' +
                                        this.get('field.ext') +
                                        '.' +
                                        option.id,
                                )
                            ) {
                                option.value = this.get('intl').t(
                                    this.get('model.constructor.modelName') +
                                        '.' +
                                        this.get('field.ext') +
                                        '.' +
                                        option.id,
                                );
                            }
                        }
                        real_options.push(option);
                    }
                }
            }
        }
        return real_options;
    }),
    useAjaxDropdown: Ember.computed.gte('db_options.length', 30),
    layoutName: 'fields/field',
    mainLayoutName: Ember.computed('content.name', 'model', 'edit', function () {
        if (this.get('settings.preview')) {
            return 'fields/preview';
        } else if (this.get('settings.report') && !this.get('edit')) {
            return 'fields/report';
        }
        return 'fields/fields-main';
    }),
    innerTemplateName: Ember.computed('content.name', 'edit', function () {
        if (this.get('settings.preview') && !this.get('edit')) {
            return 'fields/preview';
        }
        if (this.get('field.customization.template')) {
            return this.get('field.customization.template');
        }
        switch (this.get('field.type')) {
            case 'dropdown_values':
            case 'dropdown_options':
            case 'dropdown_userlevel':
            case 'dropdown_country':
            case 'dropdown_language':
            case 'month_dropdown':
                return 'fields/dropdown';
            case 'database':
                return 'fields/database';
            case 'textbox':
            case 'embedded':
            case 'object':
                return 'fields/textbox';
            case 'number':
                return 'fields/number';
            case 'minutes':
                return 'fields/number';
            case 'hours':
                return 'fields/hours';
            case 'time_new':
                return 'fields/time';
            case 'textarea_varchar':
                return 'fields/textarea';
            case 'textarea':
                return 'fields/textarea';
            case 'price':
                return 'fields/number';
            case 'radio':
                return 'fields/radio';
            case 'big_price':
                return 'fields/number';
            case 'decimal':
                return 'fields/number';
            case 'checkbox':
                return 'fields/checkbox';
            case 'date':
                return 'fields/date';
            case 'checkboxgroup':
                return 'fields/checkboxgroup';
            case 'hdfile':
                return 'fields/file';
            case 'password':
                return 'fields/password';
            case 'username':
                return 'fields/username';
            case 'timeclean_imei':
                return 'fields/array';
            case 'gpslocation':
                return 'fields/gpslocation';
            case 'gpslocationmap':
                return 'fields/gpslocationmap';
            case 'multiselect':
                return 'fields/multiselect';
            case 'signature':
                return 'fields/signature';
            case 'label':
                return 'fields/label';
            case 'weather':
                return 'fields/weather';
            case 'fileupload':
            case 'row_linker':
            case 'rowlink':
            case 'status':
                return 'fields/notsupported';
            case 'databasearray':
                return 'fields/databasearray';
            case 'timerlink':
                return 'fields/timerlink';
        }
        return 'fields/notfound';
    }),
    img_src: Ember.computed(function () {
        if (this.get('value.url')) {
            return collector.api_url() + '/' + this.get('value.url');
        }
    }),
    userlevelInfo: Ember.computed(function () {
        var linkToCommunity, linkToCommunityMovenium, linkToCommunityOtta;
        if (this.intl.primaryLocale !== 'fi') {
            return null;
        }
        linkToCommunityMovenium =
            'https://ohjeet.visma.fi/articles/#!visma-movenium/k-ytt-j-roolit';
        linkToCommunityOtta = this.get('intl').t('click.help_link.to.community_otta');
        const brand = get(this, 'gui').getBrand();

        // eslint-disable-next-line no-irregular-whitespace
        if (brand === 'otta') {
            linkToCommunity = linkToCommunityOtta;
        } else {
            linkToCommunity = linkToCommunityMovenium;
        }
        return htmlSafe(
            this.intl.t('user.userlevel.info', {
                link:
                    "<a href='" +
                    linkToCommunity +
                    "'>" +
                    this.intl.t('user.userlevel.info.link') +
                    '<span class="move move-external-link"></span></a>',
            }),
        );
    }),
    mandatory: Ember.computed('_mandatory', 'conditional', function () {
        var errors, fields, index, mandatory, ref, ref1;
        if (this.get('_mandatory')) {
            return this.get('_mandatory');
        }
        if (!this.get('model.isDeleted')) {
            fields = this.get('field.features.mandatory');
            if (!fields) {
                return;
            }
            if (typeof fields === 'boolean') {
                return fields;
            }
            mandatory = validatorClass._is_visible(
                this.get('model'),
                this.get('field.features.mandatory'),
            );
            errors = this.errors;
            if (mandatory && (!this.get('value') || this.get('value') === null)) {
                if (errors) {
                    if (((ref = this.get('field.ext')), indexOf.call(errors, ref) < 0)) {
                        this.errors.push(this.field.ext);
                    }
                } else {
                    this.errors = [this.field.ext];
                }
            } else {
                Ember.run.once(this, function () {
                    return this.set('error', null);
                });
                if (errors) {
                    if (((ref1 = this.get('field.ext')), indexOf.call(errors, ref1) >= 0)) {
                        index = errors.indexOf(this.field.ext);
                        if (index > -1) {
                            errors.splice(index, 1);
                        }
                        this.set('errors', errors);
                    }
                }
            }
            return mandatory;
        }
    }),
    hide: Ember.computed('hide_conditional', 'innerTemplateName', function () {
        var field, mandatory, setting;
        if (this.get('innerTemplateName') === 'fields/notsupported') {
            return true;
        }
        if (this.get('innerTemplateName') === 'fields/notfound') {
            return true;
        }
        if (!this.get('model.isDeleted')) {
            field = this.get('field');
            if (this.get('field.customization.hide')) {
                return true;
            } else if (this.get('field.customization.hide') === false) {
                return false;
            }
            if (this.get('field.features.hidden') && typeof field.features.hidden !== 'object') {
                return true;
            }
            if (this.get('field.features.hide_tt4') && typeof setting !== 'object') {
                return true;
            }
            setting = get(field, 'features.hide');
            mandatory = this.get('mandatory'); // used only to trigger mandatory-computed (doesn't trigger automatically if field is hidden)
            if (!setting) {
                return;
            }
            if (typeof setting === 'boolean') {
                return setting;
            }
            if (setting && typeof setting === 'object' && get(setting, 'condition')) {
                if (get(setting, 'condition') === '$editing' && !this.get('model.isNew')) {
                    return true;
                }
                if (get(setting, 'condition') === "'!$editing'" && this.get('model.isNew')) {
                    return true;
                }
            }
            return !validatorClass._is_visible(this.get('model'), setting);
        }
    }),
    _templateChanged: Ember.observer('layoutName', function () {
        return this.rerender();
    }),
    focusOut: function (event) {
        this.send('validate');
        if (this.get('edit')) {
            return this.send('save');
        }
    },
    focusIn: function (event) {
        return this.set('error', false);
    },
    show: Ember.computed(function () {
        return this.get('settings.show');
    }),
    observeError: Em.observer('error', function () {
        var errors, field, ref, ref1;
        errors = this.get('errors');
        if (this.get('error')) {
            this.set('ok', false);
            this.set('wait', false);
            if (this.get('errors')) {
                if (((ref = this.get('field.ext')), indexOf.call(this.get('errors'), ref) < 0)) {
                    return this.errors.push(this.field.ext);
                }
            } else {
                return Ember.set(this, 'errors', [this.get('field.ext')]);
            }
        } else {
            if (this.get('settings.setOKtoAllFields')) {
                this.set('ok', true);
            }
            if (errors) {
                if (((ref1 = this.get('field.ext')), indexOf.call(errors, ref1) >= 0)) {
                    field = this.get('field.ext');
                    errors.removeObject(field);
                    return Ember.set(this, 'errors', errors);
                }
            }
        }
    }),
    observeErrors: Ember.observer('errors', 'save', function () {
        var ref;
        if (this.get('errors')) {
            if (
                ((ref = this.get('field.ext')), indexOf.call(this.get('errors'), ref) >= 0) &&
                this.get('save')
            ) {
                return this.send('validate');
            }
        }
    }),
    fieldRoute: Em.computed('field', 'model', function () {
        var ref, tmp_value, value;
        value = this.get('value');
        if (!value) {
            tmp_value = null;
        } else if (
            !(
                (ref = Ember.typeOf(value)) === 'number' ||
                ref === 'string' ||
                ref === 'object' ||
                ref === 'array'
            )
        ) {
            tmp_value = value.get('id');
        } else {
            tmp_value = value;
        }
        return {
            form: this.get('model.constructor.modelName'),
            type: this.get('field.type'),
            value: tmp_value,
            features: this.get('field.features'),
            name: this.get('field.ext'),
            options: this.get('field.options'),
            filterOptions: this.get('field.filterOptions'),
        };
    }),
    map_visible: false,
    check_username: function () {
        var data, promise;
        data = {
            validate: true,
        };
        data['email'] = this.get('value');
        promise = this.Collector.ajax({
            url: 'createService',
            method: 'post',
            data: data,
        });
        promise.then((back) => {
            this.set('ok', true);
            return this.set('wait', false);
        });
        return promise.catch((back) => {
            this.set('wait', false);

            // The back variable only contains a JS error object, so we must determine
            // the error message displayed based on the message. These messages are returned
            // from a vendor script and as such are subject to change, so this isn't optimal.
            // The identified fail case messages here are:
            // 'Request was formatted incorrectly.' - Invalid characters in username
            // 'The ajax operation failed due to a conflict' - Username already exists

            // Use this as the default so a value always exists, then override it if necessary.
            var error_message = 'field.error.username_in_use';

            if ((back?.message ?? '').includes('Request was formatted incorrectly')) {
                error_message = 'field.error.username_characters';
            }

            return this.set('error', this.get('intl').t(error_message));
        });
    },
    actions: {
        dateInputClicked: function (value) {
            if (this.get('field.features.onclick_default_value') && (!value || value === void 0)) {
                return this.set('value', this.get('field.features.onclick_default_value'));
            }
        },
        show_map: function () {
            var map_visible;
            map_visible = this.get('map_visible');
            if (map_visible === true) {
                return this.set('map_visible', false);
            } else {
                return this.set('map_visible', true);
            }
        },
        updatePopoverContents: function () {
            var c, form, ids, promise;
            // this is now called only by multiselect field
            c = this.get('Collector');
            form = this.get('field.options.form');
            ids = this.get('value');
            promise = c.getRecordArray(form, ids, true);
            return promise.then((arr) => {
                var names;
                names = Em.A(arr).mapBy('name');
                return this.set('popoverContents', names.join('<br>'));
            });
        },
        removeFile: function () {
            this.set('img_src', null);
            return this.set('value', 'remove');
        },
        validate: function () {
            var arr,
                check_id,
                e,
                isJson,
                item,
                item_arr,
                j,
                l,
                len,
                len1,
                len2,
                m,
                minutes,
                nationality,
                ref,
                ref1,
                ref2,
                ref3,
                self,
                split,
                validator,
                validator_key_arr,
                value;
            if (this.get('errors')) {
                if (((ref = this.get('field.ext')), indexOf.call(this.get('errors'), ref) >= 0)) {
                    this.set('error', true);
                }
            }
            //@set('errors', Em.A [] )
            if (this.get('field.features.validate_check')) {
                validator = {};
                if (Array.isArray(this.get('field.features.validate_check'))) {
                    ref1 = this.get('field.features.validate_check');
                    for (j = 0, len = ref1.length; j < len; j++) {
                        item = ref1[j];
                        if (item.substring(0, 9) === 'exact_len') {
                            item_arr = item.split(':');
                            validator.length = parseInt(item_arr[1], 10);
                        }
                        if (item.substring(0, 9) === 'minLength') {
                            item_arr = item.split(':');
                            validator.minLength = parseInt(item_arr[1], 10);
                        }
                        if (item.substring(0, 9) === 'maxLength') {
                            item_arr = item.split(':');
                            validator.maxLength = parseInt(item_arr[1], 10);
                        }
                        if (item === 'number') {
                            validator.number = true;
                        }
                        if (item === 'email') {
                            validator.email = true;
                        }
                        if (item === 'birthdate') {
                            validator.birthdate = true;
                        }
                        if (item.substring(0, 6) === 'remote') {
                            item_arr = item.split(':');
                            //SUP-1389 if TT3 has remote:check_rfid_tag-setting, set field as unique
                            if (item_arr[1] === 'check_rfid_tag') {
                                this.set('field.features.unique', true);
                            }
                            validator.remote = item_arr[1];
                        }
                        if (item.substring(0, 5) === 'match') {
                            item_arr = item.split(':');
                            validator.match = item_arr[1];
                        }
                        if (item.substring(0, 10) === 'date_after') {
                            item_arr = item.split(':');
                            validator.date_after = item_arr[1];
                        }
                        if (item === 'digits') {
                            validator.digits = true;
                        }
                        if (item === 'phoneNumber') {
                            validator.phoneNumber = true;
                        }
                        if (item === 'positiveNumber') {
                            validator.positive = true;
                        }
                        if (item === 'date_not_past') {
                            validator.date_not_past = true;
                        }
                        if (item === 'password') {
                            validator.password = true;
                        }
                    }
                } else {
                    validator[this.get('field.features.validate_check')] = true;
                }
            }
            this.set('error', null);
            if (this.get('mandatory')) {
                if (!Ember.isPresent(this.get('value'))) {
                    this.set('error', this.get('intl').t('field.error.mandatory'));
                } else if (
                    (this.get('value') instanceof Ember.ObjectProxy ||
                        this.get('value') instanceof Ember.ArrayProxy) &&
                    Ember.isEmpty(this.get('value.content'))
                ) {
                    this.set('error', this.get('intl').t('field.error.mandatory'));
                }
            }
            if (
                this.get('field.minValue') !== void 0 &&
                this.get('value') < this.get('field.minValue')
            ) {
                this.set('error', this.get('intl').t('field.error.number_out_of_bounds'));
            }
            if (validator && validator.length) {
                isJson = false;
                value = this.get('value');
                try {
                    JSON.parse(value);
                    isJson = true;
                } catch (error) {
                    e = error;
                    isJson = false;
                }
                if (isJson && Array.isArray(JSON.parse(value))) {
                    ref2 = JSON.parse(value);
                    for (l = 0, len1 = ref2.length; l < len1; l++) {
                        item = ref2[l];
                        if (item.toString().trim().length !== validator.length) {
                            this.set(
                                'error',
                                this.get('intl').t('field.error.length', {
                                    length: validator.length,
                                }),
                            );
                        }
                    }
                } else if (value && value.trim().length !== validator.length) {
                    this.set(
                        'error',
                        this.get('intl').t('field.error.length', {
                            length: validator.length,
                        }),
                    );
                }
            } else if (validator && Object.keys(validator)[0].indexOf(':') !== -1) {
                validator_key_arr = Object.keys(validator)[0].split(':');
                validator = {};
                validator[validator_key_arr[0]] = parseInt(validator_key_arr[1]);
            }
            if (
                validator &&
                validator.maxLength &&
                this.get('value.length') > validator.maxLength
            ) {
                this.set(
                    'error',
                    this.get('intl').t('field.error.maxLength', {
                        length: validator.maxLength,
                    }),
                );
            }
            if (
                validator &&
                validator.minLength &&
                this.get('value.length') < validator.minLength
            ) {
                this.set(
                    'error',
                    this.get('intl').t('field.error.minLength', {
                        length: validator.minLength,
                    }),
                );
            }
            if (validator && validator.number && isNaN(this.get('value'))) {
                this.set('error', this.get('intl').t('field.error.number'));
            }
            if (
                validator &&
                validator.email &&
                this.get('value') &&
                !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(\s){0,1}(?:\.[a-z]{2})?)$/i.test(
                    this.get('value'),
                )
            ) {
                this.set('error', this.get('intl').t('field.error.email'));
            }
            if (
                validator &&
                validator.birthdate &&
                moment(this.get('value')).isAfter(moment().subtract(10, 'years'))
            ) {
                this.set('error', this.get('intl').t('field.error.birthdate'));
            }
            if (validator && validator.match) {
                if (this.get('model.' + validator.match) !== this.get('value')) {
                    this.set('error', this.get('intl').t('field.error.no_match'));
                }
            }
            if (validator && validator.digits) {
                if (Array.isArray(JSON.parse(this.get('value')))) {
                    ref3 = JSON.parse(this.get('value'));
                    for (m = 0, len2 = ref3.length; m < len2; m++) {
                        item = ref3[m];
                        if (Number(item) !== parseInt(item, 10)) {
                            this.set('error', this.get('intl').t('field.error.digits'));
                        }
                    }
                } else if (Number(this.get('value')) !== parseInt(this.get('value'), 10)) {
                    this.set('error', this.get('intl').t('field.error.digits'));
                }
            }
            if (validator && validator.positiveNumber && this.get('value') < 0) {
                this.set('error', this.get('intl').t('field.error.negative'));
            }
            if (
                validator &&
                validator.date_not_past &&
                moment().isAfter(this.get('value'), 'day')
            ) {
                this.set('error', this.get('intl').t('field.error.date_not_past'));
            }
            if (
                validator &&
                validator.password &&
                this.get('value.length') < 8 &&
                this.get('value.length') !== 6 &&
                this.get('value.length') !== 0
            ) {
                this.set('error', this.get('intl').t('field.error.password'));
            }
            if (validator && validator.date_after) {
                if (this.get('model.' + validator.date_after) > this.get('value')) {
                    this.set('error', this.get('intl').t('field.error.date_after'));
                }
            }
            if (
                this.get('field.type') === 'username' &&
                this.get('value') &&
                !/^[a-z\d_.@-]+$/i.test(this.get('value'))
            ) {
                this.set('error', this.get('intl').t('field.error.username_characters'));
            }
            // SKV number check..
            if (this.get('value') && validator && 'skv_number' in validator) {
                if (!/^([a-z\d]{13})$/i.test(this.get('value'))) {
                    this.set('error', this.get('intl').t('field.error.skv'));
                }
                this.set('value', this.get('value').toUpperCase());
            }
            // Personal identity number check..
            if (
                this.get('value') &&
                this.get('field.features.validate_check') &&
                this.get('model.personid')
            ) {
                check_id = this.get('field.features.validate_check');
                if (
                    typeof check_id === 'string' &&
                    check_id.substring(0, 4) === 'PIN:' &&
                    this.get('model.homecountry')
                ) {
                    nationality = this.get('model.homecountry').toUpperCase();
                    if (nationality === 'SE') {
                        if (this.get('value').length !== 13 && this.get('value').length !== 11) {
                            this.set(
                                'error',
                                this.get('intl').t('field.error.personal_identity_number'),
                            );
                        }
                        if (
                            !/^(([\d]{2})|([1-2][0|9][\d]{2}))(0[\d]|1[012])(0[\d]|[1-9]\d)-[\d]{3}[A-Z\d]$/i.test(
                                this.get('value'),
                            )
                        ) {
                            this.set(
                                'error',
                                this.get('intl').t('field.error.personal_identity_number'),
                            );
                        }
                    } else if (nationality === 'FI') {
                        if (
                            !/^((0[\d]|[12]\d|3[01])(0?[\d]|1[012])[\d]{2}-[\d]{3}[a-z\d])$/i.test(
                                this.get('value'),
                            )
                        ) {
                            this.set(
                                'error',
                                this.get('intl').t('field.error.personal_identity_number'),
                            );
                        }
                    }
                    this.set('value', this.get('value').toUpperCase());
                }
            }
            // ID06 number check..
            if (this.get('value') && validator && 'id06_number' in validator) {
                if (!/^[a-z]{2}-[\d]{2}(0?[\d]|1[012])-[\d]{6}$/i.test(this.get('value'))) {
                    this.set('error', this.get('intl').t('field.error.id06_number'));
                }
                this.set('value', this.get('value').toUpperCase());
            }
            // Project number check..
            if (this.get('value') && validator && 'project_number' in validator) {
                if (isFinite(this.get('value'))) {
                    if (parseInt(this.get('value'), 10) === 0) {
                        this.set('error', this.get('intl').t('field.error.project_number'));
                    }
                }
            }
            //phone number checking
            if (this.get('value') && this.get('field.ext') === 'phone') {
                if (
                    !/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)[^0]\d{1,14}$/i.test(
                        this.get('value'),
                    )
                ) {
                    this.set('error', this.get('intl').t('field.error.phone_number'));
                }
            }
            if (validator && validator.phoneNumber && this.get('value')) {
                if (
                    !/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)[^0]\d{1,14}$/i.test(
                        this.get('value'),
                    )
                ) {
                    this.set('error', this.get('intl').t('field.error.phone_number'));
                }
            }
            if (!this.get('error') && this.get('field.features.unique') && this.get('value')) {
                this.set('wait', true);
                this.set('ok', false);
                self = this;
                arr = {};
                arr[this.get('field.ext')] = this.get('value');
                this.Collector.get('store')
                    .query(this.get('model.constructor.modelName'), arr)
                    .then(function (rows) {
                        var fieldname;
                        fieldname = Ember.String.isHTMLSafe(self.get('name'))
                            ? self.get('name').string
                            : self.get('name');
                        if (
                            rows.get('length') > 0 &&
                            rows.get('firstObject.id') !== self.get('model.id')
                        ) {
                            self.set(
                                'error',
                                self.get('intl').t('field.error.unique', {
                                    field: fieldname.toLowerCase(),
                                    value: self.get('value'),
                                }),
                            );
                        } else {
                            self.set('ok', true);
                        }
                        return self.set('wait', false);
                    });
            }
            // this is a special validation
            if (!this.get('error') && validator && validator.remote === 'username') {
                this.set('value', this.get('model.username'));
                if (!this.get('value')) {
                    this.set('error', this.get('intl').t('field.error.mandatory'));
                } else {
                    this.set('ok', false);
                    Em.run.debounce(this, this.check_username, 700);
                }
            }
            if (validator && validator.forced_pause) {
                if (this.get('model.pause')) {
                    // check for start-end or work_hours
                    if (this.get('model.endtime')) {
                        minutes = moment(
                            this.get('model.date') + ' ' + this.get('model.endtime'),
                        ).diff(
                            moment(this.get('model.date') + ' ' + this.get('model.starttime')),
                            'minutes',
                        );
                        if (minutes < 0) {
                            minutes += 1440;
                        }
                    } else {
                        split = this.get('model.work_hours').split(':');
                        minutes = parseInt(split[0], 10) * 60 + parseInt(split[1], 10);
                    }
                    if (minutes > 360 && this.get('model.pause') < 30) {
                        this.set('error', this.get('intl').t('validation.error.forced_pause'));
                    }
                }
            }
            //return resolve()
            return this.storeDefaultValue();
        },
        check: function (key, way) {
            if (!way) {
                if (
                    this.get('model.' + this.get('field.ext')) === null ||
                    this.get('model.' + this.get('field.ext')) == null
                ) {
                    this.set('model.' + this.get('field.ext'), Em.A([key]));
                } else {
                    this.get('model.' + this.get('field.ext')).pushObject(key);
                }
            } else {
                this.get('model.' + this.get('field.ext')).removeObject(key);
            }
            return this.set('checkboxes', this.getCheckboxes());
        },
        edit: function () {
            this.set('saving', false);
            if (this.get('field.features.hide')) {
                return;
            }
            switch (this.get('field.type')) {
                case 'fileupload':
                case 'username':
                case 'row_linker':
                case 'rowlink':
                case 'status':
                case 'gpslocation':
                case 'signature':
                    break;
                default:
                    return this.toggleProperty('edit');
            }
        },
        cancel: function () {
            this.get('model').rollbackAttributes();
            return this.set('edit', false);
        },
        save: function () {
            var model, promise, self;
            self = this;
            this.send('validate');
            if (!(this.get('errors.length') > 0)) {
                model = this.get('model');
                if (model.get('hasDirtyAttributes')) {
                    promise = model.save();
                    return promise.then(function () {
                        return self.set('edit', false);
                    });
                } else {
                    return self.set('edit', false);
                }
            }
        },
        send: function (type, param) {
            return this.sendAction('generalAddAction', type, param);
        },
        bubbleChange: function (value, fieldName) {
            return this.set('model.' + fieldName, value);
        },
        changeValue: function (value, fieldName) {
            this.set('value', value);
            if (!value) {
                this.set('model.' + this.get('field.ext'), null);
                return this.send('validate');
            } else {
                return this.Collector.get('store')
                    .findRecord(this.get('field.options.form'), value)
                    .then((row) => {
                        this.set('model.' + this.get('field.ext'), row);
                        this.set('value', row);
                        return this.send('validate');
                    });
            }
        },
        customChangeValue: function (value, fieldName) {
            if (this.get('model.isDeleted')) {
                return;
            }
            this.set('model.' + this.get('field.ext'), value);
            this.set('value', value);
            Ember.run.next(this, function () {
                return this.send('validate');
            });
            if (this.get('field.type') === 'database' && !Ember.isPresent(value)) {
                this.get('localStorageWrapper').removeUserSetting(this.get('field.options.form'));
            }
            return this.sendAction('generalAddAction', value, fieldName);
        },
        changeValueByDOMEvent: function (event) {
            var value;
            value = event.target.value;
            this.set('model.' + this.get('field.ext'), value);
            this.set('value', value);
            this.send('validate');
            return this.sendAction('generalAddAction', value, this.get('field.ext'));
        },
        //pass from component to applicationcontroller
        showSignModal: function (name, model, variable) {
            return this.sendAction('action', name, model, variable);
        },
    },
    becomeFocused: Ember.on('didRender', function () {
        if (!this.get('edit')) {
            return;
        }
        return $('select,input', this.element).focus();
    }),
    observeUsername: Em.on(
        'init',
        Em.observer('model.username', function () {
            if (this.get('field.type') === 'username' && this.get('model.username')) {
                return this.send('validate');
            }
        }),
    ),
    observeHomecountry: Ember.observer('model.homecountry', function () {
        var check_id;
        if (this.get('field.features.validate_check') && this.get('model.personid')) {
            check_id = this.get('field.features.validate_check');
            if (typeof check_id === 'string' && check_id.substring(0, 4) === 'PIN:') {
                return this.send('validate');
            }
        }
    }),
    show_generated_password: true,
    password_observer: Em.observer('model.password', function () {
        if (this.get('model.password')) {
            return this.set('show_generated_password', false);
        }
    }),
    observeValue: Ember.observer('value', function () {
        var model;
        model = this.get('model');
        if (model.get('isDeleted') || model.send == null) {
            return;
        }
        return model.send('becomeDirty');
    }),
    saving: false,
    checkboxes: Ember.computed('field.type', function () {
        var checkboxes;
        checkboxes = Em.A([]);
        if (this.get('field.type') === 'checkboxgroup') {
            checkboxes = this.getCheckboxes();
        }
        return checkboxes;
    }),
    getCheckboxes: function () {
        var checked, key, options_arr, ref, text, value;
        options_arr = Ember.A([]);
        ref = this.get('field.options');
        for (key in ref) {
            value = ref[key];
            checked = false;
            if (Array.isArray(this.get('model.' + this.get('field.ext')))) {
                if (indexOf.call(this.get('model.' + this.get('field.ext')), key) >= 0) {
                    checked = true;
                }
            }
            text =
                value.substring(0, 1) === '$'
                    ? this.get('intl').t(
                          this.get('model.constructor.modelName') +
                              's.' +
                              this.get('field.ext') +
                              '.' +
                              key,
                      )
                    : value;
            if (text === value && this.get('intl').exists(value)) {
                text = this.get('intl').t(value);
            }
            options_arr.pushObject(
                Ember.Object.create({
                    checked: checked,
                    key: key,
                    text: text,
                }),
            );
        }
        return options_arr;
    },
    disabled: Em.computed('field', 'model', function () {
        var condition, j, k, len, ref, ref1, set, v;
        if (
            get(this, 'model') &&
            !get(this, 'model.isNew') &&
            get(this, 'field.features.lock_value')
        ) {
            ref = get(this, 'field.features.lock_value');
            for (j = 0, len = ref.length; j < len; j++) {
                set = ref[j];
                for (k in set) {
                    v = set[k];
                    if (((ref1 = get(this, 'model.' + k)), indexOf.call(v.split(','), ref1) >= 0)) {
                        return true;
                    }
                }
            }
        }
        if (typeof this.get('field.features.disabled') === 'object') {
            condition = this.get('field.features.disabled.condition');
            if (
                condition &&
                condition.indexOf('$editing') >= 0 &&
                this.get('field.features.disabled.value') === true &&
                this.get('settings.edit') === true &&
                (condition.indexOf('$wap') === -1 || this.get('field.ext') === 'starttime')
            ) {
                return true;
            } else if (
                this.get('field.features.disabled.value') === true &&
                !this.get('field.features.disabled.condition')
            ) {
                return true;
            }
        } else if (this.get('field.features.disabled')) {
            return true;
        }
        return false;
    }),
    dropdown_date: Ember.computed(function () {
        var arr, date, date_interval, end, i, j, ref, ref1, splitted, start;
        date_interval = this.get('field.features.date_interval');
        if (!date_interval) {
            return null;
        }
        //only when adding
        if (this.get('model.isNew')) {
            this.set('value', moment().format('YYYY-MM-DD'));
        }
        if (date_interval === 'locked') {
            return 'locked';
        }
        arr = Em.A();
        splitted = ('' + date_interval).split(',');
        if (splitted.length > 1) {
            start = splitted[1] * -1;
            end = splitted[0];
        } else {
            start = 0;
            end = date_interval;
        }
        for (
            i = j = ref = start, ref1 = end;
            ref <= ref1 ? j < ref1 : j > ref1;
            i = ref <= ref1 ? ++j : --j
        ) {
            date = moment().subtract(i, 'days');
            arr.push(
                Ember.Object.create({
                    id: date.format('YYYY-MM-DD'),
                    name: date.format('dddd L'),
                }),
            );
        }
        return arr;
    }),
    showPassword: Ember.computed('value', function () {
        if (this.get('show_generated_password') && this.get('value') !== 'secured') {
            return true;
        } else {
            return this.get('value') === 'secured';
        }
    }),
    countries: [
        {
            id: 'fi',
            value: 'Finland',
        },
        {
            id: 'se',
            value: 'Sweden',
        },
        {
            id: 'ee',
            value: 'Estonia',
        },
        {
            id: 'pl',
            value: 'Poland',
        },
        {
            id: 'lt',
            value: 'Lithuania',
        },
        {
            id: 'lv',
            value: 'Latvia',
        },
        {
            id: 'ru',
            value: 'Russian Federation',
        },
        {
            id: 'af',
            value: 'Afghanistan',
        },
        {
            id: 'ax',
            value: 'Aland Islands',
        },
        {
            id: 'al',
            value: 'Albania',
        },
        {
            id: 'dz',
            value: 'Algeria',
        },
        {
            id: 'as',
            value: 'American Samoa',
        },
        {
            id: 'ad',
            value: 'Andorra',
        },
        {
            id: 'ao',
            value: 'Angola',
        },
        {
            id: 'ai',
            value: 'Anguilla',
        },
        {
            id: 'aq',
            value: 'Antarctica',
        },
        {
            id: 'ag',
            value: 'Antigua And Barbuda',
        },
        {
            id: 'ar',
            value: 'Argentina',
        },
        {
            id: 'am',
            value: 'Armenia',
        },
        {
            id: 'aw',
            value: 'Aruba',
        },
        {
            id: 'au',
            value: 'Australia',
        },
        {
            id: 'at',
            value: 'Austria',
        },
        {
            id: 'az',
            value: 'Azerbaijan',
        },
        {
            id: 'bs',
            value: 'Bahamas',
        },
        {
            id: 'bh',
            value: 'Bahrain',
        },
        {
            id: 'bd',
            value: 'Bangladesh',
        },
        {
            id: 'bb',
            value: 'Barbados',
        },
        {
            id: 'by',
            value: 'Belarus',
        },
        {
            id: 'be',
            value: 'Belgium',
        },
        {
            id: 'bz',
            value: 'Belize',
        },
        {
            id: 'bj',
            value: 'Benin',
        },
        {
            id: 'bm',
            value: 'Bermuda',
        },
        {
            id: 'bt',
            value: 'Bhutan',
        },
        {
            id: 'bo',
            value: 'Bolivia, Plurinational State Of',
        },
        {
            id: 'bq',
            value: 'Bonaire, Sint Eustatius And Saba',
        },
        {
            id: 'ba',
            value: 'Bosnia And Herzegovina',
        },
        {
            id: 'bw',
            value: 'Botswana',
        },
        {
            id: 'bv',
            value: 'Bouvet Island',
        },
        {
            id: 'br',
            value: 'Brazil',
        },
        {
            id: 'io',
            value: 'British Indian Ocean Territory',
        },
        {
            id: 'bn',
            value: 'Brunei Darussalam',
        },
        {
            id: 'bg',
            value: 'Bulgaria',
        },
        {
            id: 'bf',
            value: 'Burkina Faso',
        },
        {
            id: 'bi',
            value: 'Burundi',
        },
        {
            id: 'kh',
            value: 'Cambodia',
        },
        {
            id: 'cm',
            value: 'Cameroon',
        },
        {
            id: 'ca',
            value: 'Canada',
        },
        {
            id: 'cv',
            value: 'Cape Verde',
        },
        {
            id: 'ky',
            value: 'Cayman Islands',
        },
        {
            id: 'cf',
            value: 'Central African Republic',
        },
        {
            id: 'td',
            value: 'Chad',
        },
        {
            id: 'cl',
            value: 'Chile',
        },
        {
            id: 'cn',
            value: 'China',
        },
        {
            id: 'cx',
            value: 'Christmas Island',
        },
        {
            id: 'cc',
            value: 'Cocos (keeling) Islands',
        },
        {
            id: 'co',
            value: 'Colombia',
        },
        {
            id: 'km',
            value: 'Comoros',
        },
        {
            id: 'cg',
            value: 'Congo',
        },
        {
            id: 'cd',
            value: 'Congo, The Democratic Republic Of The',
        },
        {
            id: 'ck',
            value: 'Cook Islands',
        },
        {
            id: 'cr',
            value: 'Costa Rica',
        },
        {
            id: 'ci',
            value: "Cote D'ivoire",
        },
        {
            id: 'hr',
            value: 'Croatia',
        },
        {
            id: 'cu',
            value: 'Cuba',
        },
        {
            id: 'cw',
            value: 'Curacao',
        },
        {
            id: 'cy',
            value: 'Cyprus',
        },
        {
            id: 'cz',
            value: 'Czech Republic',
        },
        {
            id: 'dk',
            value: 'Denmark',
        },
        {
            id: 'dj',
            value: 'Djibouti',
        },
        {
            id: 'dm',
            value: 'Dominica',
        },
        {
            id: 'do',
            value: 'Dominican Republic',
        },
        {
            id: 'ec',
            value: 'Ecuador',
        },
        {
            id: 'eg',
            value: 'Egypt',
        },
        {
            id: 'sv',
            value: 'El Salvador',
        },
        {
            id: 'gq',
            value: 'Equatorial Guinea',
        },
        {
            id: 'er',
            value: 'Eritrea',
        },
        {
            // Moved to top
            // {id: "ee", value: "Estonia"}
            id: 'et',
            value: 'Ethiopia',
        },
        {
            id: 'fk',
            value: 'Falkland Islands (malvinas)',
        },
        {
            id: 'fo',
            value: 'Faroe Islands',
        },
        {
            id: 'fj',
            value: 'Fiji',
        },
        {
            id: 'fr',
            value: 'France',
        },
        {
            id: 'gf',
            value: 'French Guiana',
        },
        {
            id: 'pf',
            value: 'French Polynesia',
        },
        {
            id: 'tf',
            value: 'French Southern Territories',
        },
        {
            id: 'ga',
            value: 'Gabon',
        },
        {
            id: 'gm',
            value: 'Gambia',
        },
        {
            id: 'ge',
            value: 'Georgia',
        },
        {
            id: 'de',
            value: 'Germany',
        },
        {
            id: 'gh',
            value: 'Ghana',
        },
        {
            id: 'gi',
            value: 'Gibraltar',
        },
        {
            id: 'gr',
            value: 'Greece',
        },
        {
            id: 'gl',
            value: 'Greenland',
        },
        {
            id: 'gd',
            value: 'Grenada',
        },
        {
            id: 'gp',
            value: 'Guadeloupe',
        },
        {
            id: 'gu',
            value: 'Guam',
        },
        {
            id: 'gt',
            value: 'Guatemala',
        },
        {
            id: 'gg',
            value: 'Guernsey',
        },
        {
            id: 'gn',
            value: 'Guinea',
        },
        {
            id: 'gw',
            value: 'Guinea-bissau',
        },
        {
            id: 'gy',
            value: 'Guyana',
        },
        {
            id: 'ht',
            value: 'Haiti',
        },
        {
            id: 'hm',
            value: 'Heard Island And Mcdonald Islands',
        },
        {
            id: 'va',
            value: 'Holy See (vatican City State)',
        },
        {
            id: 'hn',
            value: 'Honduras',
        },
        {
            id: 'hk',
            value: 'Hong Kong',
        },
        {
            id: 'hu',
            value: 'Hungary',
        },
        {
            id: 'is',
            value: 'Iceland',
        },
        {
            id: 'in',
            value: 'India',
        },
        {
            id: 'id',
            value: 'Indonesia',
        },
        {
            id: 'ir',
            value: 'Iran, Islamic Republic Of',
        },
        {
            id: 'iq',
            value: 'Iraq',
        },
        {
            id: 'ie',
            value: 'Ireland',
        },
        {
            id: 'im',
            value: 'Isle Of Man',
        },
        {
            id: 'il',
            value: 'Israel',
        },
        {
            id: 'it',
            value: 'Italy',
        },
        {
            id: 'jm',
            value: 'Jamaica',
        },
        {
            id: 'jp',
            value: 'Japan',
        },
        {
            id: 'je',
            value: 'Jersey',
        },
        {
            id: 'jo',
            value: 'Jordan',
        },
        {
            id: 'kz',
            value: 'Kazakhstan',
        },
        {
            id: 'ke',
            value: 'Kenya',
        },
        {
            id: 'ki',
            value: 'Kiribati',
        },
        {
            id: 'kp',
            value: "Korea, Democratic People's Republic Of",
        },
        {
            id: 'kr',
            value: 'Korea, Republic Of',
        },
        {
            id: 'xk',
            value: 'Kosovo',
        },
        {
            id: 'kw',
            value: 'Kuwait',
        },
        {
            id: 'kg',
            value: 'Kyrgyzstan',
        },
        {
            id: 'la',
            value: "Lao People's Democratic Republic",
        },
        {
            // Moved too top
            // {id: "lv", value: "Latvia"}
            id: 'lb',
            value: 'Lebanon',
        },
        {
            id: 'ls',
            value: 'Lesotho',
        },
        {
            id: 'lr',
            value: 'Liberia',
        },
        {
            id: 'ly',
            value: 'Libyan Arab Jamahiriya',
        },
        {
            id: 'li',
            value: 'Liechtenstein',
        },
        {
            // Moved to top
            // {id: "lt", value: "Lithuania"}
            id: 'lu',
            value: 'Luxembourg',
        },
        {
            id: 'mo',
            value: 'Macao',
        },
        {
            id: 'mk',
            value: 'Macedonia, The Former Yugoslav Republic Of',
        },
        {
            id: 'mg',
            value: 'Madagascar',
        },
        {
            id: 'mw',
            value: 'Malawi',
        },
        {
            id: 'my',
            value: 'Malaysia',
        },
        {
            id: 'mv',
            value: 'Maldives',
        },
        {
            id: 'ml',
            value: 'Mali',
        },
        {
            id: 'mt',
            value: 'Malta',
        },
        {
            id: 'mh',
            value: 'Marshall Islands',
        },
        {
            id: 'mq',
            value: 'Martinique',
        },
        {
            id: 'mr',
            value: 'Mauritania',
        },
        {
            id: 'mu',
            value: 'Mauritius',
        },
        {
            id: 'yt',
            value: 'Mayotte',
        },
        {
            id: 'mx',
            value: 'Mexico',
        },
        {
            id: 'fm',
            value: 'Micronesia, Federated States Of',
        },
        {
            id: 'md',
            value: 'Moldova, Republic Of',
        },
        {
            id: 'mc',
            value: 'Monaco',
        },
        {
            id: 'mn',
            value: 'Mongolia',
        },
        {
            id: 'me',
            value: 'Montenegro',
        },
        {
            id: 'ms',
            value: 'Montserrat',
        },
        {
            id: 'ma',
            value: 'Morocco',
        },
        {
            id: 'mz',
            value: 'Mozambique',
        },
        {
            id: 'mm',
            value: 'Myanmar',
        },
        {
            id: 'na',
            value: 'Namibia',
        },
        {
            id: 'nr',
            value: 'Nauru',
        },
        {
            id: 'np',
            value: 'Nepal',
        },
        {
            id: 'nl',
            value: 'Netherlands',
        },
        {
            id: 'nc',
            value: 'New Caledonia',
        },
        {
            id: 'nz',
            value: 'New Zealand',
        },
        {
            id: 'ni',
            value: 'Nicaragua',
        },
        {
            id: 'ne',
            value: 'Niger',
        },
        {
            id: 'ng',
            value: 'Nigeria',
        },
        {
            id: 'nu',
            value: 'Niue',
        },
        {
            id: 'nf',
            value: 'Norfolk Island',
        },
        {
            id: 'mp',
            value: 'Northern Mariana Islands',
        },
        {
            id: 'no',
            value: 'Norway',
        },
        {
            id: 'om',
            value: 'Oman',
        },
        {
            id: 'pk',
            value: 'Pakistan',
        },
        {
            id: 'pw',
            value: 'Palau',
        },
        {
            id: 'ps',
            value: 'Palestinian Territory, Occupied',
        },
        {
            id: 'pa',
            value: 'Panama',
        },
        {
            id: 'pg',
            value: 'Papua New Guinea',
        },
        {
            id: 'py',
            value: 'Paraguay',
        },
        {
            id: 'pe',
            value: 'Peru',
        },
        {
            id: 'ph',
            value: 'Philippines',
        },
        {
            id: 'pn',
            value: 'Pitcairn',
        },
        {
            // Moved to top
            // {id: "pl", value: "Poland"}
            id: 'pt',
            value: 'Portugal',
        },
        {
            id: 'pr',
            value: 'Puerto Rico',
        },
        {
            id: 'qa',
            value: 'Qatar',
        },
        {
            id: 're',
            value: 'Reunion',
        },
        {
            id: 'ro',
            value: 'Romania',
        },
        {
            // Moved to top
            // {id: "ru", value: "Russian Federation"}
            id: 'rw',
            value: 'Rwanda',
        },
        {
            id: 'bl',
            value: 'Saint Barthelemy',
        },
        {
            id: 'sh',
            value: 'Saint Helena, Ascension And Tristan Da Cunha',
        },
        {
            id: 'kn',
            value: 'Saint Kitts And Nevis',
        },
        {
            id: 'lc',
            value: 'Saint Lucia',
        },
        {
            id: 'mf',
            value: 'Saint Martin (french Part)',
        },
        {
            id: 'pm',
            value: 'Saint Pierre And Miquelon',
        },
        {
            id: 'vc',
            value: 'Saint Vincent And The Grenadines',
        },
        {
            id: 'ws',
            value: 'Samoa',
        },
        {
            id: 'sm',
            value: 'San Marino',
        },
        {
            id: 'st',
            value: 'Sao Tome And Principe',
        },
        {
            id: 'sa',
            value: 'Saudi Arabia',
        },
        {
            id: 'sn',
            value: 'Senegal',
        },
        {
            id: 'rs',
            value: 'Serbia',
        },
        {
            id: 'sc',
            value: 'Seychelles',
        },
        {
            id: 'sl',
            value: 'Sierra Leone',
        },
        {
            id: 'sg',
            value: 'Singapore',
        },
        {
            id: 'sx',
            value: 'Sint Maarten (dutch Part)',
        },
        {
            id: 'sk',
            value: 'Slovakia',
        },
        {
            id: 'si',
            value: 'Slovenia',
        },
        {
            id: 'sb',
            value: 'Solomon Islands',
        },
        {
            id: 'so',
            value: 'Somalia',
        },
        {
            id: 'za',
            value: 'South Africa',
        },
        {
            id: 'gs',
            value: 'South Georgia And The South Sandwich Islands',
        },
        {
            id: 'es',
            value: 'Spain',
        },
        {
            id: 'lk',
            value: 'Sri Lanka',
        },
        {
            id: 'sd',
            value: 'Sudan',
        },
        {
            id: 'sr',
            value: 'Suriname',
        },
        {
            id: 'sj',
            value: 'Svalbard And Jan Mayen',
        },
        {
            id: 'sz',
            value: 'Swaziland',
        },
        {
            // Moved to top
            //{id: "se", value: "Sweden"}
            id: 'ch',
            value: 'Switzerland',
        },
        {
            id: 'sy',
            value: 'Syrian Arab Republic',
        },
        {
            id: 'tw',
            value: 'Taiwan, Province Of China',
        },
        {
            id: 'tj',
            value: 'Tajikistan',
        },
        {
            id: 'tz',
            value: 'Tanzania, United Republic Of',
        },
        {
            id: 'th',
            value: 'Thailand',
        },
        {
            id: 'tl',
            value: 'Timor-leste',
        },
        {
            id: 'tg',
            value: 'Togo',
        },
        {
            id: 'tk',
            value: 'Tokelau',
        },
        {
            id: 'to',
            value: 'Tonga',
        },
        {
            id: 'tt',
            value: 'Trin{idad And Tobago',
        },
        {
            id: 'tn',
            value: 'Tunisia',
        },
        {
            id: 'tr',
            value: 'Turkey',
        },
        {
            id: 'tm',
            value: 'Turkmenistan',
        },
        {
            id: 'tc',
            value: 'Turks And Caicos Islands',
        },
        {
            id: 'tv',
            value: 'Tuvalu',
        },
        {
            id: 'ug',
            value: 'Uganda',
        },
        {
            id: 'ua',
            value: 'Ukraine',
        },
        {
            id: 'ae',
            value: 'United Arab Emirates',
        },
        {
            id: 'gb',
            value: 'United Kingdom',
        },
        {
            id: 'us',
            value: 'United States',
        },
        {
            id: 'um',
            value: 'United States Minor Outlying Islands',
        },
        {
            id: 'uy',
            value: 'Uruguay',
        },
        {
            id: 'uz',
            value: 'Uzbekistan',
        },
        {
            id: 'vu',
            value: 'Vanuatu',
        },
        {
            id: 'va',
            value: 'Vatican City State',
        },
        {
            id: 've',
            value: 'Venezuela, Bolivarian Republic Of',
        },
        {
            id: 'vn',
            value: 'Viet Nam',
        },
        {
            id: 'vg',
            value: 'Virgin Islands, British',
        },
        {
            id: 'vi',
            value: 'Virgin Islands, U.s.',
        },
        {
            id: 'wf',
            value: 'Wallis And Futuna',
        },
        {
            id: 'eh',
            value: 'Western Sahara',
        },
        {
            id: 'ye',
            value: 'Yemen',
        },
        {
            id: 'zm',
            value: 'Zambia',
        },
        {
            id: 'zw',
            value: 'Zimbabwe',
        },
    ],
});

export default CollectorFieldComponent;
