/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/edit/route.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersEditRoute;

import Ember from 'ember';

import { scheduleOnce } from '@ember/runloop';
import GeneralAddRoute from '../../../routes/general-add';

UsersEditRoute = GeneralAddRoute.extend({
    modelName: 'user',
    controllerName: 'users/edit',
    edit: true,
    afterSave: 'users',

    queryParams: {
        anchor: {
            refreshModel: false,
            defaultValue: null
        },
        profileImgClicked: {
            refreshModel: false,
            defaultValue: null
        }
    },

    setupController: function (controller, model) {
        this._super(controller, model);
        controller.notifyPropertyChange('currentSaldo');
        this.scrollToAnchor();
    },

    scrollToAnchor() {
        scheduleOnce('afterRender', this, function() {
            const { anchor, profileImgClicked } = this.paramsFor(this.routeName);
            if (anchor && profileImgClicked) {
                const element = document.querySelector(`[data-anchor="${anchor}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        });
    }
});

export default UsersEditRoute;
