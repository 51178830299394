/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/config.coffee.old
// Generated by CoffeeScript 2.6.1
var addLinkList,
    branding_needs,
    defaultFirstPages,
    possibleFirstPages,
    savedCustomization,
    staticMenus;

staticMenus = [
    {
        needs: ['worktime||presence||orientation||tr||mvr'],
        link: ['dashboard'],
        icon: 'move move-Dashboard',
        text: 'menu.dashboard',
        id: 'dashboard',
        submenus: [],
    },
    {
        needs: ['worktime||presence||orientation||tr||mvr'],
        link: ['my-sites'],
        icon: 'move move-MySites',
        text: 'menu.my-sites',
        id: 'my-sites',
        submenus: [],
    },
    {
        needs: ['worktime', 'modulecontrol.worktimes', 'worktime@access'],
        link: ['timetracker.worktime-list'],
        icon: 'move move-Time',
        text: 'menu.worktimes',
        id: 'worktime',
        pdfHeaders: ['date', 'project'],
        submenus: [
            {
                text: 'menu.worktime-list',
                link: 'timetracker.worktime-list',
            },
            {
                text: 'menu.anomalyreport',
                link: 'timetracker.anomalies',
                needs: ['!worktime.status@readonly', 'development'],
            },
            {
                text: 'menu.approval',
                link: 'timetracker.approval',
                needs: ['!worktime.status@readonly'],
            },
            {
                text: 'menu.timetracker.realtime',
                link: 'timetracker.realtime',
                needs: ['!userlevel=1'],
            },
            {
                text: 'menu.timetracker.comp-report',
                link: 'timetracker.comp-report',
                needs: ['!userlevel=1?', 'abcense'],
            },
            {
                text: 'menu.timetracker.week-report',
                link: 'timetracker.week-report',
                needs: ['mobileView'],
            },
            {
                text: 'menu.worktime_bank',
                link: 'timetracker.bank',
                needs: ['products.worktimebank'],
            },
            {
                text: 'menu.worktime_bank',
                link: 'timetracker.bank_ad',
                needs: ['products.mini_entry', '!products.worktimebank'],
            },
            {
                text: 'report.orientations.avi',
                link: 'timetracker.avi',
                needs: [
                    '!userlevel=1?',
                    'products.rakentajanpaketti||orientation',
                    'serviceCountry@fi',
                ],
            },
            {
                //{text: 'report.export_salary', link: 'salary.export-salary', needs: ["userlevel=2,3,5", "export_salary", "export_salary@access"]}
                text: 'menu.worktime_calendar',
                link: 'timetracker.calendar',
            },
            {
                text: 'menu.timetracker.aggregated',
                link: 'timetracker.aggregated',
                needs: ['!userlevel=1?', 'worktime', 'abcense', '!mobileView'],
                subroutes: ['timetracker.aggregated.rows'],
            },
            {
                text: 'menu.timetracker.salary-category',
                link: 'timetracker.salary-category',
                needs: ['!userlevel=1?', 'worktime', 'salarytype||salarytypev2', '!mobileView'],
            },
        ],
    },
    {
        needs: ['drive@add', 'modules.drivinglog'],
        link: ['drivinglog'],
        icon: 'move move-Mileage',
        text: 'menu.drivinglog',
        id: 'drivinglog',
    },
    {
        needs: [
            'export_contact_person||urakkaraportointi',
            'export_taxman@access',
            'products.rakentajanpaketti', //,'development']
        ],
        link: ['taxman.taxreport'],
        icon: 'move move-Law',
        text: 'menu.taxman',
        id: 'taxman',
        submenus: [
            {
                text: 'menu.taxreport',
                link: 'taxman.taxreport',
            },
            {
                text: 'menu.taxman-contracts-list',
                link: 'taxman.contract-list',
                needs: ['urakkaraportointi'],
            },
            {
                text: 'menu.taxman-contracts',
                link: 'taxman.taxreport-contract',
                needs: ['urakkaraportointi'],
            },
            {
                text: 'menu.taxman-archive',
                link: 'taxman.archive',
            },
        ],
    },
    {
        needs: [
            'export_contact_person||urakkaraportointi',
            'userlevel=5',
            '!products.rakentajanpaketti', //,'development']
        ],
        link: ['taxman.taxreport'],
        icon: 'move move-Law',
        text: 'menu.taxman',
        id: 'taxman',
        submenus: [
            {
                text: 'menu.taxreport',
                link: 'taxman.taxreport',
            },
            {
                text: 'menu.taxman-contracts-list',
                link: 'taxman.contract-list',
                needs: ['urakkaraportointi'],
            },
            {
                text: 'menu.taxman-contracts',
                link: 'taxman.taxreport-contract',
                needs: ['urakkaraportointi'],
            },
            {
                text: 'menu.taxman-archive',
                link: 'taxman.archive',
            },
        ],
    },
    {
        needs: ['diaryreport@add'],
        link: ['worksitediary'],
        icon: 'move move-LiteratureFilled',
        text: 'menu.worksitediary',
        id: 'worksitediary',
        submenus: [
            {
                text: 'menu.worksitediary',
                link: 'worksitediary.index',
            },
            {
                text: 'menu.worksitediary-events',
                link: 'worksitediary.events',
            },
            {
                text: 'menu.worksitediary-supervisor-notes-report',
                link: 'worksitediary.supervisor-notes-report',
                needs: ['diaryreport_supervisor_note'],
            },
            {
                text: 'menu.worksitediary-offering-report',
                link: 'worksitediary.offering-report',
                needs: ['diaryreport_given_offer'],
            },
            {
                text: 'menu.worksitediary-other-entries-report',
                link: 'worksitediary.other-entries-report',
                needs: ['diaryreport_other_note'],
            },
            {
                text: 'menu.worksitediary.calendar',
                link: 'worksitediary.calendar',
                needs: ['diaryreport@add'],
            },
            {
                text: 'menu.worksitediary.diaryreport_note',
                link: 'worksitediary.notes',
                needs: ['diaryreport_note@add'],
            },
            {
                text: 'menu.worksitediary.automation',
                link: 'worksitediary.automation',
                needs: ['worksitediary@add'],
            },
        ],
    },
    {
        needs: ['diary@access||worksitediary@add'],
        link: ['diary'],
        icon: 'move move-LiteratureFilled',
        text: 'menu.diary',
        id: 'diary',
        submenus: [
            {
                text: 'menu.diary',
                link: 'diary.index',
                needs: ['diary@access'],
            },
            {
                text: 'menu.diaryevents',
                link: 'diary.events',
                needs: ['worksitediary@add'],
            },
            {
                text: 'menu.diarycalendar',
                link: 'diary.calendar',
                needs: ['diary@access'],
            },
            {
                text: 'menu.diarycombo',
                link: 'diary.combo',
                needs: ['diary@access', 'worktime@access'],
            },
        ],
    },
    {
        needs: [
            'products.resource_planning',
            'userfieldcheck.resourcePlanner||userfieldcheck.showInRP',
        ],
        link: ['resource-planning.gantt'],
        icon: 'move move-LiteratureFilled',
        text: 'menu.resource-planning',
        id: 'resource-planning',
        submenus: [
            {
                text: 'report.resource-planning-gantt',
                link: 'resource-planning.gantt',
                needs: ['userfieldcheck.resourcePlanner'],
            },
            {
                text: 'report.resource-planning-week-list',
                link: 'resource-planning.week',
                needs: ['userfieldcheck.showInRP'],
            },
        ],
    },
    {
        needs: [
            'products.shift_planning',
            'userfieldcheck.resourcePlanner||userfieldcheck.showInRP',
        ],
        link: ['shift-planning.gantt'],
        icon: 'move move-Calendar',
        text: 'menu.shift-planning',
        id: 'shift-planning',
        submenus: [
            {
                text: 'report.shift-planning-gantt',
                link: 'shift-planning.gantt',
                needs: ['userfieldcheck.resourcePlanner'],
            },
            {
                text: 'report.shift-planning-week-list',
                link: 'shift-planning.week',
                needs: ['userfieldcheck.showInRP'],
            },
        ],
    },
    {
        needs: ['presence', 'presence@add||userlevel=2'],
        link: ['presences'],
        icon: 'move move-Marker',
        text: 'menu.presences',
        id: 'presence',
        submenus: [
            {
                text: 'report.presences',
                link: 'presences.index',
            },
            {
                text: 'menu.accident',
                link: 'presences.accident',
                needs: ['user.id06kort'],
            },
            {
                text: 'menu.report',
                link: 'presences.report',
            },
            {
                text: 'menu.emp_sums',
                link: 'presences.employer-sums',
                needs: ['user.id06kort'],
            },
            {
                text: 'menu.location_report',
                link: 'presences.location',
                needs: ['products.entrytracker||products.wirepas'],
            },
            {
                text: 'report.orientations.avi',
                link: 'presences.avi',
                needs: [
                    '!userlevel=1?',
                    'products.rakentajanpaketti||orientation',
                    'serviceCountry@fi',
                ],
            },
        ],
    },
    {
        needs: ['abcense@add||userlevel=2', 'worktime', 'modulecontrol.worktimes'],
        link: ['abcenses'],
        icon: 'move move-Cup',
        text: 'menu.abcenses',
        id: 'absence',
        submenus: [
            {
                text: 'menu.abcenses',
                link: 'abcenses.index',
            },
            {
                text: 'menu.absence_gantt',
                link: 'abcenses.gantt',
            },
            {
                text: 'menu.holidays',
                link: 'abcenses.holidays',
                needs: ['!tt3', '!customersetting.hide_holidays'],
            },
            {
                text: 'menu.holidaypays',
                link: 'abcenses.holidaypays',
                needs: ['modules.holiday_pay', 'flag.holidaypay_v2'],
            },
        ],
    },
    {
        //        {text: 'report.export_abcense', link: 'salary.export-abcense', needs: ["userlevel=2,3,5", "export_absence"]}
        needs: ['orientation@add'],
        link: ['orientations.reports'],
        icon: 'move move-TodoList',
        text: 'menu.orientations',
        id: 'orientation',
        submenus: [
            {
                text: 'report.orientations',
                link: 'orientations.reports',
            },
            {
                text: 'report.orientations.passes',
                link: 'orientations.passes',
            },
            {
                text: 'report.orientations.withoutori',
                link: 'orientations.withoutori',
                needs: ['user@add', '!userlevel=1'],
            },
            {
                text: 'report.orientations.employeetree',
                link: 'orientations.employeetree',
                needs: ['products.orientations_parent', '!userlevel=1'],
            },
        ],
    },
    {
        needs: ['tr', 'trnotice@add', '!mvr'],
        link: ['site_safety.report.tr.graphs'],
        icon: 'move move-Length',
        text: 'menu.site_safety',
        id: 'site-safety',
        submenus: [
            {
                text: 'tr.graphs',
                link: 'site_safety.report.tr.graphs',
                needs: ['tr@add'],
            },
            {
                text: 'tr.data',
                link: 'site_safety.report.tr.data',
                needs: ['tr@add'],
            },
            {
                text: 'tr.notices',
                link: 'site_safety.report.tr.notices',
            },
            {
                text: 'menu.site_safety_observation',
                link: 'site_safety.report.observation',
                needs: ['site_safety_observation'],
            },
            {
                text: 'menu.site_safety_event',
                link: 'site_safety.report.event',
                needs: [
                    'site_safety_observation',
                    'site_safety_observation@access',
                    'site_safety_event@access',
                ],
            },
        ],
    },
    {
        needs: ['mvr', 'trnotice@add', '!tr'],
        link: ['site_safety.report.mvr.graphs'],
        icon: 'move move-Humidity',
        text: 'menu.site_safety',
        id: 'site-safety',
        submenus: [
            {
                text: 'mvr.graphs',
                link: 'site_safety.report.mvr.graphs',
                needs: ['mvr@add'],
            },
            {
                text: 'mvr.data',
                link: 'site_safety.report.mvr.data',
                needs: ['mvr@add'],
            },
            {
                text: 'mvr.notices',
                link: 'site_safety.report.mvr.notices',
            },
            {
                text: 'menu.site_safety_observation',
                link: 'site_safety.report.observation',
                needs: ['site_safety_observation'],
            },
            {
                text: 'menu.site_safety_event',
                link: 'site_safety.report.event',
                needs: [
                    'site_safety_observation',
                    'site_safety_observation@access',
                    'site_safety_event@access',
                ],
            },
        ],
    },
    {
        needs: ['mvr', 'trnotice@add', 'tr'],
        link: ['site_safety.report.tr.graphs'],
        icon: 'move move-Humidity',
        text: 'menu.site_safety',
        id: 'site-safety',
        submenus: [
            {
                text: 'tr.graphs',
                link: 'site_safety.report.tr.graphs',
                needs: ['tr@add'],
            },
            {
                text: 'tr.data',
                link: 'site_safety.report.tr.data',
                needs: ['tr@add'],
            },
            {
                text: 'tr.notices',
                link: 'site_safety.report.tr.notices',
            },
            {
                text: 'mvr.graphs',
                link: 'site_safety.report.mvr.graphs',
                needs: ['mvr@add'],
            },
            {
                text: 'mvr.data',
                link: 'site_safety.report.mvr.data',
                needs: ['mvr@add'],
            },
            {
                text: 'mvr.notices',
                link: 'site_safety.report.mvr.notices',
            },
            {
                text: 'menu.site_safety_observation',
                link: 'site_safety.report.observation',
                needs: ['site_safety_observation'],
            },
            {
                text: 'menu.site_safety_event',
                link: 'site_safety.report.event',
                needs: [
                    'site_safety_observation',
                    'site_safety_observation@access',
                    'site_safety_event@access',
                ],
            },
        ],
    },
    {
        needs: ['skyddsrond', 'notice@add'],
        link: ['skyddsronds.dashboard'],
        icon: 'move move-Length',
        text: 'menu.skyddsrond',
        id: 'skyddsrond',
        submenus: [
            {
                text: 'skyddsronds.dashboard',
                link: 'skyddsronds.dashboard',
            },
            {
                text: 'skyddsronds.report',
                link: 'skyddsronds.report',
                needs: ['skyddsrond@add'],
            },
            {
                text: 'skyddsronds.notices',
                link: 'skyddsronds.notices',
            },
        ],
    },
    {
        needs: ['worktask@edit', 'userlevel=1,4'],
        link: ['worktasks.list'],
        icon: 'move move-Checklist',
        text: 'menu.worktask',
        id: 'worktask',
        submenus: [
            {
                text: 'menu.worktask',
                link: ['worktasks.list'],
                needs: ['worktask@edit', 'userlevel=1,4'],
                showMobile: true,
            },
            {
                //{text: "menu.add_presence", link: ['presences.add'], needs: ['presence@add'], showMobile: true}
                //{text: "menu.clockcard", link: ['timetracker.timecard.add'], needs: ['userlevel=1,4', 'worktime', 'worktime.starttime@disabled||products.timetracker_hybrid', 'modulecontrol.worktimes'], showMobile: true}
                //{text: "menu.add_worktime", link: ['timetracker.add'], needs: ['userlevel=1,4', 'worktime@add', '!worktime.starttime@disabled', '!products.timetracker_hybrid', 'modulecontrol.worktimes'], showMobile: true}
                //{text: "menu.add_worktime", link: ['timetracker.add'], needs: ['!userlevel=1,4', 'worktime@add', 'modulecontrol.worktimes'], showMobile: true}
                text: 'report.worktasks.calendar',
                link: 'worktasks.calendar',
            },
            {
                text: 'report.worktasks.report',
                link: 'worktasks.report',
            },
        ],
    },
    {
        needs: ['worktask@add||worktask@access', '!userlevel=1,4'],
        link: ['worktasks.calendar'],
        icon: 'move move-Checklist',
        text: 'menu.worktask',
        id: 'worktask',
        submenus: [
            {
                text: 'menu.worktask',
                link: ['worktasks.list'],
                needs: ['worktask@edit'],
                showMobile: true,
            },
            {
                text: 'report.worktasks.calendar',
                link: 'worktasks.calendar',
            },
            {
                text: 'report.worktasks.report',
                link: 'worktasks.report',
            },
            {
                text: 'report.worktasks.gantt',
                link: 'worktasks.gantt',
            },
        ],
    },
    {
        needs: ['materiallog@add', '!userlevel=1?'],
        link: ['materiallog.report'],
        icon: 'move move-Checklist',
        text: 'menu.materiallog',
        id: 'material',
        submenus: [
            {
                text: 'report.materials.index',
                link: 'materiallog.report',
            },
            {
                text: 'report.materials.groups',
                link: 'materiallog.groups',
                needs: ['products.materialgroups', 'materialgroup@access'],
            },
            {
                text: 'report.materials.list',
                link: 'materiallog.list',
                needs: ['products.materialgroups', 'materialtype@access'],
            },
        ],
    },
    {
        needs: ['multitask@add'],
        link: ['multitask.report'],
        icon: 'move move-Checklist',
        text: 'menu.multitask',
        id: 'multitask',
    },
    {
        needs: ['user@add'],
        link: ['users'],
        icon: 'move move-Users',
        text: 'menu.users',
        id: 'users',
        submenus: [
            {
                text: 'report.users.index',
                link: 'users.index',
            },
            {
                text: 'report.users.id06card',
                link: 'users.card',
                needs: ['user.id06valid'],
            },
            {
                text: 'report.users.subcontractor',
                link: 'users.subcontractor',
                needs: ['user.taxnumber'],
            },
            {
                text: 'report.users.linked_users',
                link: 'users.linked',
            },
            {
                text: 'report.users.removed',
                link: 'users.removed',
            },
            {
                text: 'report.users.inactive',
                link: 'users.inactive',
            },
            {
                text: 'report.users.saldos',
                link: 'users.saldos',
                needs: ['products.saldo'],
            },
            {
                text: 'report.users.saldos',
                link: 'users.saldos_ad',
                needs: ['!products.saldo', 'products.mini_entry'],
            },
            {
                text: 'report.users.saldocalendar',
                link: 'users.saldocalendar',
                needs: ['products.saldo'],
            },
            {
                text: 'report.users.saldohistory',
                link: 'users.saldohistory',
                needs: ['products.saldo', 'saldodeducter@access'],
            },
            {
                text: 'report.users.firecard',
                link: 'firecards.index',
                needs: ['products.firecard'],
            },
            {
                text: 'report.users.cardregister',
                link: 'users.cardregister',
                needs: ['products.cardregister'],
            },
            {
                text: 'report.users.superior_replacements',
                link: 'users.superior_replacements',
                needs: ['products.superior_replacement', 'userlevel=5'],
            },
            {
                text: 'report.users.worktimegroup_changes',
                link: 'users.worktimegroup_changes',
                needs: ['products.worktimegroup_changes', 'userlevel=5'],
            },
        ],
    },
    {
        needs: ['project@add'],
        link: ['projects'],
        icon: 'move move-GeoFence',
        text: 'menu.projects',
        id: 'projects',
        submenus: [
            {
                text: 'report.projects.show_all',
                link: 'projects.index',
            },
            {
                text: 'report.projects.show_closed',
                link: 'projects.removed',
            },
            {
                text: 'report.projects.inactive',
                link: 'projects.inactive',
                needs: ['userlevel=5', 'presence||worktime'],
            },
            {
                text: 'report.projects.rfiddevices',
                link: 'projects.rfiddevices',
                needs: ['userlevel=5', 'modules.famoco'],
            },
            {
                text: 'report.projects.report',
                link: 'projects.report',
                needs: ['userlevel=5', 'abcense', 'worktime'],
            },
            {
                text: 'report.projects.tasks',
                link: 'projects.tasks',
                needs: ['userlevel=6,5', 'task'],
            },
        ],
    },
    {
        needs: ['products.nova_costcenter', 'novacostcenter@access'],
        link: ['nova_costcenters'],
        icon: 'move move-GeoFence',
        text: 'menu.nova_costcenters',
        id: 'nova_costcenters',
    },
    {
        needs: ['lockgroup@access', 'lockpermission@access'],
        link: ['worksite-areas.report'],
        icon: 'move move-Password',
        text: 'menu.worksite-areas',
        id: 'worksite-areas',
        submenus: [
            {
                text: 'report.worksite-areas.report',
                link: 'worksite-areas.report',
            },
            {
                text: 'report.worksite-areas.log',
                link: 'worksite-areas.log-report',
            },
        ],
    },
    {
        needs: ['employer@add'],
        link: ['employers'],
        icon: 'move move-AdministratorFilled',
        text: 'menu.employers',
        id: 'employers',
        submenus: [
            {
                text: 'employer.list',
                link: 'employers.index',
            },
            {
                text: 'employer.tree',
                link: 'employers.tree',
                needs: [
                    'employer.parent',
                    '!products.generic_timetracker',
                    '!flag.hide_employer_tree',
                ],
            },
            {
                text: 'employer.check',
                link: 'employers.check',
                needs: ['employer.tv_status'],
            },
            {
                text: 'employer.removed',
                link: 'employers.removed',
            },
            {
                text: 'employer.inactive',
                link: 'employers.inactive',
            },
        ],
    },
    {
        needs: ['transaction@access'],
        link: ['machines.transactions'],
        icon: 'move move-Hammer',
        text: 'menu.machines',
        id: 'machines',
        submenus: [
            {
                text: 'machines.transactions',
                link: 'machines.transactions',
                needs: ['transaction@access'],
            },
            {
                text: 'machines.transactions_history',
                link: 'machines.transactions-history',
                needs: ['transaction@access'],
            },
            {
                text: 'machines.machine',
                link: 'machines.machine',
                needs: ['tooltype@access'],
            },
            {
                text: 'machines.map',
                link: 'machines.map',
                needs: ['transaction@access'],
            },
            {
                text: 'machines.prices',
                link: 'machines.prices',
                needs: ['transaction@access'],
            },
        ],
    },
    {
        needs: ['worktime', 'userlevel=2,5'],
        link: ['salary'],
        icon: 'move move-Money',
        text: 'menu.salary',
        id: 'salary',
        submenus: [
            {
                text: 'report.export_salary',
                link: 'salary.export-salary',
                needs: ['userlevel=2,3,5'],
            },
            {
                text: 'report.export_abcense',
                link: 'salary.export-abcense',
                needs: ['userlevel=2,3,5', 'export_absence'],
            },
            {
                text: 'settings.custom-export',
                link: 'salary.custom-export',
                needs: ['products.custom_export'],
            },
            {
                text: 'settings.custom-export',
                link: 'salary.custom-export-ad',
                needs: ['products.mini_entry', '!products.custom_export'],
            },
            {
                text: 'settings.wage_period',
                link: 'salary.salaryperiods',
            },
            {
                text: 'settings.salarytype',
                link: 'salary.salarytypes',
                needs: ['salarytype', '!modules.salarytype_v2'],
            },
            {
                text: 'settings.workday-calendar',
                link: 'salary.workday-calendar',
                needs: ['!mobileView'],
            },
        ],
    },
    {
        needs: ['worktime||products.admintool', '!userlevel=4'],
        link: ['thereport'],
        icon: 'move move-PieChart',
        text: 'menu.comboreport',
        id: 'own-report',
    },
    {
        /*
    AT2 LINKS
    */
        needs: ['products.admintool'],
        link: ['forms.form-report', 'customer'],
        icon: 'move move-Time',
        text: 'menu.admintool',
        submenus: [
            {
                text: 'menu.customers',
                link: ['forms.form-report', 'customer'],
                needs: ['customer@access'],
            },
            {
                text: 'menu.products',
                link: ['forms.form-report', 'customerproduct'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.bills',
                link: ['forms.form-report', 'bill'],
                needs: ['bill@access'],
            },
            {
                text: 'menu.rcbills',
                link: ['forms.form-report', 'rcbill'],
                needs: ['rcbill@access'],
            },
            {
                text: 'menu.campaigns',
                link: ['forms.form-report', 'campaign'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.productcampaigns',
                link: ['forms.form-report', 'productcampaign'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.customercampaigns',
                link: ['forms.form-report', 'customercampaign'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.promocodes',
                link: ['forms.form-report', 'promocode'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.timecleandevices',
                link: ['forms.form-report', 'timecleandevice'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.invoices',
                link: ['forms.custom-report', 'invoices'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.invoicesat2.netvisor',
                link: ['forms.custom-report', 'invoicesat2Netvisor'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.invoicesat2',
                link: ['forms.custom-report', 'invoicesat2'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.invoicesat1',
                link: ['forms.custom-report', 'invoicesat1'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.invoicesat2legacy',
                link: ['forms.custom-report', 'invoicesat2legacy'],
                needs: ['userlevel=5', 'development'],
            },
            {
                text: 'menu.invoicesat1legacy',
                link: ['forms.custom-report', 'invoicesat1legacy'],
                needs: ['userlevel=5', 'development'],
            },
            {
                text: 'menu.comissions',
                link: ['forms.custom-report', 'comissions'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.activity',
                link: ['forms.custom-report', 'activity'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.billinginfomissing',
                link: ['forms.custom-report', 'billinginfomissing'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.subcontractor',
                link: ['forms.form-report', 'subcontractor'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.billingrun',
                link: ['forms.form-report', 'billingrun'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.billinginfo',
                link: ['forms.form-report', 'billinginfo'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.promomessages',
                link: ['forms.form-report', 'promomessage'],
                needs: ['userlevel=5'],
            },
            {
                text: 'menu.tos',
                link: ['forms.custom-report', 'tos'],
                needs: ['userlevel=5'],
            },
        ],
    },
    {
        needs: ['products.admintool', 'userlevel=5'],
        link: ['admintool.reports.bi.timecleanlog'],
        icon: 'move move-Timeclean',
        text: 'menu.timecleanlog',
        submenus: [],
    },
    {
        needs: ['products.admintool', 'userlevel=5'],
        link: ['admintool.reports.featureflags'],
        icon: 'move move-Toggle',
        text: 'menu.featureflags',
        submenus: [],
    },
    {
        needs: ['products.admintool', 'userlevel=5'],
        link: ['groups'],
        icon: 'move move-Users',
        text: 'menu.groups',
        submenus: [],
    },
    {
        /*
    AT2 LINKS END
    */
        needs: ['userlevel=5'],
        link: ['settings'],
        icon: 'move move-Settings',
        text: 'menu.settings',
        id: 'settings',
        submenus: [
            {
                text: 'settings.general',
                link: 'settings.general-v2',
            },
            {
                text: 'settings.notifications',
                link: 'settings.notificationpanel',
                needs: ['!flag.notification-settings-v2'],
            },
            {
                text: 'settings.integrations',
                link: 'settings.integrations',
                needs: ['products.admintool'],
            },
            {
                //{text: "settings.pl", link: 'settings.pl', needs: ["presence"]}
                text: 'settings.measurement_custom',
                link: 'settings.measurement_custom',
                needs: ['products.measurement_custom'],
            },
            {
                text: 'settings.forms',
                link: 'settings.forms',
                subroutes: ['settings.forms.form'],
                needs: ['!products.mini_entry'],
            },
            {
                text: 'settings.products',
                link: 'settings.products',
                needs: ['!products.mini_entry'],
            },
            {
                text: 'settings.billing',
                link: 'settings.billing',
                needs: ['userlevel=5', 'products', '!products.generic_timetracker'],
            },
            {
                text: 'settings.history',
                link: 'settings.history',
            },
        ],
    },
    {
        //{text: "settings.activitylog", link: 'settings.activitylog', needs: ["userlevel=5"]}
        needs: ['!customersetting.new_messageboard', '!products.admintool'],
        link: ['messageboard'],
        icon: 'move move-Message',
        text: 'menu.messageboard',
        id: 'messageboard',
    },
    {
        needs: ['customersetting.new_messageboard', '!products.admintool'],
        link: ['messages.list'],
        icon: 'move move-Message',
        text: 'menu.messages',
        id: 'messages',
        submenus: [
            {
                text: 'menu.messages',
                link: 'messages.list',
            },
            {
                text: 'menu.messages.email-approvals',
                link: 'messages.email-approvals',
                needs: ['email_approval', 'userlevel=5,6', 'email_approval@access'],
            },
        ],
    },
    {
        needs: ['products.mapon', 'userlevel=5'],
        link: ['mapon'],
        icon: 'move move-ComboChart',
        text: 'menu.mapon',
        id: 'mapon',
    },
    {
        needs: ['ccbutton', 'userlevel=5'],
        link: ['integrations'],
        icon: 'move move-puzzle',
        text: 'menu.integrations',
        id: 'integrations',
        submenus: [
            {
                text: 'menu.integrations.run',
                link: 'integrations.index',
            },
            {
                text: 'menu.integrations.report',
                link: 'integrations.report',
            },
            {
                text: 'menu.integrations.list',
                link: 'integrations.list',
                needs: ['development'],
            },
            {
                text: 'menu.integrations.fondionerrors',
                link: 'integrations.fondionerrors',
                needs: ['products.fondion'],
            },
        ],
    },
    {
        needs: ['development'],
        link: ['development'],
        icon: 'move move-Settings',
        text: 'development',
        id: 'development',
        submenus: [
            {
                text: 'development',
                link: 'development.index',
            },
            {
                text: 'cron',
                link: 'development.cron',
            },
            {
                text: 'data creator',
                link: 'development.datacreator',
                needs: ['devMode'],
            },
            {
                text: 'react',
                link: 'development.react',
            },
            {
                text: 'integrations',
                link: 'development.integrations',
            },
            {
                text: 'notifications',
                link: 'development.notifications',
            },
            {
                text: 'sms logs',
                link: 'development.smslogs',
            },
            {
                text: 'functions',
                link: 'development.functions',
            },
            {
                text: 'own reports',
                link: 'development.ownreports',
            },
            {
                text: 'own roles',
                link: 'development.ownroles',
            },
            {
                text: 'products',
                link: 'development.products',
            },
            {
                text: 'rollock',
                link: 'development.rollock',
                needs: ['products.rollock'],
            },
            {
                //{text: "activity", link: 'development.activitylog'}
                text: 'import',
                link: 'development.import',
            },
        ],
    },
];

addLinkList = [
    {
        // if modal-add-form-worktime
        icon: 'move move-Time',
        text: 'menu.clockcard',
        link: 'modal',
        query: 'timecard',
        id: 'worktime',
        needs: [
            'userlevel=1?',
            'worktime',
            'worktime.starttime@disabled||products.timetracker_hybrid',
            'modulecontrol.worktimes',
            'flag.modal-add-form-worktime',
        ],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
                modal: 'presence',
            },
            {
                text: 'menu.clockcard',
                link: ['timetracker.timecard.add'],
                needs: [
                    'userlevel=1?',
                    'worktime',
                    'worktime.starttime@disabled||products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                modal: 'timecard',
            },
        ],
    },
    {
        icon: 'move move-Time',
        text: 'menu.add_worktime',
        link: 'modal',
        query: 'worktime',
        id: 'worktime',
        needs: [
            'userlevel=1?',
            'worktime@add',
            '!worktime.starttime@disabled',
            '!products.timetracker_hybrid',
            'modulecontrol.worktimes',
            'flag.modal-add-form-worktime',
        ],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
                modal: 'presence',
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: [
                    'userlevel=1?',
                    'worktime@add',
                    '!worktime.starttime@disabled',
                    '!products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                modal: 'worktime',
            },
        ],
    },
    {
        icon: 'move move-Time',
        text: 'menu.add_worktime',
        link: 'modal',
        query: 'worktime',
        id: 'worktime',
        needs: [
            '!userlevel=1?',
            'worktime@add',
            'modulecontrol.worktimes',
            'flag.modal-add-form-worktime',
        ],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
                modal: 'presence',
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: ['!userlevel=1?', 'worktime@add', 'modulecontrol.worktimes'],
                modal: 'worktime',
            },
            {
                text: 'header.path.add.timecard',
                link: ['timetracker.timecard.add'],
                needs: [
                    '!userlevel=1?',
                    'worktime',
                    'products.timetracker_clockcard||products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                modal: 'timecard',
            },
        ],
    },
    {
        // else
        icon: 'move move-Time',
        text: 'menu.clockcard',
        link: ['timetracker.timecard.add'],
        id: 'worktime',
        needs: [
            'userlevel=1?',
            'worktime',
            'worktime.starttime@disabled||products.timetracker_hybrid',
            'modulecontrol.worktimes',
            '!flag.modal-add-form-worktime',
        ],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
            },
            {
                text: 'menu.clockcard',
                link: ['timetracker.timecard.add'],
                needs: [
                    'userlevel=1?',
                    'worktime',
                    'worktime.starttime@disabled||products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
            },
        ],
    },
    {
        icon: 'move move-Time',
        text: 'menu.add_worktime',
        link: ['timetracker.add'],
        id: 'worktime',
        needs: [
            'userlevel=1?',
            'worktime@add',
            '!worktime.starttime@disabled',
            '!products.timetracker_hybrid',
            'modulecontrol.worktimes',
            '!flag.modal-add-form-worktime',
        ],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: [
                    'userlevel=1?',
                    'worktime@add',
                    '!worktime.starttime@disabled',
                    '!products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
            },
        ],
    },
    {
        icon: 'move move-Time',
        text: 'menu.add_worktime',
        link: ['timetracker.add'],
        id: 'worktime',
        needs: [
            '!userlevel=1?',
            'worktime@add',
            'modulecontrol.worktimes',
            '!flag.modal-add-form-worktime',
        ],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: ['!userlevel=1?', 'worktime@add', 'modulecontrol.worktimes'],
            },
            {
                text: 'header.path.add.timecard',
                link: ['timetracker.timecard.add'],
                needs: [
                    '!userlevel=1?',
                    'worktime',
                    'products.timetracker_clockcard||products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
            },
        ],
    },
    {
        icon: 'move move-Mileage',
        text: 'menu.toggle_drive',
        link: ['drivinglog.toggle'],
        id: 'drivinglog',
        needs: ['drive@add'],
    },
    {
        //if flag
        icon: 'move move-LiteratureFilled',
        text: 'menu.add_worksitediary',
        link: 'modal',
        query: 'worksitediary',
        id: 'worksitediary',
        needs: ['worksitediary@add', 'flag.ember-movenium-form-add-worksitediary'],
    },
    {
        //else
        icon: 'move move-LiteratureFilled',
        text: 'menu.add_worksitediary',
        link: ['worksitediary.add'],
        id: 'worksitediary',
        needs: ['worksitediary@add', '!flag.ember-movenium-form-add-worksitediary'],
    },
    {
        //if flag
        icon: 'move move-LiteratureFilled',
        text: 'menu.diary',
        link: 'modal',
        query: 'diary',
        id: 'diary',
        needs: ['diary@add', 'flag.ember-movenium-form-add-diary'],
    },
    {
        //else
        icon: 'move move-LiteratureFilled',
        text: 'menu.diary',
        link: ['diary.report'],
        id: 'diary',
        needs: ['diary@add', '!flag.ember-movenium-form-add-diary'],
    },
    {
        icon: 'move move-Cup',
        text: 'menu.add_abcense',
        link: 'modal',
        query: 'abcense',
        id: 'absence',
        needs: ['abcense@add', 'worktime', 'modulecontrol.worktimes'],
        submenus: [
            {
                text: 'menu.add_abcense_multiple',
                link: ['abcenses.add-multiple'],
                needs: ['abcense@add', 'worktime', 'modulecontrol.worktimes'],
            },
            {
                text: 'menu.add_abcense_single',
                link: ['abcenses.add'],
                needs: ['abcense@add', 'worktime', 'modulecontrol.worktimes'],
            },
        ],
    },
    {
        icon: 'move move-Signature',
        text: 'menu.add_pass',
        link: ['pass.add'],
        id: 'pass',
        needs: ['orientation@add'],
    },
    {
        // Swedish Presence Links
        // if no flag (or userlevel=1)
        icon: 'move move-Marker',
        text: 'menu.add_presence',
        link: ['presences.add'],
        id: 'presence',
        needs: ['presence@add', 'serviceCountry@se', '!flag.modal-add-form-worktime||userlevel=1?'],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
                showMobile: true,
            },
            {
                text: 'menu.clockcard',
                link: ['timetracker.timecard.add'],
                needs: [
                    'userlevel=1',
                    'worktime',
                    'worktime.starttime@disabled||products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                showMobile: true,
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: [
                    'userlevel=1',
                    'worktime@add',
                    '!worktime.starttime@disabled',
                    '!products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                showMobile: true,
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: ['!userlevel=1', 'worktime@add', 'modulecontrol.worktimes'],
                showMobile: true,
            },
        ],
    },
    {
        //flag
        icon: 'move move-Marker',
        text: 'menu.add_presence',
        link: 'modal',
        query: 'presence',
        id: 'presence',
        needs: [
            'presence@add',
            'serviceCountry@se',
            'flag.modal-add-form-worktime',
            '!userlevel=1?',
        ],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
                showMobile: true,
                modal: 'presence',
            },
            {
                text: 'menu.clockcard',
                link: ['timetracker.timecard.add'],
                needs: [
                    'userlevel=1',
                    'worktime',
                    'worktime.starttime@disabled||products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                showMobile: true,
                modal: 'timecard',
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: [
                    'userlevel=1',
                    'worktime@add',
                    '!worktime.starttime@disabled',
                    '!products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                showMobile: true,
                modal: 'timecard',
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: ['!userlevel=1', 'worktime@add', 'modulecontrol.worktimes'],
                showMobile: true,
                modal: 'worktime',
            },
        ],
    },
    {
        // Finnish Presence Links for Workers
        // if no flag
        icon: 'move move-Marker',
        text: 'menu.add_presence',
        link: ['presences.add'],
        id: 'presence',
        needs: [
            'presence@add',
            'serviceCountry@fi',
            'userlevel=1?',
            '!flag.modal-add-form-worktime',
        ],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
                showMobile: true,
            },
            {
                text: 'menu.clockcard',
                link: ['timetracker.timecard.add'],
                needs: [
                    'userlevel=1',
                    'worktime',
                    'worktime.starttime@disabled||products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                showMobile: true,
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: [
                    'userlevel=1',
                    'worktime@add',
                    '!worktime.starttime@disabled',
                    '!products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                showMobile: true,
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: ['!userlevel=1', 'worktime@add', 'modulecontrol.worktimes'],
                showMobile: true,
            },
        ],
    },
    {
        // flag
        icon: 'move move-Marker',
        text: 'menu.add_presence',
        link: 'modal',
        query: 'presence',
        id: 'presence',
        needs: [
            'presence@add',
            'serviceCountry@fi',
            'userlevel=1?',
            'flag.modal-add-form-worktime',
        ],
        submenus: [
            {
                text: 'menu.add_presence',
                link: ['presences.add'],
                needs: ['presence@add'],
                showMobile: true,
                modal: 'presence',
            },
            {
                text: 'menu.clockcard',
                link: ['timetracker.timecard.add'],
                needs: [
                    'userlevel=1',
                    'worktime',
                    'worktime.starttime@disabled||products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                showMobile: true,
                modal: 'timecard',
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: [
                    'userlevel=1',
                    'worktime@add',
                    '!worktime.starttime@disabled',
                    '!products.timetracker_hybrid',
                    'modulecontrol.worktimes',
                ],
                showMobile: true,
                modal: 'worktime',
            },
            {
                text: 'menu.add_worktime',
                link: ['timetracker.add'],
                needs: ['!userlevel=1', 'worktime@add', 'modulecontrol.worktimes'],
                showMobile: true,
                modal: 'worktime',
            },
        ],
    },
    {
        // Finnish Presence Links for non Workers
        icon: 'move move-Marker',
        text: 'menu.add_presence',
        link: 'modal',
        query: 'presence',
        id: 'presence',
        needs: ['presence@add', 'serviceCountry@fi', '!userlevel=1?'],
    },
    {
        icon: 'move move-TodoList',
        text: 'menu.add_orientation',
        link: ['orientations.add'],
        id: 'orientation',
        needs: ['orientation@add'],
    },
    {
        icon: 'move move-Length',
        text: 'menu.add_tr',
        link: ['site_safety.measurement.add', 'add', 'tr'],
        id: 'tr',
        needs: ['tr@add'],
    },
    {
        icon: 'move move-Humidity',
        text: 'menu.add_mvr',
        link: ['site_safety.measurement.add', 'add', 'mvr'],
        id: 'mvr',
        needs: ['mvr@add'],
    },
    {
        icon: 'move move-Length',
        text: 'menu.add_skyddsrond',
        link: ['skyddsronds.add'],
        id: 'skyddsrond',
        needs: ['skyddsrond@add'],
    },
    {
        icon: 'move move-Note',
        text: 'menu.add_notice',
        link: ['site_safety.addnotice'],
        id: 'trnotice',
        needs: [
            'trnotice@add',
            'products.measurement_tr||products.measurement_mvr',
            '!userlevel=1',
        ],
    },
    {
        icon: 'move move-Note',
        text: 'menu.add_notice',
        link: 'modal',
        query: 'notice',
        id: 'notice',
        needs: ['notice@add', '!userlevel=1'],
    },
    {
        icon: 'move move-Checklist',
        text: 'menu.add_worktask',
        link: 'modal',
        query: 'worktask',
        id: 'worktask',
        needs: ['worktask@add'],
    },
    {
        icon: 'move move-Users',
        text: 'menu.add_user',
        link: ['users.add'],
        id: 'user',
        needs: ['user@add'],
    },
    {
        //if flag
        icon: 'move move-GeoFence',
        text: 'menu.add_project',
        link: 'modal',
        query: 'project',
        id: 'project',
        needs: ['project@add', 'flag.ember-movenium-form-add-projects'],
    },
    {
        //else
        icon: 'move move-GeoFence',
        text: 'menu.add_project',
        link: ['projects.add'],
        id: 'project',
        needs: ['project@add', '!flag.ember-movenium-form-add-projects'],
    },
    {
        icon: 'move move-Hammer',
        text: 'menu.add_transaction',
        link: ['machines.transaction.project'],
        id: 'transaction',
        needs: ['transaction@add'],
    },
    {
        icon: 'move move-Hammer',
        text: 'menu.machine',
        link: 'modal',
        query: 'tooltype',
        id: 'tooltype',
        needs: ['tooltype@add'],
    },
    {
        icon: 'move move-GeoFence',
        text: 'menu.give_magickey',
        link: ['projects.magickey'],
        id: 'magickey',
        needs: ['!products.generic_timetracker'],
    },
    {
        //if ember-movenium-form-add-employers flag
        icon: 'move move-AdministratorFilled',
        text: 'menu.add_employer',
        link: 'modal',
        query: 'employer',
        id: 'employer',
        needs: ['employer@add', 'flag.ember-movenium-form-add-employers'],
    },
    {
        //else
        icon: 'move move-AdministratorFilled',
        text: 'menu.add_employer',
        link: ['employers.add'],
        id: 'employer',
        needs: ['employer@add', '!flag.ember-movenium-form-add-employers'],
    },
    {
        icon: 'move move-Message',
        text: 'menu.add_message',
        link: ['messageboard.add'],
        id: 'messageboard',
        needs: ['!customersetting.new_messageboard', 'userlevel=5', '!products.admintool'],
    },
    {
        icon: 'move move-Message',
        text: 'menu.add_message',
        link: ['messages.add'],
        id: 'message',
        needs: [
            'customersetting.new_messageboard',
            'userlevel=5||userlevel=6',
            '!products.admintool',
        ],
    },
    {
        //{text: "menu.send_skatteverket", link: ['send_skatteverket'], needs: ['userlevel=5','presence']}
        icon: 'move move-Users',
        text: 'menu.invite_users',
        link: ['invite-users'],
        id: 'invite-users',
        needs: ['userlevel=2,3,4,5,6', '!products.generic_timetracker'],
    },
    {
        // AT2 LINKS
        text: 'menu.add_product',
        link: ['forms.form-add', 'customerproduct'],
        id: 'product',
        needs: ['products.admintool', 'customerproduct@add'],
    },
    {
        text: 'menu.add_campaign',
        link: ['forms.form-add', 'campaign'],
        id: 'campaign',
        needs: ['products.admintool', 'campaign@add'],
    },
    {
        text: 'menu.add_productcampaign',
        link: ['forms.form-add', 'productcampaign'],
        id: 'productcampaign',
        needs: ['products.admintool', 'productcampaign@add'],
    },
    {
        text: 'menu.add_customercampaign',
        link: ['forms.form-add', 'customercampaign'],
        id: 'customercampaign',
        needs: ['products.admintool', 'customercampaign@add'],
    },
    {
        text: 'menu.add_promocode',
        link: ['forms.form-add', 'promocode'],
        id: 'promocode',
        needs: ['products.admintool', 'promocode@add'],
    },
    {
        text: 'menu.add_billrow',
        link: ['forms.form-add', 'bill'],
        id: 'billrow',
        needs: ['products.admintool', 'bill@add', 'development', '!userlevel=7,8'],
    },
    {
        text: 'menu.add_rcbill',
        link: ['forms.form-add', 'rcbill'],
        id: 'rcbill',
        needs: ['products.admintool', 'rcbill@add', 'development', '!userlevel=7,8'],
    },
    {
        text: 'menu.add_timecleandevice',
        link: 'modal',
        query: 'timecleandevice',
        id: 'timecleandevice',
        needs: ['products.admintool', 'timecleandevice@add'],
    },
    {
        text: 'menu.add_promomessage',
        link: ['forms.form-add', 'promomessage'],
        id: 'promomessage',
        needs: ['products.admintool', 'promomessage@add'],
    },
    {
        text: 'menu.createservice',
        link: ['admintool.createservice'],
        id: 'createservice',
        needs: ['products.admintool'],
    },
    {
        text: 'menu.at_billing',
        link: ['admintool.billing'],
        id: 'atbilling',
        needs: ['products.admintool', 'bill@access', '!userlevel=7'],
    },
    {
        text: 'menu.add_group',
        link: 'modal',
        query: 'group',
        id: 'group',
        needs: ['products.admintool', 'userlevel=5'],
    },
    {
        // AT2 LINKS END
        icon: 'move move-Law',
        text: 'menu.add_taxman_contract',
        id: 'taxman_contract',
        link: ['taxman.contract-list.add'],
        needs: ['userlevel=5', 'urakkaraportointi'],
    },
    {
        icon: 'move move-GeoFence',
        text: 'menu.skv_number',
        id: 'skv_number',
        link: 'http://www.skatteverket.se/foretagorganisationer/sjalvservice/blanketterbroschyrer/blanketter/info/1528.4.3810a01c150939e893fba08.html',
        needs: ['userlevel=5', 'products.personalliggareandid06'],
    },
    {
        icon: 'move move-LiteratureFilled',
        text: 'menu.worksitediary.report.add.basic-info',
        id: 'worksitediary-info',
        link: ['worksitediary.report.first'],
        needs: ['diaryreport@add'],
    },
    {
        // if ember-movenium-form-add-siteSafetyObservation flag
        icon: 'move move-Note',
        text: 'menu.add.site_safety_observation',
        link: 'modal',
        id: 'site_safety_observation',
        query: 'site_safety_observation',
        needs: ['site_safety_observation', 'flag.ember-movenium-form-add-siteSafetyObservation'],
    },
    {
        //else
        icon: 'move move-Note',
        text: 'menu.add.site_safety_observation',
        link: ['site_safety.observation.add'],
        id: 'site_safety_observation',
        needs: ['site_safety_observation', '!flag.ember-movenium-form-add-siteSafetyObservation'],
    },
    {
        icon: 'move move-Note',
        text: 'menu.add.site_safety_event',
        link: 'modal',
        query: 'site_safety_event',
        id: 'site_safety_event',
        needs: ['site_safety_event', 'site_safety_event@add'],
    },
    {
        icon: 'move move-Note',
        text: 'menu.add.firecard',
        link: ['firecards.add'],
        id: 'firecard',
        needs: ['firecard@add'],
    },
    {
        icon: 'move move-Marker',
        text: 'menu.add_card',
        link: 'modal',
        query: 'card',
        id: 'card',
        needs: ['card@add', 'userlevel=1?'],
    },
    {
        icon: 'move move-Checklist',
        text: 'menu.add.task',
        link: 'modal',
        query: 'task',
        id: 'task',
        needs: ['task@add', 'userlevel=6,5'],
    },
    {
        icon: 'move move-qr-code',
        text: 'menu.add.qrcode',
        link: 'modal',
        query: 'qrcode',
        id: 'qrcode',
        needs: ['products.qrcode'],
    },
    {
        icon: 'move move-puzzle',
        text: 'menu.add.integration',
        link: 'modal',
        query: 'ccbutton',
        id: 'ccbutton',
        needs: ['ccbutton', 'development'],
    },
];

defaultFirstPages = [
    {
        route: 'forms.form-report@customer',
        needs: ['products.admintool'],
    },
    {
        route: 'presences.add',
        needs: ['userlevel=1', 'presence'],
    },
    {
        route: 'my-sites',
        needs: ['userlevel=3', 'project'],
    },
    {
        route: 'timetracker.timecard.add',
        needs: [
            'userlevel=1',
            'worktime',
            'worktime.starttime@disabled||products.timetracker_hybrid',
        ],
    },
    {
        route: 'timetracker.add',
        needs: [
            'userlevel=1',
            'worktime@add',
            '!worktime.starttime@disabled',
            '!products.timetracker_hybrid',
        ],
    },
    {
        route: 'presences.report',
        needs: ['userlevel=5||userlevel=2', 'presence'],
    },
    {
        route: 'timetracker.worktime-list',
        needs: ['userlevel=5||userlevel=2', 'worktime'],
    },
    {
        route: 'my-sites',
        needs: ['worktime||presence||orientation||tr||mvr'],
    },
];

savedCustomization = {
    worktime: {
        endtime: {
            features: {
                hide: false,
            },
        },
    },
};

branding_needs = {
    whitelist: [
        {
            needs: ['products.generic_timetracker'],
            product_page: [
                'famoco',
                'drivinglog',
                'fivaldi_salary_soap',
                'fivaldi_salary_csv',
                'netvisor_salary_rest',
                'lon600_salary_xml',
                'taskmanagement',
                'plentry',
                'nova_salary_csv',
            ],
        },
    ],
    blacklist: [
        {
            needs: ['!products.generic_timetracker'],
            product_page: ['plentry'],
        },
    ],
};

possibleFirstPages = [
    'dashboard',
    'my-sites.index',
    'timetracker.worktime-list',
    'timetracker.week-report',
    'users.index',
    'projects.index',
    'presences.index',
    'forms.form-report',
];

export default {
    staticMenus: staticMenus,
    addLinkList: addLinkList,
    defaultFirstPages: defaultFirstPages,
    savedCustomization: savedCustomization,
    branding_needs: branding_needs,
    possibleFirstPages: possibleFirstPages,
};
