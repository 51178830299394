import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { debounce, scheduleOnce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import config from 'tt4/config/environment';

export default class TopMenuComponent extends Component {
    @service menu;
    @service intl;
    @tracked topMenu = [];
    @tracked visibleItems = [];
    @tracked hiddenItems = [];
    @tracked currentLocale = this.intl.locale || 'fi'; // Track current locale
    padding = 150; // we have to manually set padding because we don't know the width of the final menu

    constructor() {
        super(...arguments);
        // kind of a hack, but we need to know the padding and margin of the items so we can use it in the menu service for width calculations
        // could be done better, but it works for now
        this.menu.paddingPlusMarginForItem = config.brand === 'otta' ? 40 : 20;
        // we add a resize event listener to the window to recalculate the menu when the screen size changes
        window.addEventListener('resize', this.debouncedResize);
        // Listen for locale change
        this.intl.on('localeChanged', this.handleLocaleChange.bind(this));
        // we need to wait for the component to be rendered before we can calculate the width of the menu container
        // because we use the width of the window and the width of other elements to calculate it
        scheduleOnce('afterRender', this, 'refreshMenu');
    }
    // Called when the locale changes
    handleLocaleChange() {
        this.currentLocale = this.intl.locale; // Update tracked property
        this.refreshMenu(); // Refresh menu to update translations
    }
    // sets the width of the menu container and the padding for each item and uses the menu service to get the menus
    @action
    refreshMenu() {
        this.menu.containerWidth =
            window.innerWidth -
            document.querySelector('.icons-right').offsetWidth -
            document.querySelector('.topmenu-branding-logo').offsetWidth -
            this.padding;
        this.topMenu = this.menu.getMenus();
    }

    //make sure to remove the event listener when the component is destroyed
    willDestroy() {
        super.willDestroy(...arguments);
        window.removeEventListener('resize', this.debouncedResize);
    }

    // when screen size is changed, debounce the resize event to prevent multiple calls
    @action
    debouncedResize() {
        debounce(this, this.refreshMenu, 150);
    }

    get styleNamespace() {
        return podNames['top-menu'];
    }
}
