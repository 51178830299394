export function getCommunityLink(brand, companyInfo) {
    // eslint-disable-next-line no-irregular-whitespace
    if (brand == 'otta') return 'https://ohjeet.visma.fi/articles/#!otta/k-ytt-ohjeet';

    if (!companyInfo) return false;
    let country = companyInfo.country;

    switch (country) {
        case 'se':
            return 'https://community.visma.com/t5/Support-Visma-Construction/bd-p/SE_SW_Visma_Construction_support';
        case 'fi':
            return 'https://ohjeet.visma.fi/articles/#!visma-movenium/k-ytt-ohjeet';
        default:
            return 'https://ohjeet.visma.fi/articles/#!visma-movenium/k-ytt-ohjeet';
    }
}
export default { getCommunityLink };
