import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { set } from '@ember/object';
import { htmlSafe } from '@ember/string';
import { isEmpty } from '@ember/utils';

export default Component.extend({
    session: service(),
    Collector: service('collector-service'),
    capi: service(),
    ttapi: service(),
    intl: service(),
    router: service(),
    store: service(),
    vastuuGroup: service('vastuu-group'),

    init() {
        this._super(...arguments);
        this.found_employers = [];
        this.not_found_employers = [];
        this.needs = ['employers/add'];
        this.empArr = A([]);
        this.no_employers = false;
        this.userAlreadyExists = false;
        this.messageStatus = null;
        this.message = null;
        this.create = false;
        this.users = null;
        this.noEmployer = false;
        this.activeUser = null;
    },

    actions: {
        async fetchTax() {
            set(this, 'empArr', A([]));
            if (!this.taxnumber || this.taxnumber.length !== 12) {
                set(this, 'message', null);
                set(this, 'messageStatus', null);
                return;
            }
            set(this, 'loading', true);
            let user = await this.store.query('user', { taxnumber: this.taxnumber });
            if (user.length === 0) {
                let userExists = await this.checkIfUserExists(this.taxnumber);
                if (userExists) {
                    set(this, 'loading', false);
                    set(this, 'userAlreadyExists', true);
                } else {
                    set(this, 'userAlreadyExists', false);
                    try {
                        set(this, 'loading', true);
                        let request = await this.vastuuGroup.request('tvpersonapi',
                            {
                                country: 'fi',
                                'id-country': 'fi',
                                'id-type': 'tax_number',
                                'id-value': this.taxnumber,
                            }
                        );
                        this.handleRequestMessage(request);
                    } catch (Exception) {
                        set(this, 'loading', false);
                        set(this, 'messageStatus', 'alert-danger');
                        let message = htmlSafe(
                            '<div>' +
                                '<h3><b>' +
                                this.intl.t('user.add.status.failed') +
                                '</b></h3></div>',
                        );
                        set(this, 'message', message);
                    }
                }
            } else {
                set(this, 'loading', false);
                set(this, 'messageStatus', 'alert-danger');
                let message = htmlSafe(
                    '<div>' + '<h3><b>' + this.intl.t('user.add.exists') + '</b></h3></div>',
                );
                set(this, 'message', message);
            }
        },

        async createUser(user, employer) {
            if (
                user.homecountry !== 'fi' &&
                employer.employment_periods[0].address_in_home_country
            ) {
                user.address = employer.employment_periods[0].address_in_home_country.address[0];
                user.zip = employer.employment_periods[0].address_in_home_country.post_code;
                user.city = employer.employment_periods[0].address_in_home_country.post_area;
                user.contract_type = employer.employment_periods[0].contract_type;
                user.workpass = employer.employment_periods[0].documentation_of_sent_employee;
            }
            set(this, 'activeUser', user);
            set(this, 'users', null);
            set(this, 'message', null);

            if (this.Collector.testNeeds(['employer@access'])) {
                let employers = await this.store.query('employer', {
                    emp_id: employer.gov_org_ids[0].gov_org_id,
                });
                if (employers.length > 0) {
                    user.employer = employers.firstObject;
                    this.setModel(user, ['status', 'vatin']);
                    set(this, 'message', null);
                    set(this, 'users', null);
                    this.addUser(user);
                } else {
                    set(this, 'messageStatus', 'alert-warning');
                    set(this, 'noEmployer', true);
                    let message = htmlSafe(
                        '<div>' +
                            '<h3><b>' +
                            this.intl.t('user.add.status.employer.notfound') +
                            '</b></h3><h4>' +
                            user.employer +
                            ', ' +
                            user.vatin +
                            '</h4></div>',
                    );

                    set(this, 'message', message);
                }
            } else {
                user.employer = this.session.currentUser.employer;
                this.setModel(user, ['status', 'vatin']);
                set(this, 'message', null);
                set(this, 'users', null);
                this.addUser(user);
            }
        },

        async addEmployer(employer, id) {
            let emp_obj = {
                emp_id: id.gov_org_id,
                name: employer.name,
                emp_firstname: employer.employers_contact_person.contact_first_name,
                emp_lastname: employer.employers_contact_person.contact_last_name,
                emp_phone: employer.employers_contact_person.contact_phone,
                emp_email: employer.employers_contact_person.contact_email,
            };

            if (employer.country !== 'FI') {
                emp_obj.emp_id_type = id.gov_org_id === 'vat_number' ? '1' : '2';
                emp_obj.emp_homecountry = employer.country.toLowerCase();

                if (employer.address) {
                    emp_obj.emp_address = employer.address.address.toString();
                    emp_obj.emp_zip = employer.address.post_code;
                    emp_obj.emp_city = employer.address.post_area;
                    emp_obj.emp_country = employer.address.country.toLowerCase();
                }
            }

            let emp = this.store.createRecord('employer', emp_obj);

            set(this.user, 'employer', emp);

            this.store.createRecord('user', this.user);
            let newRoute = await this.router.transitionTo('employers.add');
            set(newRoute.controller, 'afterSave', this.afterSave);
        },

        async addUserToGroup(taxnumber) {
            set(this, 'loading', true);
            let data = await this.ttapi._post('addusertogroup', { taxnumber: taxnumber });
            set(this, 'loading', false);
            let user = await this.store.findRecord('user', data.user.id);
            this.addUser(user);
        },

        setUserTaxnumber(taxnumber) {
            let user = {
                taxnumber: taxnumber,
            };
            this.setModel(user, ['status', 'vatin']);
            this.addUser(user);
        },
    },
    setModel(data, skip) {
        for (let k in data) {
            if (skip.includes(k)) continue;
            set(this.model, k, data[k]);
        }
    },

    setUserData(data) {
        let user_record = {
            firstname: data.names[0].given_names[0],
            lastname: data.names[0].surnames.join(' '),
            birthdate: data.date_of_birth,
            taxnumber: data.gov_ids[0].gov_id,
            homecountry: data.residences ? data.residences[0].toLowerCase() : 'fi',
        };

        set(this, 'user', user_record);
    },

    async checkIfUserExists(taxnumber) {
        try {
            await this.capi.request('/users?validation_only=taxnumber', {
                method: 'POST',
                data: { user: { taxnumber: taxnumber } },
            });
            return false;
        } catch (reason) {
            if (
                reason.payload.error_msg ==
                'user with this ' + taxnumber + ' taxnumber already exists!'
            ) {
                return true;
            } else {
                return false;
            }
        } finally {
            set(this, 'loading', false);
        }
    },
    async getImage() {
        let data = await this.vastuuGroup.request('tvcardimage',
            { taxnumber: this.model.taxnumber, emp_id: this.model.employer.emp_id }
        );
        if (data && !data.error) {
            set(this, 'model.image', [
                {
                    file: 'data:image/png;base64,' + data,
                    name: 'profile.png',
                },
            ]);
            set(this, 'fetchedImage', 'data:image/png;base64,' + data);
        }
    },

    handleRequestMessage(data) {
        let message = '';
        set(this, 'message', message);
        if (data.message) {
            set(this, 'loading', false);
            set(this, 'messageStatus', 'alert-danger');
            message = htmlSafe(
                '<div>' + '<h3><b>' + this.intl.t('user.add.status.notfound') + '</b></h3></div>',
            );
            set(this, 'message', message);
        } else if (!data.error) {
            if (isEmpty(data.employers)) {
                set(this, 'loading', false);
                set(this, 'messageStatus', 'alert-danger');
                message = htmlSafe(
                    '<div>' + '<h3><b>' + this.intl.t('user.add.no_employers') + '</b></h3></div>',
                );
                set(this, 'message', message);
            }
            this.setUserData(data);
            let accessError = false;

            data.employers.forEach((emp) => {
                let found = [];
                let not_found = [];

                let emp_id = emp.gov_org_ids[0].gov_org_id;
                if (emp.country !== 'FI' && emp.gov_org_ids[1]) {
                    emp_id = emp.gov_org_ids[1].gov_org_id;
                }
                if (this.Collector.testNeeds(['employer@access'])) {
                    emp.gov_org_ids.forEach(async (gov_ids) => {
                        let employers = await this.store.query('employer', {
                            emp_id: gov_ids.gov_org_id,
                        });
                        if (employers.length > 0) {
                            found.pushObject(gov_ids);
                        } else {
                            not_found.pushObject(gov_ids);
                        }
                    });

                    this.empArr.pushObject({ employer: emp, found: found, not_found: not_found });
                } else if (this.session.currentUser.employer.emp_id === emp_id) {
                    emp.gov_org_id = emp_id;
                    this.empArr.pushObject({
                        employer: emp,
                        found: this.session.currentUser.employer,
                        not_found: [],
                    });
                } else {
                    accessError = true;
                }
            });
            if (accessError && this.found_employers.length === 0) {
                set(this, 'messageStatus', 'alert-danger');
                message = htmlSafe(
                    '<div>' +
                        '<h3><b>' +
                        this.intl.t('user.add.no_employer_access') +
                        '</b></h3></div>',
                );
                set(this, 'message', message);
            }
        } else {
            this.setUserInfo(data, false);
            set(this, 'loading', false);
        }
        set(this, 'loading', false);
    },
});
