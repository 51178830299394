import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { set, get } from '@ember/object';

export default Component.extend({
    ttapi: service(),
    flags: service(),

    tagName: '',

    async didInsertElement() {
        set(this, 'loading', true);
        let user = get(this, 'data');
        const year = get(this, 'year');

        // old data
        const url = 'api/getUserHolidaySummary?start=';

        const oldArr = await get(this, 'ttapi').request(
            url +
                year +
                '-05-02&id=' +
                user[0]['id'] +
                (this.isHolidayReport ? '&absenceType=vacation' : ''),
        );

        // current data
        const currentYear = +year + 1;

        const currentArr = await get(this, 'ttapi').request(
            url +
                currentYear +
                '-05-02&id=' +
                user[0]['id'] +
                (this.isHolidayReport ? '&absenceType=vacation' : ''),
        );

        //const data = await get(this, 'data');
        //let currentArr = data[moment(year+"-01-01").add(1, 'year').format("YYYY")];
        //let oldArr = data[year];

        let currentDays = currentArr['abcenses'].filter((row) => {
            return row['lastseason'] != 0;
        });

        // Sort current days according to startdate field
        currentDays = currentDays.sort((a, b) => {
            return new Date(a['startdate']) < new Date(b['startdate']) ? 1 : -1;
        });

        let oldDays = oldArr['abcenses'].filter((row) => {
            return row['thisseason'] != 0;
        });

        // Sort old days according to startdate field
        oldDays = oldDays.sort((a, b) => {
            return new Date(a['startdate']) < new Date(b['startdate']) ? 1 : -1;
        });

        let bookedOldDays = [];
        let bookedCurrentDays = [];
        let totalHolidays = [];
        let totals = [];

        let totalOldHolidays = [];
        let totalCurrentHolidays = [];

        for (var oldrow of oldDays) {
            if (get(oldrow, 'startdate') > moment().format('YYYY-MM-DD')) {
                bookedOldDays.pushObject(oldrow);
                //console.log("added "+ get(oldrow, 'startdate')+"_"+get(oldrow,'enddate')+" to OLD booked")
            } else {
                totalOldHolidays.pushObject(oldrow);
            }
        }
        for (var currentRow of currentDays) {
            set(currentRow, 'current', true);
            if (get(currentRow, 'startdate') > moment().format('YYYY-MM-DD')) {
                //console.log("added "+ get(currentRow, 'startdate')+"_"+get(currentRow,'enddate')+" to CURRENT booked")
                bookedCurrentDays.pushObject(currentRow);
            } else {
                totalCurrentHolidays.pushObject(currentRow);
            }
        }

        const totals1 = totalOldHolidays.reduce((total, row) => total + row.thisseason, 0);
        const totals2 = totalCurrentHolidays.reduce((total, row) => total + row.lastseason, 0);

        totals = totals1 + totals2;

        totalHolidays = totalOldHolidays.concat(totalCurrentHolidays);

        const bookedSum1 = bookedOldDays.reduce((total, row) => total + row.thisseason, 0);
        const bookedSum2 = bookedCurrentDays.reduce((total, row) => total + row.lastseason, 0);

        set(this, 'totalHolidays', totalHolidays);
        set(this, 'bookedThisSeason', bookedOldDays.concat(bookedCurrentDays));
        set(this, 'totalSums', totals);

        set(this, 'bookedSum', bookedSum1 + bookedSum2);

        set(this, 'loading', false);
    },
});
