import Component from '@ember/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
    store: service(),

    init() {
        this._super();
        this.load();
    },

    async load() {
        await this.store.query('user', { id: this.data.mapBy('saldos.userid').join(','), limit: 'all' });

        const over = [];
        const under = [];
        for (let row of this.data) {
            const user = this.store.peekRecord('user', row.saldos.userid);
            const add = {
                name: `${user.firstname} ${user.lastname}`,
                saldo: this.toHoursAndMinutes(parseInt(row.saldos.saldo, 10)),
            };
            if (parseInt(row.saldo, 10) > 0) over.push(add);
            else under.push(add);
        }

        set(this, 'over', over);
        set(this, 'under', under);
    },

    toHoursAndMinutes(minutes) {
        const negative = minutes < 0;
        minutes = Math.abs(minutes);
        let hours = Math.floor(minutes / 60);
        let minutesLeft = Math.floor(minutes - hours * 60);

        return (
            (negative ? '-' : '') + hours + ':' + (minutesLeft < 10 ? '0' : '') + minutesLeft + 'h'
        );
    },
});
