/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/add/builder/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersAddBuilderController,
    indexOf = [].indexOf;

import Ember from 'ember';

import config from '../../../../config/environment';

// veronumero for testing 100011258537
UsersAddBuilderController = Ember.Controller.extend({
    //    needs: ["employers/add"]
    session: Em.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    addController: Ember.inject.controller('employers/add'),
    capi: Ember.inject.service(),
    ttapi: Ember.inject.service(),
    intl: Ember.inject.service(),
    cc: Ember.inject.service(),
    store: Ember.inject.service(),
    vastuuGroup: Ember.inject.service('vastuu-group'),
    found_employers: [],
    not_found_employers: [],
    empArr: Em.A([]),
    no_employers: false,
    userAlreadyExists: false,
    veroApiInfoExists: false,
    birthday: null,
    birthdateField: null,
    obs: function () {
        this.set('messageStatus', null);
        this.set('message', null);
        this.set('create', false);
        this.set('users', null);
        this.set('noEmployer', false);
        this.set('userAlreadyExists', false);
        this.set('activeUser', null);
        this.set('found_employers', []);
        this.set('not_found_employers', []);
        this.set('no_employers', false);
        if (this.get('taxnumber.length') === 12) {
            return this.send('fetchTax');
        } else if (this.get('cardnumber.length') === 12) {
            return this.send('fetchCard');
        } else if (this.get('taxnumber.length') === 14) {
            return this.send('fetchCardWithUID');
        }
    }.observes('taxnumber', 'cardnumber'),
    setModel: function (data, skip) {
        var k, model, results, v;
        model = this.get('model');
        results = [];
        for (k in data) {
            v = data[k];
            if (indexOf.call(skip, k) < 0) {
                results.push(model.set(k, v));
            } else {
                results.push(void 0);
            }
        }
        return results;
    },
    //    setUserInfo: (data, skip) ->
    //        intl = @get 'intl'
    //        if !data.error
    //            @setModel(data,skip)
    //            if data.cardStatus == 'closed' && data.status == 'verified'
    //                @set 'messageStatus', 'alert-warning'
    //            else if data.status == 'verified'
    //                @set 'messageStatus', 'alert-success'
    //            else
    //                @set 'messageStatus', 'alert-danger'
    //            string = ''
    //            if data.status
    //                string += '<div><h3>'+intl.t('user.add.status.taxnumber')+': <b>'+intl.t('user.add.status.'+data.status)+'</b></h3></div>'

    //            if data.cardStatus == 'closed'
    //               string += '<div><h3><b>'+intl.t('user.add.card.closed')+'</b></h3><b>'+intl.t('user.add.card.reason')+': '+data.cardReason+'</b></div>'
    //            message = Ember.String.htmlSafe(string)
    //        else
    //            @set 'messageStatus', 'alert-danger'
    //            message = Ember.String.htmlSafe('<div>'+
    //                '<h3><b>'+intl.t('user.add.status.notfound')+'</b></h3></div>')
    //        @set 'message', message
    setUserData: function (data) {
        var user_record;
        if (data.names) {
            user_record = {
                firstname: data.names[0].given_names[0],
                lastname: data.names[0].surnames.join(' '),
                birthdate: data.date_of_birth,
                taxnumber: data.gov_ids[0].gov_id,
                homecountry: data.residences != null ? data.residences[0].toLowerCase() : 'fi',
            };
            if (this.get('cardUidUsed')) {
                user_record.rfid_tag_id = this.get('cardUidUsed');
            }
            return this.set('user', user_record);
        }
    },
    checkIfUserExists: function (taxnumber) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            return this.get('capi')
                .request('/users?validation_only=taxnumber', {
                    method: 'POST',
                    data: {
                        user: {
                            taxnumber: taxnumber,
                        },
                    },
                })
                .then(
                    () => {
                        return resolve(false);
                    },
                    (reason) => {
                        if (
                            reason.payload.error_msg ===
                            'user with this ' + taxnumber + ' taxnumber already exists!'
                        ) {
                            return resolve(true);
                        } else {
                            return resolve(false);
                        }
                    },
                );
        });
    },
    queryVeroApi: function (number, birthday) {
        var isNumber, query;
        isNumber = /^\d+$/.test(number);
        if (!number || !isNumber || number.length !== 12) {
            return;
        }
        query = '/veroapi-veronumero?taxnumber=' + number;
        if (birthday) {
            query += '&birthday=' + birthday;
        }
        return $.ajax({
            url: this.get('cc').host + query,
            headers: {
                Authorization: 'Bearer ' + this.session.data.authenticated.access_token,
            },
            success: (data) => {
                this.set('veroApiInfoExists', data.InfoExists);
                if (data.InfoExists) {
                    this.set('veroApiUser', data);
                }
                if (data.InfoExists && birthday && !data.TaxNumberMatchBirthday) {
                    return this.set('fetchedWithWrongBirthday', true);
                }
            },
        });
    },
    getImage: function (field) {
        var emp_id, taxnumber;
        //        @set 'loading', true
        taxnumber = this.get('model.taxnumber');
        emp_id = this.get('model.employer.emp_id');
        return $.ajax({
            url: this.get('cc').host + config.APP.CC_TVCARDIMAGE,
            data: {
                taxnumber: taxnumber,
                emp_id: emp_id,
            },
            success: (data) => {
                //                @set 'loading', false
                if (data != null && !data.error) {
                    this.set('model.image', [
                        {
                            file: 'data:image/png;base64,' + data,
                            name: 'profile.png',
                        },
                    ]);
                    return this.set('fetchedImage', 'data:image/png;base64,' + data);
                }
            },
        });
    },
    setMessage: function (status, messageKey) {

        this.set('messageStatus', status);

        const message = Ember.String.htmlSafe(
            '<div>' +
                '<h3><b>' +
                this.intl.t(messageKey) +
                '</b></h3></div>',
        );

        this.set('message', message);
    },
    actions: {
        setInitialValue: function () {
            if (!this.get('birthday')) {
                return this.set('birthday', '1980-06-01');
            }
        },
        fetchPersonFromTaxApi: function () {
            //            console.log(@get('taxnumber'), @get('birthday'))
            this.set('fetchedWithWrongBirthday', false);
            return this.queryVeroApi(this.get('taxnumber'), this.get('birthday'));
        },
        // fetch card taxnumber from vgpersonapi integration and if card is found
        // replace the taxnumber and taxnumber search starts automatically
        fetchCardWithUID: async function () {
            try {
                this.set('loading', true);
                this.set('cardUidUsed', null);
                const uid = this.get('taxnumber');

                const response = await this.vastuuGroup.request('vgpersonapi',
                    {
                        country: 'FI',
                        'id-country': 'FI',
                        'id-type': 'card_uid',
                        'id-value': uid,
                    },
                );

                if (response.length > 0 && response[0].taxnumber) {
                    this.set('cardUidUsed', uid);
                    this.set('taxnumber', response[0].taxnumber);
                } else {
                    this.set('loading', false);
                }
            } catch (e) {
                console.error(e);
                this.set('loading', false);
            }
        },
        fetchTax: async function () {
            const c = this.get('Collector');
            this.set('empArr', Em.A([]));

            if (this.get('taxnumber') && this.get('taxnumber.length') === 12) {
                const value = this.get('taxnumber');
                this.set('loading', true);

                try {

                    const users = await this.store.query('user', {
                        taxnumber: value,
                    });

                    const veroapipromise = this.queryVeroApi(value);

                    if (users.get('length') === 0) {
                        const userExists = await this.checkIfUserExists(value);

                        if (userExists) {
                            this.set('loading', false);
                            return this.set('userAlreadyExists', true);
                        } else {
                            this.set('userAlreadyExists', false);

                            try {
                                const data = await this.vastuuGroup.request(config.APP.CC_TVPERAPI,
                                    {
                                        country: 'FI',
                                        'id-country': 'FI',
                                        'id-type': 'tax_number',
                                        'id-value': value,
                                    }
                                );

                                if (data.message) {
                                    this.set('loading', false);
                                    this.setMessage('alert-danger', 'user.add.status.notfound');
                                } else if (!data.error) {
                                    if (Em.isEmpty(data.employers)) {
                                        this.set('loading', false);
                                        this.setMessage('alert-danger', 'user.add.no_employers');
                                    } else {
                                        this.setUserData(data);
                                        let accessError = false;
                                        const promises = data.employers.map(async (emp) => {
                                            const found = [];
                                            const not_found = [];
                                            let emp_id = emp.gov_org_ids[0].gov_org_id;

                                            if (emp.country !== 'FI' && emp.gov_org_ids[1]) {
                                                emp_id = emp.gov_org_ids[1].gov_org_id;
                                            }

                                            if (c.testNeeds(['employer@access'])) {
                                                await Promise.all(
                                                    emp.gov_org_ids.map(async (gov_ids) => {
                                                        const employers = await this.get('store').query(
                                                            'employer',
                                                            { emp_id: gov_ids.gov_org_id },
                                                        );
                                                        if (employers.get('length') > 0) {
                                                            found.pushObject(gov_ids);
                                                        } else {
                                                            not_found.pushObject(gov_ids);
                                                        }
                                                    }),
                                                );

                                                this.get('empArr').pushObject({
                                                    employer: emp,
                                                    found,
                                                    not_found,
                                                });
                                            } else if (
                                                this.get('session.currentUser.employer.emp_id') === emp_id
                                            ) {
                                                emp.gov_org_id = emp_id;
                                                this.get('empArr').pushObject({
                                                    employer: emp,
                                                    found: [
                                                        emp.gov_org_ids.find(
                                                            (item) => item.gov_org_id === emp_id,
                                                        ),
                                                    ],
                                                    not_found: [],
                                                });
                                            } else {
                                                accessError = true;
                                            }
                                        });

                                        if (accessError && this.get('found_employers').length === 0) {
                                            this.set('messageStatus', 'alert-danger');
                                            this.setMessage('alert-danger', 'user.add.no_employer_access');
                                        }

                                        await Promise.all(promises);
                                        this.set('loading', false);
                                    }
                                } else {
                                    this.set('loading', false);
                                }
                            } catch (error) {
                                this.set('loading', false);
                                this.setMessage('alert-danger', 'user.add.status.failed');
                            }
                        }
                    } else {
                        this.set('loading', false);
                        this.setMessage('alert-danger', 'user.add.exists');
                    }
                } catch (error) {
                    this.set('loading', false);
                    this.setMessage('alert-danger', 'user.add.status.failed');
                }
            }
        },
        createUser: function (user, employer, found_employer) {
            var c, intl, self;
            self = this;
            if (
                user.homecountry !== 'fi' &&
                employer.employment_periods[0].address_in_home_country
            ) {
                user.address = employer.employment_periods[0].address_in_home_country.address[0];
                user.zip = employer.employment_periods[0].address_in_home_country.post_code;
                user.city = employer.employment_periods[0].address_in_home_country.post_area;
                user.contract_type = employer.employment_periods[0].contract_type;
                user.workpass = employer.employment_periods[0].documentation_of_sent_employee;
            }
            this.set('activeUser', user);
            this.set('users', null);
            this.set('message', null);
            intl = this.get('intl');
            c = this.get('Collector');
            if (c.testNeeds(['employer@access'])) {
                return self.store
                    .query('employer', {
                        emp_id: found_employer.gov_org_id,
                    })
                    .then(function (employers) {
                        var message;
                        if (employers.get('length') > 0) {
                            user.employer = employers.get('firstObject');
                            self.setModel(user, ['status', 'vatin']);
                            self.set('message', null);
                            self.set('users', null);
                            return self.transitionToRoute('users.add.index');
                        } else {
                            self.set('messageStatus', 'alert-warning');
                            self.set('noEmployer', true);
                            message = Ember.String.htmlSafe(
                                '<div>' +
                                    '<h3><b>' +
                                    intl.t('user.add.status.employer.notfound') +
                                    '</b></h3><h4>' +
                                    user.employer +
                                    ', ' +
                                    user.vatin +
                                    '</h4></div>',
                            );
                            return self.set('message', message);
                        }
                    });
            } else {
                user.employer = self.get('session.currentUser.employer');
                self.setModel(user, ['status', 'vatin']);
                self.set('message', null);
                self.set('users', null);
                return self.transitionToRoute('users.add.index');
            }
        },
        addEmployer: function (employer, id) {
            var emp, emp_obj, user;
            user = this.get('user');
            emp_obj = {
                emp_id: id.gov_org_id,
                name: employer.name,
                emp_firstname: employer.employers_contact_person.contact_first_name,
                emp_lastname: employer.employers_contact_person.contact_last_name,
                emp_phone: employer.employers_contact_person.contact_phone,
                emp_email: employer.employers_contact_person.contact_email,
            };
            if (employer.country !== 'FI') {
                //if employer.gov_org_ids[1]
                //    emp_obj.emp_id = employer.gov_org_ids[1].gov_org_id
                if (id.gov_org_id === 'vat_number') {
                    emp_obj.emp_id_type = '1';
                } else {
                    emp_obj.emp_id_type = '2';
                }
                emp_obj.emp_homecountry = employer.country.toLowerCase();
                if (employer.address) {
                    emp_obj.emp_address = employer.address.address.toString();
                    emp_obj.emp_zip = employer.address.post_code;
                    emp_obj.emp_city = employer.address.post_area;
                    emp_obj.emp_country = employer.address.country.toLowerCase();
                }
            }
            emp = this.store.createRecord('employer', emp_obj);
            Em.set(user, 'employer', emp);
            this.store.createRecord('user', user);
            this.set('addController.afterSave', 'users.add.index');
            this.transitionToRoute('employers.add');
            return this.set('noEmployer', false);
        },
        addUserToGroup: function (taxnumber) {
            this.set('loading', true);
            return this.get('ttapi')
                ._post('addusertogroup', {
                    taxnumber: taxnumber,
                })
                .then(() => {
                    this.set('loading', false);
                    return this.transitionToRoute('users.index');
                });
        },
        setUserTaxnumber: function (taxnumber) {
            var user;
            user = {
                taxnumber: taxnumber,
            };
            if (this.veroApiUser) {
                user.firstname = this.veroApiUser.FirstName;
                user.lastname = this.veroApiUser.LastName;
                user.birthdate = this.birthday;
            }
            this.setModel(user, ['status', 'vatin']);
            return this.transitionToRoute('users.add.index');
        },
    },
});

export default UsersAddBuilderController;
