import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
export default class AnalyticsService extends Service {
    @service private flags: any;
    @tracked customData: any[] = [];

    public trackEvent(event: TrackingEvent) {
        if (typeof window.snowplow === 'function' && this.flags.test('use-snowplow')) {
            window.snowplow('trackStructEvent', event);
            // empty customData after tracking
            this.customData = [];
        }
    }
    // function to init customData (so we can call it from components)
    public initCustomData() {
        this.customData = [];
    }
}
