/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/login.coffee.old
// Generated by CoffeeScript 2.6.1
// app/controllers/login.js
var LoginController;

import Ember from 'ember';

import config from '../config/environment';

import collector from '../classes/collector';

LoginController = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    application: Ember.inject.controller(),
    currentMobile: false,
    localStorageWrapper: Ember.inject.service(),
    customerSettings: Ember.inject.service(),
    initapp: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    gps: Ember.inject.service(),
    intl: Ember.inject.service(),
    gui: Ember.inject.service(),
    isTrackingAllowed: true,
    init: function () {
        if (this.get('session.session.isAuthenticated')) {
            this.transitionToRoute('my-sites');
        }
        this._super();
        if (this.get('gui').isIOS) {
            return this.askTrackingPermissions();
        }
    },
    isOtta: Em.computed(function () {
        if (config.brand === 'otta') {
            return true;
        }
        return false;
    }),
    logoUrl: Ember.computed(function () {
        if (config.brand === 'visma') {
            return config.APP.CDN_URL + '/images/entry/logo_nega.png';
        } else if (config.brand === 'otta') {
            return config.APP.CDN_URL + '/images/otta/logo_nega.png';
        }
        return config.APP.CDN_URL + '/images/movenium/logo_nega.png';
    }),
    googlessourl: Ember.computed(function () {
        return config.APP.GOOGLESSOURL;
    }),
    vismassourl: Ember.computed(function () {
        return config.APP.VISMASSOURL;
    }),
    isSeasonTheme: Em.computed(function () {
        return this.get('application.isSeasonTheme');
    }),
    loginLink: Ember.computed('devModeOn', function () {
        if (this.get('Collector').testNeeds(['development'])) {
            return 'register.index';
        } else {
            return 'register.user.code';
        }
    }),
    showRegisterLink: Ember.computed('hideRegister', function () {
        if (this.get('hideRegister')) {
            return false;
        }
        if (config.brand !== 'default') {
            return false;
        }
        return true;
    }),
    showSavedUsername: Ember.on('init', function () {
        var ls, username;
        ls = this.get('localStorageWrapper');
        username = ls.getSavedUsername();
        return this.set('identification', username);
    }),
    observeIdentification: Ember.observer('identification', function () {
        var ls, username;
        username = this.get('identification');
        ls = this.get('localStorageWrapper');
        if (username === '') {
            return ls.setSavedUsername('');
        }
    }),
    mobile_devices: Ember.computed(function () {
        var devices, obj, re;
        devices = Em.A([]);
        if (typeof cordova === 'undefined' || cordova === null) {
            for (obj in config.mobile_links) {
                re = new RegExp(config.mobile_links[obj].device_identifier, 'i');
                if (navigator.userAgent.match(re)) {
                    devices.pushObject(config.mobile_links[obj]);
                }
            }
        }
        return devices;
    }),
    isCordova: Ember.computed(function () {
        return typeof cordova !== 'undefined' && cordova !== null;
    }),
    modal_title: Ember.computed(function () {
        return this.get('intl').t('general.get_app');
    }),
    askTrackingPermissions: function () {
        var fail, self, win;
        self = this;
        win = function (res) {
            if (res === 2) {
                self.set('isTrackingAllowed', false);
            }
            if (res === 3) {
                self.set('isTrackingAllowed', true);
            }
        };
        fail = function (res) {
            self.set('isTrackingAllowed', false);
        };
        return window.cordova.exec(win, fail, 'idfa', 'requestPermission', []);
    },
    create_account_link: Ember.computed('isOtta', function () {
        var browser, country;
        if (this.get('isOtta')) {
            return 'https://www.otta.fi/ei-tilia';
        }
        browser = window.navigator.userLanguage || window.navigator.language;
        country = browser.substring(0, 2).toLowerCase();
        if (country === 'sv') {
            return 'https://www.visma.se/tid-och-projekthantering/byggbranschen/';
        }
        return 'https://www.movenium.fi/ei-tilia';
    }),
    actions: {
        vismassologin: function () {
            let url = this.get('vismassourl');
            window.open(url, '_system');
        },
        authenticate: function () {
            var data, ls, self;
            this.get('initapp').clearInit();
            self = this;
            if (this.get('showCaptcha') && !this.get('reCaptchaResponse')) {
                this.set('errorMessage', this.get('intl').t('login.recaptcha.unresolved'));
                return;
            }
            ls = this.get('localStorageWrapper');
            data = this.getProperties('identification', 'password');
            if (!data.identification || !data.password) {
                this.set('errorMessage', this.get('intl').t('login.give_username_and_password'));
                this.set('isAuthenticating', false);
                return;
            }
            this.set('isAuthenticating', true);
            this.set('errorMessage', null);
            return this.get('session')
                .authenticate(
                    'authenticator:collector-auth',
                    data.identification.trim(),
                    data.password.trim(),
                    self.get('reCaptchaResponse'),
                )
                .then(() => {
                    self.set('isAuthenticating', false);
                    ls.setSavedUsername(data.identification);
                    return this.get('initapp').start();
                })
                .catch(function (resp) {
                    var error;
                    //simple-auth 3.0 responds errors according to ember-fetch so check if this is the case
                    if (resp.responseJSON) {
                        resp = resp.responseJSON;
                    }
                    self.set('isAuthenticating', false);
                    if (!resp) {
                        self.set(
                            'errorMessage',
                            self.get('intl').t('login.failed') +
                                '<br>' +
                                self.get('intl').t('login.network_connection_error'),
                        );
                        return;
                    }
                    error = resp.error;
                    if (resp.captcha_needed) {
                        self.set('showCaptcha', true);
                        if (self.get('gRecaptcha')) {
                            self.get('gRecaptcha').resetReCaptcha();
                        }
                    }
                    // if google reCaptcha validation fails for some reason (normally caused by it just timing out because the user is slow)
                    if (resp.message.error && resp.message['error-codes']) {
                        self.set('showCaptcha', true);
                        self.set(
                            'errorMessage',
                            resp.message.error + ' ' + resp.message['error-codes'],
                        );
                    }
                    if (!isNaN(error)) {
                        return self.set('errorMessage', resp.error_description);
                    } else {
                        return self.set('errorMessage', self.get('intl').t('login.' + error));
                    }
                });
        },
        setLowercased: function () {
            return this.set('identification', this.get('identification').toLowerCase());
        },
        testcaps: function (value, e) {
            var IS_MAC, capsLock, key, shiftKey;
            IS_MAC = /Mac/.test(navigator.platform);
            key = e.originalEvent.key;
            if (key === 'CapsLock' && typeof this.get('capsOn') !== 'undefined') {
                this.toggleProperty('capsOn');
            }
            if (!key || key.length > 1) {
                return;
            }
            shiftKey = e.originalEvent.shiftKey;
            if (key >= 'A' && key <= 'Z') {
                capsLock = !shiftKey;
            } else if (key >= 'a' && key <= 'z' && !(shiftKey && IS_MAC)) {
                capsLock = shiftKey;
            } else {
                return false;
            }
            this.set('capsOn', capsLock);
            return false;
        },
    },
});

export default LoginController;
