import { get, set } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { getScriptsInOrder } from 'tt4/utils/getScript';
import config from '../../../config/environment';

/* global reactComponents */

export default Component.extend({
    cdn: service(),

    unmountMethod: null,

    async didReceiveAttrs() {
        let url;
        switch (config.target) {
            case 'development':
                url = 'http://localhost:9010/reactComponents.development.js';
                break;
            case 'release-next':
            case 'next':
                url = this.cdn.url('react/reactComponents.next.js');
                break;
            default:
                url = this.cdn.url('react/reactComponents.production.js');
                break;
        }

        await getScriptsInOrder([url]);

        const unmountMethod = reactComponents.render(this.comp, this.element);
        set(this, 'unmountMethod', unmountMethod);
    },

    willDestroyElement() {
        const unmountMethod = get(this, 'unmountMethod');
        if (unmountMethod) {
            unmountMethod();
        }
    },
});
