/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/users/add/index/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var UsersAddIndexController,
    indexOf = [].indexOf;

import Ember from 'ember';

import GeneralAddController from '../../../../controllers/general-add';

import pdf from '../../../../mixins/pdf-export';

import config from '../../../../config/environment';

import $ from 'jquery';

UsersAddIndexController = GeneralAddController.extend(pdf, {
    Collector: Ember.inject.service('collector-service'),
    intl: Ember.inject.service(),
    cc: Ember.inject.service(),
    savedController: Ember.inject.controller('users/saved'),
    notifications: Ember.inject.service(),
    vastuuGroup: Ember.inject.service('vastuu-group'),
    addGetImageTilaajavastuu: Em.on('init', function () {
        var c, customization;
        c = this.get('Collector');
        if (c.fieldExists('user', 'taxnumber')) {
            customization = this.get('customization');
            customization.image = {
                template: 'users/add/image',
                features: {
                    hide_preview: true,
                },
            };
            return this.set('customization', customization);
        }
    }),
    customization: Em.computed('model', {
        set: function (key, value) {
            return (this._value = value);
        },
        get: function () {
            var country, cust, userlevels;
            if (this._value) {
                return this._value;
            }
            cust = {
                setting_wap_font_size: {
                    hide: true,
                },
                language: {
                    hide: true,
                },
                ea1card: {
                    type: 'date',
                },
                ea2card: {
                    type: 'date',
                },
                firecard: {
                    type: 'date',
                },
                safetycard: {
                    type: 'date',
                },
                birthdate: {
                    type: 'date',
                    features: {},
                },
                userlevel: {
                    features: {
                        default_value: '1',
                    },
                },
                username: {
                    features: {
                        validate_check: ['minLength:6', 'maxLength:80', 'remote:username'],
                    },
                },
                status: {
                    features: {
                        default_value: 'ok',
                    },
                },
                phone: {
                    template: 'users/add/phone',
                },
                password: {
                    features: {
                        validate_check: ['password'],
                    },
                    default: null,
                },
            };
            if (
                parseInt(this.get('session.currentUser.userlevel')) < 5 ||
                parseInt(this.get('session.currentUser.userlevel')) === 6
            ) {
                // allow adding roles 1-3 and own roles
                userlevels = Ember.get(
                    this.get('Collector').formField('user', 'userlevel'),
                    'options',
                );
                cust.userlevel.options = userlevels
                    .filter(function (userlevel) {
                        return !(userlevel === '5' || userlevel === '6');
                    })
                    .join(',');
            } else if (parseInt(this.get('session.currentUser.userlevel')) === 8) {
                // allow adding roles 7 and 8
                userlevels = Ember.get(
                    this.get('Collector').formField('user', 'userlevel'),
                    'options',
                );
                cust.userlevel.options = userlevels
                    .filter(function (userlevel) {
                        return userlevel === '7' || userlevel === '8';
                    })
                    .join(',');
            }
            // special feature used for date-input
            // inserts default-value only after user has clicked date-input
            if (Modernizr.inputtypes.date) {
                cust.birthdate.features.onclick_default_value =
                    moment('19800101').format('YYYY-MM-DD');
            } else {
                cust.birthdate.features.onclick_default_value = '01/01/1980';
            }
            if (this.get('model') && this.get('session.currentUser.userlevel') === '4') {
                this.set('model.employer', this.get('session.currentUser.employer'));
            }
            //cust.password.default = @generatePassword()

            //set phone by country
            country = this.get('session.currentUser.company_info.country');
            switch (country) {
                case 'fi':
                    cust.phone.default = '+358';
                    break;
                case 'se':
                    cust.phone.default = '+46';
            }
            return cust;
        },
    }),
    generatePassword: function () {
        var chars, generated_password, i, rnum, string_length;
        chars = '23456789abcdefghkmnpqrstuvwxyz';
        string_length = 6;
        generated_password = '';
        i = 0;
        while (i < string_length) {
            rnum = Math.floor(Math.random() * chars.length);
            generated_password += chars.substring(rnum, rnum + 1);
            i++;
        }
        return generated_password;
    },
    countryLists: Ember.inject.service(),
    setCountryCode: function () {
        var code, codes, codesByNumber, j, len, model, ref, results;
        model = this.get('model');
        codes = this.get('countryLists.phoneList');
        codesByNumber = codes.mapBy('number');
        if (
            !model.get('phone') ||
            (((ref = model.get('phone')), indexOf.call(codesByNumber, ref) >= 0) &&
                model.get('homecountry') &&
                !this.get('countryCodeSetManually'))
        ) {
            results = [];
            for (j = 0, len = codes.length; j < len; j++) {
                code = codes[j];
                if (code.id === model.get('homecountry')) {
                    results.push(model.set('phone', code.number));
                } else {
                    results.push(void 0);
                }
            }
            return results;
        }
    },
    actions: {
        generalAddAction: async function (type, param) {
            if (type === 'getImage') {
                this.set('loading', true);
                await this.getImage(this.get('model'));
                this.set('loading', false);
            }
            if (type === 'changeCountry') {
                return this.changeCountry(param);
            }
            if (type === 'setCountryCode') {
                return this.setCountryCode();
            }
        },
    },
    changeCountry: function (countrycode) {
        this.set('countryCodeSetManually', true);
        return this.set('model.phone', countrycode);
    },
    getImage: async function (model) {
        // Skip rest of the function and show warning if no employer set
        if (!model.get('employer.id')) {
            this.get('notifications').warning(this.get('intl').t('employer.add.emp_id_missing'), {
                autoClear: true,
            });
            return;
        }
        var emp_id, intl, taxnumber;
        intl = this.get('intl');

        taxnumber = model.get('taxnumber');
        // try to fetch emp_id of employer if it is not loaded yet (this is case when editing user)
        if (!model.get('employer.emp_id')) {
            let employer = await this.store.findRecord('employer', model.get('employer.id'), {
                reload: true,
            });
            emp_id = employer.emp_id;
        } else {
            emp_id = model.get('employer.emp_id');
        }
        try {
            const response = await this.vastuuGroup.request(config.APP.CC_TVCARDIMAGE,
                {
                    taxnumber: taxnumber,
                    emp_id: emp_id,
                }
            );

            let message;
            if (response != null && !response.error) {
                model.set('image', [
                    {
                        file: 'data:image/png;base64,' + response,
                        name: 'naama.png',
                    },
                ]);
                this.set('fetchedImage', 'data:image/png;base64,' + response);
            } else {
                message = Ember.String.htmlSafe(
                    '<div>' +
                        '<h3><b>' +
                        intl.t('employer.add.status.notfound') +
                        '</b></h3>' +
                        intl.t('employer.add.status.marketing') +
                        '</div>',
                );
            }
            this.set('message', message);
        } catch (error) {
            this.get('notifications').warning(intl.t('employer.add.status.failed'), {
                autoClear: true,
            });
        }
    },
    disable_username_generation: false,
    username_observer: Em.observer('model.username', function () {
        if (this.get('model.username')) {
            return this.generateUsername().then((test) => {
                if (test !== this.get('model.username')) {
                    // User entered input so disable generating..
                    return this.set('disable_username_generation', true);
                }
            });
        } else {
            return this.set('disable_username_generation', false);
        }
    }),
    email_observer: Em.observer('model.email', function () {
        var email, username;
        if (!this.get('model.isNew')) {
            return;
        }
        username = this.get('model.username');
        email = this.get('model.email');
        if (username && email) {
            if (
                username === email.substring(0, username.length) ||
                email === username.substring(0, email.length)
            ) {
                return this.set('model.username', email);
            }
        } else if (email) {
            return this.set('model.username', email);
        } else if (username && username.length === 1 && !email) {
            return this.set('model.username', email);
        }
    }),
    employerPromise: Em.computed('model.employer', function () {
        return new Ember.RSVP.Promise((resolve) => {
            if (!this.get('model.employer.domain')) {
                return this.get('store')
                    .query('employer', {
                        id: this.get('model.employer.id'),
                    })
                    .then(() => {
                        return resolve();
                    });
            } else {
                return resolve();
            }
        });
    }),
    generateUsername: function () {
        return new Ember.RSVP.Promise((resolve) => {
            var generatedUsername;
            if (
                this.get('model.lastname') &&
                this.get('model.firstname') &&
                this.get('model.employer.id') &&
                this.get('model.homecountry')
            ) {
                return this.get('employerPromise').then(() => {
                    var company_domain, domain, generatedUsername;
                    company_domain = this.get('model.employer.domain');
                    domain = company_domain
                        ? company_domain
                        : this.removeCompanyType(this.get('model.employer.name')) +
                          '.' +
                          this.get('model.homecountry');
                    generatedUsername =
                        this.get('model.firstname') +
                        '.' +
                        this.get('model.lastname') +
                        '@' +
                        domain;
                    generatedUsername = this.convertAccentedCharacters(generatedUsername);
                    generatedUsername = generatedUsername.replace(/\s+/g, '');
                    generatedUsername = generatedUsername.replace(/[&]+/g, '');
                    generatedUsername = generatedUsername.toLowerCase();
                    return resolve(generatedUsername);
                });
            } else if (this.get('model.lastname') && this.get('model.firstname')) {
                generatedUsername = this.get('model.firstname') + '.' + this.get('model.lastname');
                generatedUsername = this.convertAccentedCharacters(generatedUsername);
                generatedUsername = generatedUsername.replace(/\s+/g, '');
                generatedUsername = generatedUsername.replace(/[&]+/g, '');
                generatedUsername = generatedUsername.toLowerCase();
                return resolve(generatedUsername);
            } else {
                return resolve(null);
            }
        });
    },
    validateAndGenerateUsername: Em.observer(
        'model.lastname',
        'model.firstname',
        'model.employer',
        'model.homecountry',
        function () {
            var employer_field_is_in_use;
            if (!this.get('model.isNew')) {
                return;
            }
            employer_field_is_in_use = this.get('Collector').testNeeds(['user.employer']);
            if (employer_field_is_in_use) {
                if (
                    this.get('model.lastname') &&
                    this.get('model.firstname') &&
                    this.get('model.employer') &&
                    this.get('model.homecountry') &&
                    !this.get('disable_username_generation')
                ) {
                    return this.generateUsername().then((username) => {
                        return this.set('model.username', username);
                    });
                }
            } else {
                if (this.get('model.lastname') && !this.get('disable_username_generation')) {
                    return this.generateUsername().then((username) => {
                        return this.set('model.username', username);
                    });
                }
            }
        },
    ),
    removeCompanyType: function (company) {
        var arr, type;
        if (!company) {
            return '';
        }
        arr = company.split(' ');
        type = arr[arr.length - 1].toLowerCase();
        if (type === 'oy' || type === 'oyj' || type === 'ltd' || type === 'ab') {
            arr.pop();
        }
        return arr.join(' ');
    },
    convertAccentedCharacters: function (str) {
        var conversions, i, re;
        conversions = {};
        conversions['A'] = 'À|Á|Â|Ã|Ä|Å|Ǻ|Ā|Ă|Ą|Ǎ|Ä';
        conversions['a'] = 'à|á|â|ã|å|ǻ|ā|ă|ą|ǎ|ªä|æ|ǽ|ä';
        conversions['C'] = 'Ç|Ć|Ĉ|Ċ|Č';
        conversions['c'] = 'ç|ć|ĉ|ċ|č';
        conversions['D'] = 'Ð|Ď|Đ';
        conversions['d'] = 'ð|ď|đ';
        conversions['E'] = 'È|É|Ê|Ë|Ē|Ĕ|Ė|Ę|Ě';
        conversions['e'] = 'è|é|ê|ë|ē|ĕ|ė|ę|ě';
        conversions['G'] = 'Ĝ|Ğ|Ġ|Ģ';
        conversions['g'] = 'ĝ|ğ|ġ|ģ';
        conversions['H'] = 'Ĥ|Ħ';
        conversions['h'] = 'ĥ|ħ';
        conversions['I'] = 'Ì|Í|Î|Ï|Ĩ|Ī|Ĭ|Ǐ|Į|İ';
        conversions['i'] = 'ì|í|î|ï|ĩ|ī|ĭ|ǐ|į|ı';
        conversions['J'] = 'Ĵ';
        conversions['j'] = 'ĵ';
        conversions['K'] = 'Ķ';
        conversions['k'] = 'ķ';
        conversions['L'] = 'Ĺ|Ļ|Ľ|Ŀ|Ł';
        conversions['l'] = 'ĺ|ļ|ľ|ŀ|ł';
        conversions['N'] = 'Ñ|Ń|Ņ|Ň';
        conversions['n'] = 'ñ|ń|ņ|ň|ŉ';
        conversions['O'] = 'Ò|Ó|Ô|Õ|Ō|Ŏ|Ǒ|Ő|Ơ|Ø|Ǿ|Ö';
        conversions['o'] = 'ò|ó|ô|õ|ō|ŏ|ǒ|ő|ơ|ø|ǿ|ºö|œ|ö';
        conversions['R'] = 'Ŕ|Ŗ|Ř';
        conversions['r'] = 'ŕ|ŗ|ř';
        conversions['S'] = 'Ś|Ŝ|Ş|Š';
        conversions['s'] = 'ś|ŝ|ş|š|ſ';
        conversions['T'] = 'Ţ|Ť|Ŧ';
        conversions['t'] = 'ţ|ť|ŧ';
        conversions['U'] = 'Ù|Ú|Û|Ũ|Ū|Ŭ|Ů|Ű|Ų|Ư|Ǔ|Ǖ|Ǘ|Ǚ|Ǜ|Ü';
        conversions['u'] = 'ù|ú|û|ũ|ū|ŭ|ů|ű|ų|ư|ǔ|ǖ|ǘ|ǚ|ǜ|ü';
        conversions['Y'] = 'Ý|Ÿ|Ŷ';
        conversions['y'] = 'ý|ÿ|ŷ';
        conversions['W'] = 'Ŵ';
        conversions['w'] = 'ŵ';
        conversions['Z'] = 'Ź|Ż|Ž';
        conversions['z'] = 'ź|ż|ž';
        conversions['ss'] = 'ß';
        conversions['f'] = 'ƒ';
        for (i in conversions) {
            re = new RegExp(conversions[i], 'g');
            str = str.replace(re, i);
        }
        return str;
    },
});

export default UsersAddIndexController;
