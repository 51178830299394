export default {
    settings: [
        {
            name: 'new_messageboard',
            title: 'settings.general.new_messageboard',
            needs: ['!customersetting.new_messageboard'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'general.settings',
        },
        {
            title: 'settings.general.terms',
            needs: ['modules.timetracker'],
            component: 'settings/general-v2/components/terms-setting',
            category: 'project-management.projects',
        },
        {
            title: 'settings.general.recipients',
            productsOn: ['products.recipients'],
            productsOff: ['!products.recipients'],
            needs: ['products.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'notifications.settings',
        },
        {
            name: 'orientations_check_all',
            title: 'settings.general.orientations',
            needs: ['modules.orientations'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.orientations',
        },
        {
            title: 'settings.general.self_orientation',
            productsOn: ['products.orientations_self'],
            productsOff: ['!products.orientations_self'],
            needs: ['modules.orientations'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.orientations',
        },
        {
            title: 'settings.general.orientations_parent',
            productsOn: ['products.orientations_parent'],
            productsOff: ['!products.orientations_parent'],
            needs: ['modules.orientations'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.orientations',
        },
        {
            title: 'settings.worksitediary.state',
            productsOn: ['products.worksitediary_state'],
            productsOff: ['!products.worksitediary_state'],
            needs: ['products.worksitediary'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'no-category',
        },
        {
            title: 'settings.phasetitle',
            form: 'phasetitle',
            productsOn: ['products.worksitediary_phasetitle'],
            productsOff: ['!products.worksitediary_phasetitle'],
            needs: ['products.worksitediary'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'no-category',
        },
        {
            title: 'settings.general.superior_for_user',
            productsOn: ['products.superior_for_user'],
            productsOff: ['!products.superior_for_user'],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            editingDisabledRules: [
                { key: '!products.super_superior', text: 'general.super_superior' },
            ],
            category: 'general.settings',
        },
        {
            title: 'settings.general.superior_replacement',
            productsOn: ['products.superior_replacement'],
            productsOff: ['!products.superior_replacement'],
            needs: ['modules.timetracker'],
            disable: [
                { key: '!products.mini_entry', text: 'general.mini_entry' },
                { key: 'products.superior_for_user', text: 'settings.general.superior_for_user' },
            ],
            category: 'general.settings',
        },
        {
            title: 'settings.general.worktimegroup_changes',
            productsOn: ['products.worktimegroup_changes'],
            productsOff: ['!products.worktimegroup_changes'],
            needs: [
                'modules.timetracker',
                'products.worktimegroup',
                'products.salarytype_v2',
                'flag.worktimegroup-changes-setting',
            ],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'general.settings',
        },
        {
            title: 'settings.super_superior',
            productsOn: ['products.super_superior'],
            productsOff: ['!products.super_superior'],
            needs: ['products.groups||products.multigroups', 'products.superior_for_user'],
            disable: [],
            category: 'general.settings',
        },
        {
            title: 'settings.general.employer',
            productsOn: ['products.employer_standalone'],
            productsOff: ['!products.employer_standalone'],
            needs: ['products.timetracker', '!products.rakentajanpaketti'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'general.settings',
        },

        {
            name: 'push_notification_time',
            setting: {
                name: 'push_notification_time',
            },
            title: 'settings.general.push_notifications_fixed_time',
            component: 'collector/field-setting-comp/push-setting',
            needs: ['modules.timetracker', 'serviceCountry@fi', '!flag.notification-settings-v2'],
            secondary_setting: 'push_notification_time_hours_fi',
            category: 'notifications.settings',
        },
        {
            name: 'push_notification_time',
            setting: {
                name: 'push_notification_time',
            },
            title: 'settings.general.push_notifications_fixed_time',
            component: 'collector/field-setting-comp/push-setting',
            needs: ['modules.timetracker', 'serviceCountry@se', '!flag.notification-settings-v2'],
            secondary_setting: 'push_notification_time_hours_se',
            category: 'notifications.settings',
        },

        {
            name: 'push_notification_time',
            setting: {
                name: 'push_notification_time',
            },
            title: 'settings.general.push_notifications_fixed_time',
            component: 'collector/field-setting-comp/push-setting',
            needs: ['modules.timetracker', 'serviceCountry@fi', 'flag.notification-settings-v2'],
            category: 'notifications.settings',
        },
        {
            name: 'push_notification_time',
            setting: {
                name: 'push_notification_time',
            },
            title: 'settings.general.push_notifications_fixed_time',
            component: 'collector/field-setting-comp/push-setting',
            needs: ['modules.timetracker', 'serviceCountry@se', 'flag.notification-settings-v2'],
            category: 'notifications.settings',
        },

        {
            name: 'email_approval_for_all',
            title: 'settings.general.email_approval_for_all',
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'notifications.settings',
        },
        {
            title: 'settings.tasks',
            options: [
                {
                    products: ['!products.tasks', '!products.multitasking', '!products.subtasks'],
                    title: 'general.off',
                },
                {
                    products: ['products.tasks', '!products.multitasking'],
                    title: 'settings.tasks.single',
                },
                {
                    products: ['!products.tasks', 'products.multitasking'],
                    title: 'settings.tasks.multitasking',
                },
                {
                    products: ['products.tasks', 'products.multitasking'],
                    title: 'settings.tasks.both',
                },
            ],
            form: 'task',
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            groupEditable: true,
            category: 'project-management.tasks',
        },
        {
            title: 'settings.subtasks',
            productsOn: ['products.subtasks'],
            productsOff: ['!products.subtasks'],
            form: 'subtask',
            needs: ['modules.timetracker'],
            disable: [
                {
                    key: 'products.tasks||products.multitasking',
                    text: 'report.projects.tasks_or_multitasks',
                },
            ],
            category: 'project-management.tasks',
        },
        {
            title: 'settings.timetracker.time_adding_type',
            options: [
                {
                    products: [
                        '!products.timetracker_hybrid',
                        '!products.timetracker_hours',
                        '!products.timetracker_clockcard',
                    ],
                    title: 'settings.timetracker.startandend',
                },
                {
                    products: [
                        '!products.timetracker_hybrid',
                        'products.timetracker_hours',
                        '!products.timetracker_clockcard',
                    ],
                    title: 'settings.timetracker.hours',
                    notGroupEditable: true,
                },
                {
                    products: [
                        'products.timetracker_hybrid',
                        '!products.timetracker_hours',
                        '!products.timetracker_clockcard',
                    ],
                    title: 'settings.timetracker.hybrid',
                },
                {
                    products: [
                        '!products.timetracker_hybrid',
                        '!products.timetracker_hours',
                        'products.timetracker_clockcard',
                    ],
                    title: 'settings.timetracker.clockcard',
                },
            ],
            needs: ['modules.timetracker'],
            groupEditable: true,
            groupEditableSettings: true,
            groupEditableIf: {
                needs: [
                    '!products.timetracker_hybrid',
                    '!products.timetracker_hours',
                    '!products.timetracker_clockcard',
                ],
                title: 'settings.timetracker.startandend.worktimegroup_needs',
            },
            addition: true,
            category: 'worktimes.settings',
        },
        {
            title: 'settings.timetracker.edit_time',
            component: 'settings/general-v2/components/edit-time-setting',
            setting: {
                form: 'worktime',
                field: 'date',
                setting: { name: 'date_interval', userlevel: '1', value: 'locked' },
                type: 'number',
            },
            needs: ['modules.timetracker'],
            category: 'worktimes.settings',
        },
        {
            title: 'settings.timetracker.pause',
            label: 'split',
            productsOn: ['products.pause'],
            productsOff: ['!products.pause'],
            component: 'settings/general-v2/components/pause-setting',
            setting: {
                form: 'worktime',
                field: 'pause',
                setting: { title: 'default_value', value: 30 },
            },
            needs: ['modules.timetracker'],
            disable: [],
            groupEditable: true,
            groupEditableSettings: true,
            checkWorktimegroupWarnings:  { products: ['pause'] },
            category: 'worktimes.settings',
        },
        {
            title: "settings.timetracker.autopause",
            label: "split",
            productsOn: ["products.autopause"],
            productsOff: ["!products.autopause"],
            component: "settings/general-v2/components/auto-pause-setting",
            needs: ["modules.timetracker", "flag.autopause"],
            disable: [],
            groupEditable: true,
            groupEditableSettings: true,
            category: "worktimes.settings",
        },
        {
            title: 'settings.timetracker.multipause',
            label: 'split',
            productsOn: ['products.multipause'],
            productsOff: ['!products.multipause'],
            needs: ['modules.timetracker', 'flag.new-clockcard'],
            disable: [],
            groupEditable: false,
            groupEditableSettings: false,
            category: 'worktimes.settings',
        },
        {
            title: 'settings.timetracker.force_clockcard_dialog',
            productsOn: ['products.force_clockcard_dialog'],
            productsOff: ['!products.force_clockcard_dialog'],
            needs: ['modules.timetracker', 'flag.new-clockcard'],
            disable: [],
            groupEditable: false,
            groupEditableSettings: false,
            category: 'worktimes.settings',
        },
        {
            title: 'settings.timetracker.timeclean',
            label: 'split',
            component: 'collector/nfc-comp',
            productsOn: ['products.rfid'],
            productsOff: ['!products.rfid'],
            needs: ['modules.timetracker||products.personalliggareandid06', 'debug'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'worktimes.settings',
        },
        {
            title: 'settings.materials',
            productsOn: ['products.materiallog'],
            productsOff: ['!products.materiallog'],
            form: 'materialtype',
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.materials',
        },
        {
            title: 'settings.materialgroups',
            productsOn: ['products.materialgroups'],
            productsOff: ['!products.materialgroups'],
            needs: ['modules.timetracker'],
            disable: [{ key: 'products.materiallog', text: 'settings.materials' }],
            category: 'project-management.materials',
        },
        {
            title: 'settings.absences',
            productsOn: ['products.absences'],
            productsOff: ['!products.absences'],
            form: 'abcensetype',
            needs: ['modules.timetracker||products.mini_entry', '!products.salarytype_v2'],
            groupEditable: true,
            fields: 'key,name,type,usesholiday,worktimegroup',
            category: 'absences.settings',
        },
        {
            title: 'settings.absences',
            productsOn: ['products.absences'],
            productsOff: ['!products.absences'],
            needs: ['modules.timetracker||products.mini_entry', 'products.salarytype_v2'],
            groupEditable: true,
            component: 'settings/general-v2/components/salarytypes/absencetypes',
            category: 'absences.settings',
        },
        {
            title: 'settings.holiday_startdate',
            needs: [],
            disable: [{ key: 'products.absences', text: 'general.absences' }],
            component: 'settings/general-v2/components/holiday',
            custom: true,
            category: 'absences.settings',
        },
        {
            title: 'settings.absencetype',
            productsOn: ['products.absences_hours'],
            productsOff: ['!products.absences_startandend', '!products.absences_hours'],
            needs: ['modules.timetracker||products.mini_entry'],
            disable: [{ key: 'products.absences', text: 'general.absences' }],
            category: 'absences.settings',
        },
        {
            title: 'settings.absence_projects',
            productsOn: ['products.absence_projects'],
            productsOff: ['!products.absence_projects'],
            needs: [],
            disable: [
                { key: '!products.mini_entry', text: 'general.mini_entry' },
                { key: 'products.absences', text: 'general.absences' },
            ],
            category: 'absences.settings',
        },
        {
            name: 'hide_holidays',
            setting: {
                name: 'hide_holidays',
            },
            title: 'settings.general.hide_holidays',
            needs: [],
            disable: [
                { key: '!products.mini_entry', text: 'general.mini_entry' },
                { key: 'products.absences', text: 'general.absences' },
            ],
            category: 'absences.settings',
        },
        {
            name: 'vacation_week_length',
            title: 'settings.vacation_week_length5',
            needs: ['serviceCountry@fi'],
            groupEditable: true,
            groupEditableIf: {
                needs: ['!customersetting.vacation_week_length'],
                title: 'settings.general.vacation_week_length.worktimegroup_needs',
            },
            disable: [
                { key: '!products.mini_entry', text: 'general.mini_entry' },
                { key: 'products.absences', text: 'general.absences' },
            ],
            category: 'absences.settings',
        },
        {
            title: 'settings.holiday_pay',
            productsOn: ['products.holiday_pay'],
            productsOff: ['!products.holiday_pay'],
            needs: [],
            disable: [{ key: 'modules.absences', text: 'general.absences' }],
            category: 'absences.settings',
        },
        {
            title: 'settings.team_calendar',
            productsOn: ['products.team_calendar'],
            productsOff: ['!products.team_calendar'],
            needs: [],
            disable: [{ key: 'modules.absences', text: 'general.absences' }],
            category: 'absences.settings',
        },
        {
            title: 'settings.skip_holiday_check',
            productsOn: ['products.skip_holiday_check'],
            productsOff: ['!products.skip_holiday_check'],
            needs: ['flag.holiday-checker'],
            disable: [{ key: 'modules.absences', text: 'general.absences' }],
            category: 'absences.settings',
        },
        {
            title: 'settings.skip_holidaypay_check',
            productsOn: ['products.skip_holidaypay_check'],
            productsOff: ['!products.skip_holidaypay_check'],
            needs: ['flag.holiday-checker'],
            disable: [{ key: 'products.holiday_pay', text: 'settings.holiday_pay' }],
            category: 'absences.settings',
        },
        {
            name: 'stampout_worktime_fi',
            setting: {
                name: 'stampout_worktime_fi',
                secondary_setting_name: 'stampout_worktime_time_fi',
            },
            title: 'settings.general.stampout_worktime',
            component: 'settings/general-v2/components/time-setting',
            needs: ['modules.timetracker', 'serviceCountry@fi'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'worktimes.limitations',
        },
        {
            name: 'stampout_worktime_se',
            setting: {
                name: 'stampout_worktime_se',
                secondary_setting_name: 'stampout_worktime_time_se',
            },
            title: 'settings.general.stampout_worktime',
            component: 'settings/general-v2/components/time-setting',
            needs: ['modules.timetracker', 'serviceCountry@se'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'worktimes.limitations',
        },
        {
            name: 'stampout_presence_fi',
            setting: {
                name: 'stampout_presence_fi',
                secondary_setting_name: 'stampout_presence_time_fi',
            },
            title: 'settings.general.stampout_presence',
            component: 'settings/general-v2/components/time-setting',
            needs: ['presence', 'serviceCountry@fi'],
            category: 'worktimes.limitations',
        },
        {
            title: 'settings.round_times',
            form: 'rounding_rule',
            needs: ['modules.timetracker', 'worktime.formFeature.round_times;10'],
            //disable: [{key: "worktime.formFeature.round_times;10", text: "dummy1"}],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'worktimes.limitations',
        },
        {
            title: 'settings.round_times',
            productsOn: ['products.rounding_rule_v2'],
            productsOff: ['!products.rounding_rule_v2'],
            form: 'rounding_rule_v2',
            needs: ['modules.timetracker', '!worktime.formFeature.round_times;10'],
            //disable: [{key: "!worktime.formFeature.round_times;10", text: "dummy2"}],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'worktimes.limitations',
        },
        {
            title: 'settings.prevent_multiple_worktimes',
            productsOn: ['products.timetracker_prevent_multiple'],
            productsOff: ['!products.timetracker_prevent_multiple'],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'worktimes.limitations',
        },
        {
            title: 'settings.overlapping_worktimes',
            productsOn: ['products.timetracker_disable_overlapping'],
            productsOff: [
                '!products.timetracker_overlap',
                '!products.timetracker_disable_overlapping',
            ],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'worktimes.limitations',
        },
        {
            title: 'settings.groups',
            options: [
                {
                    products: ['!products.groups', '!products.multigroups'],
                    title: 'settings.timetracker.nogroups',
                },
                {
                    products: ['products.groups', '!products.multigroups'],
                    title: 'settings.timetracker.groups',
                },
                {
                    products: ['!products.groups', 'products.multigroups'],
                    title: 'settings.timetracker.multigroups',
                },
            ],
            form: 'group',
            needs: ['products.timetracker||products.generic_timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            editingDisabledRules: [
                { key: '!products.super_superior', text: 'general.super_superior' },
            ],
            category: 'project-management.groups',
        },
        {
            title: 'settings.super_superior',
            productsOn: ['products.super_superior'],
            productsOff: ['!products.super_superior'],
            needs: ['products.groups||products.multigroups', 'products.superior_for_user'],
            disable: [],
            category: 'project-management.groups',
        },
        {
            title: 'settings.groups',
            options: [
                {
                    products: ['!products.groups', '!products.multigroups'],
                    title: 'settings.timetracker.nogroups',
                },
                {
                    products: ['products.groups', '!products.multigroups'],
                    title: 'settings.timetracker.groups',
                },
                {
                    products: ['!products.groups', 'products.multigroups'],
                    title: 'settings.timetracker.multigroups',
                },
            ],
            form: 'group',
            needs: ['!products.timetracker', '!products.generic_timetracker'],
            disable: [
                { key: '!products.mini_entry', text: 'general.mini_entry' },
                { key: 'presence', text: 'header.path.add.presence' },
            ],
            category: 'project-management.groups',
        },
        {
            title: 'settings.mainproject',
            productsOn: ['products.mainproject'],
            productsOff: ['!products.mainproject'],
            form: 'mainproject',
            needs: ['modules.timetracker', '!products.base_basic', '!products.mini_entry'],
            disable: [
                {
                    key: 'products.projects_by_customer',
                    text: 'settings.timetracker.customer.projects',
                },
            ],
            category: 'project-management.projects',
        },
        {
            title: 'settings.subprojects',
            options: [
                {
                    products: ['!products.subprojects', '!products.subprojectsmulti'],
                    title: 'general.off',
                },
                {
                    products: ['products.subprojects', '!products.subprojectsmulti'],
                    title: 'settings.timetracker.subprojects',
                },
                {
                    products: ['!products.subprojects', 'products.subprojectsmulti'],
                    title: 'settings.timetracker.subprojectsmulti',
                },
            ],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.projects',
        },

        {
            title: 'settings.customers',
            productsOn: ['products.customer'],
            productsOff: ['!products.customer'],
            form: 'customer',
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.customers',
        },
        {
            title: 'settings.customers',
            productsOn: ['products.customer'],
            productsOff: ['!products.customer'],
            form: 'customer',
            needs: ['presence', '!products.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.customers',
        },
        /*
        {
            title: "settings.customers",
            tt3valueOn: "customers.none",
            tt3valueOff: "customers.on",
            productsOn: ["customer"],
            productsOff: ["!customer"],
            form: 'customer',
            needs: ["!modules.timetracker"],
            disable: [, {key: "worktime", text: "general.worktime"}],
            category: "project-management.customers"
        },
        */
        {
            title: 'settings.timetracker.customer.projects',
            productsOn: ['products.projects_by_customer'],
            productsOff: ['!products.projects_by_customer'],
            needs: ['modules.timetracker'],
            disable: [{ key: 'products.customer', text: 'settings.customers' }],
            category: 'project-management.customers',
        },
        {
            title: 'settings.timetracker.saldo',
            productsOn: ['products.saldo'],
            productsOff: ['!products.saldo'],
            component: 'settings/general-v2/components/saldo-setting',
            hideSettingsFromWorktimegroups: true,
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            groupEditable: true,
            checkWorktimegroupWarnings:  { products: ['saldo'] },
            category: 'salaries.saldo_banks',
        },
        {
            name: 'saldo_skip_weekends',
            title: 'settings.saldo.skip_weekends',
            label: 'split',
            needs: ['modules.timetracker'],
            disable: [{ key: 'products.saldo', text: 'settings.timetracker.saldo' }],
            category: 'salaries.saldo_banks',
        },
        {
            title: 'settings.saldo.pay_saldos',
            label: 'split',
            productsOn: ['products.pay_saldos'],
            productsOff: ['!products.pay_saldos'],
            needs: ['modules.timetracker', 'flag.pay_saldos'],
            disable: [{ key: 'products.saldo', text: 'settings.timetracker.saldo' }],
            category: 'salaries.saldo_banks',
        },
        {
            name: 'saldo_skip_pieceworkdays',
            title: 'settings.saldo.skip_pieceworkdays',
            label: 'split',
            needs: ['modules.timetracker'],
            disable: [
                { key: 'products.saldo', text: 'settings.timetracker.saldo' },
                { key: 'worktime.typeofwork', text: 'field.typeofwork' },
            ],
            category: 'salaries.saldo_banks',
        },
        {
            title: 'settings.timetracker.overtime',
            productsOn: ['products.overtime'],
            productsOff: ['!products.overtime'],
            //component: "collector/field-setting-comp/overtime-setting",
            component: 'settings/general-v2/components/overtime-comp',
            needs: ['modules.timetracker', '!serviceCountry@se'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            groupEditableSettings: true,
            groupEditable: true,
            checkWorktimegroupWarnings:  {
                products: ['overtime', 'overtime_extrahours', 'overtime_daily', 'overtime_weekly', 'overtime_period', 'overtime_splitted']
            },
            category: 'salaries.overtime',
            dummySetting: true,
            hideHeader: true,
        },
        {
            title: 'settings.project_owner',
            productsOn: ['products.project_owner'],
            productsOff: ['!products.project_owner'],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.settings',
        },
        {
            title: 'settings.worktimegroup',
            needs: ['modules.timetracker'],
            productsOn: ['products.worktimegroup'],
            productsOff: ['!products.worktimegroup'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'general.settings',
            component: 'settings/general-v2/components/worktimegroups',
            hasReport: true,
        },
        {
            title: 'settings.disable_login',
            needs: [],
            productsOn: ['products.user_disable_login'],
            productsOff: ['!products.user_disable_login'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'general.settings',
        },
        {
            title: 'settings.timetracker_allow_nfc_edit',
            productsOn: ['products.timetracker_allow_nfc_edit'],
            productsOff: ['!products.timetracker_allow_nfc_edit'],
            needs: ['!products.generic_timetracker', '!products.mini_entry'],
            disable: [{ key: 'products.rfid', text: 'settings.timetracker.timeclean' }],
            category: 'worktimes.settings',
        },
        {
            name: 'postpopulated_worktimes',
            title: 'settings.general.postpopulated_worktimes',
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            groupEditable: true,
            groupEditableSettings: true,
            groupEditableIf: {
                needs: ['!customersetting.postpopulated_worktimes'],
                title: 'settings.general.postpopulated_worktimes.worktimegroup_needs',
            },
            component: 'settings/general-v2/components/postpopulated-worktimes',
            category: 'worktimes.settings',
        },
        {
            title: 'settings.timetracker.worktype',
            options: [
                {
                    products: [
                        '!products.timetracker_worktype',
                        '!products.timetracker_worktype_extra',
                    ],
                    title: 'settings.timetracker.worktype_none',
                },
                {
                    products: [
                        'products.timetracker_worktype',
                        '!products.timetracker_worktype_extra',
                    ],
                    title: 'settings.timetracker.worktype_def',
                },
                {
                    products: [
                        '!products.timetracker_worktype',
                        'products.timetracker_worktype_extra',
                    ],
                    title: 'settings.timetracker.worktype_extra',
                },
            ],
            needs: ['modules.timetracker', '!products.generic_timetracker'],
            disable: [
                {
                    key: 'products.timetracker_worktype||products.timetracker_worktype_extra',
                    text: 'dummy',
                },
            ],
            category: 'no-category',
        },
        {
            title: 'settings.timetracker.salarytype',
            productsOn: ['products.salarytype', 'products.selectablesalarytype'],
            productsOff: ['!products.salarytype', '!products.selectablesalarytype'],
            needs: ['modules.timetracker', '!products.salarytype_v2'],
            disable: [
                { key: '!products.mini_entry', text: 'general.mini_entry' },
                { key: '!products.timetracker_worktype', text: 'settings.timetracker.worktype' },
                {
                    key: '!products.timetracker_worktype_extra',
                    text: 'settings.timetracker.worktype_extra',
                },
            ],
            category: 'salaries.settings',
        },
        {
            title: 'settings.timetracker.perdiem',
            optionsForComponent: [
                {
                    products: [
                        '!products.timetracker_perdiem',
                        '!products.timetracker_perdiem_meal',
                    ],
                    title: 'settings.timetracker.perdiem_none',
                },
                {
                    products: [
                        'products.timetracker_perdiem',
                        '!products.timetracker_perdiem_meal',
                    ],
                    title: 'settings.timetracker.perdiem_def',
                },
                {
                    products: [
                        '!products.timetracker_perdiem',
                        'products.timetracker_perdiem_meal',
                    ],
                    title: 'settings.timetracker.perdiem_meal',
                },
            ],
            needs: ['modules.timetracker', 'serviceCountry@fi'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            component: 'settings/general-v2/components/perdiem',
            category: 'salaries.settings',
        },
        {
            title: 'settings.timetracker.km_type',
            optionsForComponent: [
                {
                    products: [
                        '!products.timetracker_km',
                        '!products.timetracker_kmsade',
                        '!products.timetracker_kmandsade',
                    ],
                    title: 'settings.timetracker.km_none',
                },
                {
                    products: [
                        'products.timetracker_km',
                        '!products.timetracker_kmsade',
                        '!products.timetracker_kmandsade',
                    ],
                    title: 'settings.timetracker.km',
                },
                {
                    products: [
                        'products.timetracker_kmsade',
                        '!products.timetracker_km',
                        '!products.timetracker_kmandsade',
                    ],
                    title: 'settings.timetracker.kmsade',
                },
                {
                    products: ['products.timetracker_kmsade', 'products.timetracker_km'],
                    title: 'settings.timetracker.kmandsade',
                },
            ],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            component: 'settings/general-v2/components/km',
            category: 'salaries.settings',
        },
        {
            title: 'settings.timetracker.km_automatic',
            label: 'split',
            productsOn: ['products.timetracker_km_automatic', 'products.timetracker_km'],
            productsOff: ['!products.timetracker_km_automatic', '!products.timetracker_km'],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            component: 'settings/general-v2/components/autokm-setting',
            groupEditable: true,
            groupEditableSettings: true,
            category: 'salaries.settings',
        },
        {
            title: 'settings.timetracker.traveltime_automatic',
            productsOn: ['products.timetracker_traveltime'],
            productsOff: ['!products.timetracker_traveltime'],
            needs: ['modules.timetracker', 'serviceCountry@fi', '!products.generic_timetracker'],
            disable: [
                {
                    key: 'products.timetracker_km_automatic',
                    text: 'settings.timetracker.km_automatic',
                },
            ],
            category: 'salaries.settings',
        },
        {
            title: 'settings.general.midweek_holiday_pay',
            productsOn: ['products.midweek_holiday_pay'],
            productsOff: ['!products.midweek_holiday_pay'],
            needs: ['worktime'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'salaries.settings',
        },
        {
            title: 'settings.salarytypes.salarytypesv2onoff',
            productsOn: ['products.salarytype_v2'],
            productsOff: ['!products.salarytype_v2'],
            custom: true,
            component: 'settings/general-v2/components/salarytypes',
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'salaries.salarytypes',
            secondary_setting_on: [
                '!products.selectablesalarytype',
                '!products.salarytype',
                'products.worktimegroup',
            ],
            groupEditable: false,
        },
        {
            title: 'settings.salarytypes.salarytypesv2',
            component: 'settings/general-v2/components/salarytypes/salaries',
            needs: ['modules.timetracker', 'products.salarytype_v2'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'salaries.salarytypes',
            groupEditable: true,
        },
        {
            title: 'settings.salarytypes.absencetypes',
            component: 'settings/general-v2/components/salarytypes/absencetypes',
            needs: ['modules.timetracker', 'products.salarytype_v2'],
            disable: [
                { key: '!products.mini_entry', text: 'general.mini_entry' },
                { key: 'products.absences', text: 'general.absences' },
            ],
            category: 'salaries.salarytypes',
            groupEditable: true,
        },
        {
            title: 'settings.salarytypes.worktypes',
            component: 'settings/general-v2/components/salarytypes/worktypes',
            needs: ['modules.timetracker', 'products.salarytype_v2'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'salaries.salarytypes',
            groupEditable: true,
        },
        {
            title: 'settings.gps',
            options: [
                {
                    products: ['!products.gps', '!products.gps_force'],
                    title: 'settings.gps_none',
                },
                {
                    products: ['products.gps', '!products.gps_force'],
                    title: 'settings.gps_def',
                },
                {
                    products: ['!products.gps', 'products.gps_force'],
                    title: 'settings.gps_force',
                },
            ],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.settings',
        },
        {
            title: 'settings.general.gps_distance_value',
            component: 'settings/general-v2/components/number-field',
            setting: {
                setting: 'gps_distance',
                minVal: 0,
                defaultVal: 700,
                unit: 'm',
            },
            disable: [{ key: 'products.gps_force', text: 'settings.gps_force' }],
            needs: [],
            category: 'project-management.settings',
        },
        {
            title: 'settings.eventtitle',
            form: 'eventtitle',
            needs: [],
            disable: [
                { key: '!products.mini_entry', text: 'general.mini_entry' },
                { key: 'eventtitle', text: 'settings.eventtitle' },
            ],
            category: 'no-category',
        },
        {
            title: 'settings.wage_period',
            form: 'wage_period',
            component: 'salary-period-adder',
            needs: ['worktime', 'wage_period'],
            fields: 'period_name,period_start_date,period_end_date,worktimegroup',
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'salaries.salaryperiods',
            groupEditable: true,
        },
        {
            title: 'settings.export_contact_person',
            form: 'export_contact_person',
            needs: ['export_contact_person'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.construction_notices',
        },
        {
            title: 'settings.timetracker.multiple_companies',
            productsOn: ['products.multiple_companies'],
            productsOff: ['!products.multiple_companies'],
            needs: ['project', 'products.rakentajanpaketti'],
            category: 'project-management.construction_notices',
        },
        {
            title: 'settings.timetracker.founder',
            productsOn: ['products.taxman_founder'],
            productsOff: ['!products.taxman_founder'],
            needs: ['project', 'products.rakentajanpaketti'],
            category: 'project-management.construction_notices',
        },
        {
            title: 'settings.vg_customer_id',
            form: 'vastuugroupsetting',
            needs: ['vastuugroupsetting'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'integrations.settings',
        },
        {
            title: 'settings.timetracker.banks',
            label: 'split',
            productsOn: ['products.worktimebank'],
            productsOff: ['!products.worktimebank'],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'salaries.saldo_banks',
        },

        {
            title: 'settings.timetracker.hourprice',
            label: 'split',
            options: [
                {
                    //tt3value: "hourly_rates.none"
                    products: ['!products.hourprice_user', '!products.hourprice_project'],
                    title: 'settings.timetracker.hourprice_none',
                },
                {
                    //tt3value: "hourly_rates.user"
                    products: ['products.hourprice_user', '!products.hourprice_project'],
                    title: 'settings.timetracker.hourprice_user',
                },
                {
                    //tt3value: "hourly_rates.project"
                    products: ['!products.hourprice_user', 'products.hourprice_project'],
                    title: 'settings.timetracker.hourprice_project',
                },
            ],
            needs: ['modules.timetracker'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.settings',
        },
        {
            name: 'unapproved_worktimes',
            setting: {
                name: 'unapproved_worktimes',
            },
            title: 'settings.general.unapproved_worktimes',
            component: 'settings/general-v2/components/time-setting',
            needs: ['worktime', 'serviceCountry@fi', '!flag.notification-settings-v2'],
            category: 'notifications.settings',
        },
        {
            title: 'settings.taskmanagement.worktask_involved',
            productsOn: ['products.worktask_involved'],
            productsOff: ['!products.worktask_involved'],
            needs: ['products.taskmanagement'],
            category: 'project-management.worktasks',
        },
        {
            title: 'settings.taskmanagement.worktask_signature',
            productsOn: ['products.worktask_signature'],
            productsOff: ['!products.worktask_signature'],
            needs: ['products.taskmanagement'],
            category: 'project-management.worktasks',
        },
        {
            title: 'settings.taskmanagement.worktask_enddate',
            productsOn: ['products.worktask_enddate'],
            productsOff: ['!products.worktask_enddate'],
            needs: ['products.taskmanagement'],
            category: 'project-management.worktasks',
        },
        {
            title: 'settings.taskmanagement.worktask_starttime',
            productsOn: ['products.worktask_starttime'],
            productsOff: ['!products.worktask_starttime'],
            needs: ['products.taskmanagement'],
            disable: [],
            category: 'project-management.worktasks',
        },

        {
            title: 'settings.general.drivinglog.gauge',
            productsOn: ['products.drivinglog_gauge'],
            productsOff: ['!products.drivinglog_gauge'],
            needs: ['products.drivinglog'],
            category: 'drivinglog.settings',
        },
        {
            title: 'settings.general.drivinglog.project',
            productsOn: ['products.drivinglog_project'],
            productsOff: ['!products.drivinglog_project'],
            needs: ['products.drivinglog'],
            category: 'drivinglog.settings',
        },
        {
            title: 'settings.general.drivinglog.date',
            productsOn: ['products.drivinglog_date'],
            productsOff: ['!products.drivinglog_date'],
            needs: ['products.drivinglog'],
            category: 'drivinglog.settings',
        },
        {
            title: 'settings.general.drivinglog.private',
            productsOn: ['products.drivinglog_private'],
            productsOff: ['!products.drivinglog_private'],
            needs: ['products.drivinglog'],
            category: 'drivinglog.settings',
        },
        {
            title: 'settings.general.drivinglog.plate',
            productsOn: ['products.drivinglog_plate'],
            productsOff: ['!products.drivinglog_plate'],
            needs: ['products.drivinglog'],
            category: 'drivinglog.settings',
        },
        {
            title: 'settings.general.drivinglog.desc',
            productsOn: ['products.drivinglog_desc'],
            productsOff: ['!products.drivinglog_desc'],
            needs: ['products.drivinglog'],
            category: 'drivinglog.settings',
        },

        {
            name: 'hide_measurement_index',
            title: 'settings.general.hide_measurement_index',
            needs: ['products.measurement_tr||products.measurement_mvr'],
            category: 'project-management.measurements',
        },
        {
            title: 'settings.general.measurement_mandatory_signatures',
            productsOn: ['products.measurement_mandatory_signatures'],
            productsOff: ['!products.measurement_mandatory_signatures'],
            needs: ['products.measurement_tr||products.measurement_mvr'],
            category: 'project-management.measurements',
        },
        {
            title: 'settings.general.measurement_status',
            productsOn: ['products.measurement_status'],
            productsOff: ['!products.measurement_status'],
            needs: ['products.measurement_tr||products.measurement_mvr'],
            category: 'project-management.measurements',
        },

        {
            title: 'settings.plgps',
            options: [
                {
                    products: ['!products.plgps_off', '!products.plgps_end'],
                    title: 'settings.plgps_on',
                },
                {
                    products: ['!products.plgps_off', 'products.plgps_end'],
                    title: 'settings.plgps_also_end',
                },
                {
                    products: ['products.plgps_off', '!products.plgps_end'],
                    title: 'settings.plgps_off',
                },
            ],
            needs: ['products.personalliggareandid06||products.personalliggare'],
            category: 'project-management.settings',
        },
        {
            title: 'settings.export_contact_person',
            form: 'export_contact_person',
            needs: ['export_contact_person', '!worktime'],
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            category: 'project-management.construction_notices',
        },
        {
            title: 'settings.groups',
            form: 'group',
            needs: ['products.admintool', 'group'],
            disable: [],
            category: 'project-management.groups',
        },
        {
            title: 'settings.toolgroups',
            productsOn: ['products.toolgroups'],
            productsOff: ['!products.toolgroups'],
            needs: ['products.machine'],
            form: 'toolgroup',
            category: 'project-management.settings',
        },
        {
            title: 'settings.compensations',
            needs: ['modules.timetracker'],
            /*disable: [{key: "products.overtime", text: "settings.overtime"}],*/
            category: 'salaries.calculations',
            component: 'settings/general-v2/components/overtime-comp',
            disable: [{ key: '!products.mini_entry', text: 'general.mini_entry' }],
            dummySetting: true,
            hideHeader: true,
            groupEditable: true,
            //component: "collector/field-setting-comp/overtime-setting",
        },
        {
            title: 'company_profile.billing_info_header',
            needs: [],
            component: 'settings/general-v2/components/company-info/billing-comp',
            category: 'companyProfile.info',
            groupEditable: true,
        },
        {
            title: 'company_profile.company_info',
            needs: [],
            component: 'settings/general-v2/components/company-info/info-comp',
            category: 'companyProfile.info',
            groupEditable: true,
        },
        {
            title: 'company_profile.address_info_header',
            needs: [],
            component: 'settings/general-v2/components/company-info/address-comp',
            category: 'companyProfile.info',
            groupEditable: true,
        },
        {
            title: 'company_profile.logo_header',
            needs: [],
            component: 'settings/general-v2/components/company-info/logo-comp',
            category: 'companyProfile.info',
            groupEditable: true,
        },
        {
            title: 'settings.notification_center',
            needs: ['modules.timetracker', 'flag.notification-settings-v2'],
            /*disable: [{key: "products.overtime", text: "settings.overtime"}],*/
            category: 'notifications.sending',
            component: 'settings/general-v2/components/notification-center',
            dummySetting: true,
            hideHeader: true,
            //component: "collector/field-setting-comp/overtime-setting",
        },
    ],
};
