import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import podNames from 'ember-component-css/pod-names';
import { initializeVersionInfo } from 'tt4/utils/version-info';
import { inject as service } from '@ember/service';
import config from 'tt4/config/environment';
import Ember from 'ember';

export default class ProfileModalComponent extends Component {
  @service intl;
  @service gui;
  @service loadTranslations;
  @service ttapi;
  @service('collector-service') Collector;
  @service moment;
  @service intl;
  @service analytics;

  @tracked devModeClicks = 0;
  @tracked devModeOn = Ember.devModeOn === true;
  @tracked profileImg = this.args.profileImg;
  @tracked session = this.args.session;
  @tracked communityLink = this.args.communityLink;
  @tracked version = '';
  @tracked currentLanguage = localStorage['lang'] || 'en';
  @tracked copiedPartnerId = ''; // Added to track if partner id is copied in test

  get styleNamespace() {
    return podNames['profile-modal'];
  }

  constructor() {
    super(...arguments);
    initializeVersionInfo(this);
  }

  @action
  handleCopyPartnerId() {
    if (typeof this.args.copyPartnerId === 'function') {
        this.args.copyPartnerId();
    }
    set(this,'copiedPartnerId', this.session.currentUser.partnerid); // For testing purposes
  }

  @action
  handlerrChangeLanguage() {
    this.args.changeLanguage();
  }

  @action
  handleLogout() {
    if(typeof this.args.logout === 'function') {
        this.args.logout();
    }
    this.trackAnalyticsEvent('Logout clicked');
  }

  @action
  devmode() {
    this.devModeClicks += 1;
    if (this.devModeClicks > 6) {
      Ember.dev();
      this.devModeClicks = 0;
    }
    this.devModeOn = Ember.devModeOn === true;
  }

  @action
  handleChangeLanguage(lang) {
    moment.locale(lang);
    localStorage['lang'] = lang;

    this.currentLanguage = lang;

    // save selected lang to ttapi
    this.Collector.ttapi({
        url: 'api/setUserLang?lang=' + lang,
        method: 'post',
    });

    this.trackAnalyticsEvent('Change language clicked, language: ' + lang);

    // eslint-disable-next-line ember/named-functions-in-promises
    this.loadTranslations.fetch().then(() => {
        return (this.intl.locale = lang);
    });
 }

 @action
 handleClose() {
    if (this.args.dd && typeof this.args.dd.closeDropdown === 'function') {
     this.args.dd.closeDropdown();
   }
 }

 @action
 trackAnalyticsEvent(action) {
    this.analytics.trackEvent({
        category: 'ProfileModal',
        action: action,
        label: 'ProfileModal',
    });
 }

 get dropdownItems() {
    return this.args.items || [];
  }

  get profileImgUrl() {
    // Convert SafeString to string if necessary
    let profileImgString = this.profileImg.toString();
    const match = profileImgString.match(/url\((.*?)\)/);
    return match ? match[1] : '';
  }

  get productUpdatesUrl() {
    const brand = config.brand;
    const intl = this.intl;

    if (brand === 'visma') {
      return intl.t('general.product_updates_url_entry').toString();
    } else if (brand === 'otta') {
      return intl.t('general.product_updates_url_otta').toString();
    } else {
      return intl.t('general.product_updates_url').toString();
    }
  }

  get impersonate() {
    if (this.session.session.content.authenticated.scope) {
      return true;
    }
    return false;
  }
}
