import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CopyPreviousDayComponent extends Component {
    @tracked showCopyPreviousDayModal;
    @tracked showCopyPreviousDayFinalModal;
    @tracked record;
    @tracked canCopy;
    @tracked showCopy;

    @tracked date;
    @tracked worktimes;

    @tracked selectedDay = null;
    @tracked isActive = false;

    @service router;
    @service store;
    @service notifications;
    @service intl;
    @service analytics;

    constructor() {
        super(...arguments);
        this.selectedDay = this.args.date;
    }

    @action
    notifySaved() {
        this.args.updateData();
        this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
        this.args.rowAdded();
    }

    @action
    datePicked(date, worktimes) {
        this.showCopyPreviousDayModal = false;
        this.day = date;
        this.worktimes = worktimes;
        this.showCopyPreviousDayFinalModal = true;
    }

    @action
    closeShowCopyPreviousDayModal() {
        this.showCopyPreviousDayModal = false;
    }

    @action
    closeShowCopyPreviousDayFinalModal() {
        this.showCopyPreviousDayFinalModal = false;
    }

    @action
    startCopyingPreviousDay() {
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: 'Start copying previous day',
            label: 'worktime-calendar widget',
        });
        this.showCopyPreviousDayModal = true;
    }
}
