/* eslint-disable no-irregular-whitespace */
import Service from '@ember/service';
import config from '../config/environment';

export default class MetaService extends Service {
    updateDescription(content) {
        // When calling from translateMetaTags, content is passed as a parameter
        // If content is not passed, then we are calling from application route and content is set empty.
        if (!content) {
            content = '';
        }
        let metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', content);
        }
    }

    updateTitle(title) {
        // When calling from translateMetaTags, title is passed as a parameter
        // If title is not passed, then we are calling from application route
        if (title) {
            document.querySelector('title').textContent = title;
            return;
        }
        // Change Page title according the brand
        if (config.brand === 'default') {
            title = 'Movenium';
        } else if (config.brand === 'otta') {
            title = 'Otta';
        }
        document.querySelector('title').textContent = title;
    }

    translateMetaTags() {
        // Get browser language
        let browser = window.navigator.userLanguage || window.navigator.language;
        let language = browser.substring(0, 2).toLowerCase();

        // If language is not Finnish, then use English as default
        if (language !== 'fi') {
            if (config.brand === 'default') {
                this.updateTitle('Log in to Movenium | Log in');
                this.updateDescription(
                    'Log in to the Movenium Site Management System with your credentials or via Visma Connect.',
                );
            } else if (config.brand === 'otta') {
                this.updateTitle('Log in to Otta | Log in');
                this.updateDescription(
                    'Log in to the Otta time tracking system with your credentials or via Visma Connect.',
                );
            }
        }
    }
}
