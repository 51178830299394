// noinspection TypeScriptValidateTypes

import Service, { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

/**
 * Handles all operations that have to do with calling different apis
 *
 * ```js
 * @service apiservice;
 * this.apiservice.request(<backendName>, <url>, <method>, <body>)
 * ```
 *
 * @class ApiserviceService
 * @constructor
 * @public
 */

export default class ApiserviceService extends Service {
    @service private session!: any;
    @service private ttapi!: any;
    @service private capi!: any;

    /**
     * Make api request using backendName, url, body and method given
     * EXAMPLE: await this.apiservice.request('capi', '/worktime/:id', 'PUT', {worktime: {locked: true}})
     * EXAMPLE2: await this.apiservice.request('ttapi', '/sendReportAsEmail', 'POST', {to: 'test@mail.com', lang: 'fi'})
     * @method request
     * @param {String} backendName
     * @param {String} url
     * @param {object} data
     * @param {string} method
     */
    public async request(
        backendName: string,
        url: string,
        method: string = 'GET',
        data: object = {},
    ) {
        let callUrl: unknown = '';
        switch (backendName) {
            case 'ttapi':
                callUrl = this.ttapi.host;
                break;
            case 'capi':
                callUrl = this.capi.host;
                break;
            default:
                assert('Invalid backend name (options ttapi, capi)');
        }

        assert('You need to pass url to request method', url);

        try {
            const requestData: any = {
                headers: {
                    Authorization: 'Bearer ' + this.session.data.authenticated.access_token,
                    'Content-Type': 'application/json',
                },
                method,
            };
            if (Object.keys(data).length > 0) requestData.body = JSON.stringify(data);
            // @ts-ignore: Ignore import of compiled template
            callUrl = callUrl.slice(-1) === '/' ? callUrl.slice(0, -1) : callUrl;
            return await fetch(callUrl + url, requestData);
        } catch (e) {
            throw new Error(e);
        }
    }
}
